/***************************************************************************************************************
||||||||||||||||||||||||||||       MATER STYLESHEET FOR Ecogreen      ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||||              TABLE OF CONTENT                  ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************

1. Imported styles
2. Global styles
3. Header styles
4. Slider styles
5. Urgent cause
6. about section
7. gallery section
8. event section
9. testimonial
10.brand-logo
11.main-footer
12.blog section
13.fact-counter
14.shop page


****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/

/* ==============================
   1. Imported styles
   ==============================
*/

@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Google+Sans:400,400i,700");
@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");
@import url(./bootstrap.min.css);
@import url(./bootstrap-select.min.css);
@import url(./jquery.bootstrap-touchspin.css);
@import url(./font-awesome.css);
@import url(./icomoon.css);
@import url(./settings.css);
@import url(./layers.css);
@import url(./navigation.css);
@import url(./owl.carousel.css);
@import url(./owl.theme.default.css);
@import url(./jquery.bxslider.css);
@import url(./flexslider.css);
@import url(./jquery-ui.css);
@import url(./jquery-ui.theme.css);
@import url(./jquery.fancybox.css);
@import url(./polyglot-language-switcher.css);
@import url(./animate.min.css);
@import url(./nouislider.css);
@import url(./nouislider.pips.css);
@import url(./menu.css);
@import url(./magnific-popup.css);
@import url(./imagehover.min.css);
@import url(./player.css);
@import url(./animate.min.css);
@import url(./responsive.css);
@import url(./animate.min.css);
@import url(./animate.min.css);
@import url(./extras.css);
@import url(./me-anime.css);

/* ==============================
   2. Global styles
   ============================== */

html {
  font-family: "Google Sans", "Open Sans", sans-serif;
  scroll-behavior: smooth !important;
}
iframe{
  /* display: none !important; */
  position:  relative !important;
}

body {
  font-family: "Google Sans", "Open Sans", sans-serif;
  /* color: #999; */
  color: black;
}

body.burger-menu-open {
  overflow: hidden;
}

.burger-menu-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}

body.burger-menu-open .burger-menu-overlay {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
}

ul {
  list-style-type: disc;
}

ul.normal {
  list-style-type: disc;
}

ul.normal li {
  margin-left: 2em;
}

p {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: large;
  color: black;
  line-height: 26px;
  font-weight: 400;
}

a {
  text-decoration: none;
  display: inline-block;
  outline: none;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

img {
  max-width: 100%;
  display: inline-block;
}

button {
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  padding: 0;
  background: transparent;
}

input,
textarea {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  display: block;
  font-size: 16px;
  line-height: 28px;
  outline: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.boxed_wrapper {
  width: 100%;
  background: #fff;
  overflow-x: hidden;
}

.sec-padd {
  padding: 20px 0;
}

.sec-padd2 {
  padding: 80px 0 30px;
}

.sec-padd3 {
  padding: 80px 0 50px;
}

.sec-padd-top {
  padding-top: 80px;
}

.sec-padd-bottom {
  padding-bottom: 80px;
}

.no-padd {
  padding: 0px;
}

.paddt-50 {
  padding-top: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.list_inline li {
  display: inline-block;
}

.padd-80 {
  padding-top: 80px;
}

.tran3s {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.center {
  text-align: center;
}

.font-normal {
  color: #999 !important;
  font-size: inherit !important;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.thm-color {
  color: #8dc63f;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
}

.border-bottom {
  border-bottom: 1px solid #f7f7f7;
}

.normal {
  color: inherit;
  font-size: inherit;
}

@media (min-width: 1200px) {
  .container {
    padding: 0;
  }
}

.thm-btn {
  position: relative;
  background: #8dc63f;
  font-size: 14px;
  line-height: 30px;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #8dc63f;
  font-weight: 700;
  display: inline-block;
  padding: 0 38px;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
  margin: 2px;
}

.thm-btn:disabled {
  background: #aaa;
  border: #aaa;
}

.thm-btn:hover {
  background: transparent;
  color: #8dc63f;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.selected {
  background: transparent;
  color: #8dc63f;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.red {
  position: relative;
  background: red;
  font-size: 14px;
  line-height: 30px;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid red;
  font-weight: 700;
  display: inline-block;
  padding: 0 38px;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.red:disabled {
  background: #aaa;
  border: #aaa;
}

.thm-btn.red:hover {
  background: transparent;
  color: red;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.style-2 {
  background: transparent;
  color: #8dc63f;
  border-color: #8dc63f;
}

.thm-btn.style-2:hover {
  background: #8dc63f;
  color: #fff;
  border-color: #8dc63f;
}

.thm-btn.style-3 {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.thm-btn.style-3:hover {
  background: #fff;
  color: #8dc63f;
  border-color: #fff;
}

.thm-btn.style-4 {
  position: relative;
  background: #8dc63f;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 20px;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #8dc63f;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  padding: 0 3px;
  margin-left: 2px;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
  font-size: 12px;
}

.thm-btn.style-4.red {
  position: relative;
  background: red;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 20px;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid red;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  padding: 0 3px;
  margin-left: 2px;
  transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.style-4.red:hover {
  background: transparent;
  color: red;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.style-4:disabled {
  background: #aaa;
  border: #aaa;
}

.thm-btn.style-4.disabled {
  background: #aaa;
  border: #aaa;
}

.thm-btn.style-4:disabled:hover {
  background: #aaa;
  border: #aaa;
  color: white;
}

.thm-btn.style-4.disabled:hover {
  background: #aaa;
  border: #aaa;
  color: white;
}

.thm-btn.style-4:hover {
  background: transparent;
  color: #8dc63f;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.thm-btn.style-4.selected {
  background: transparent;
  color: #8dc63f;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.section-title {
  position: relative;
  margin-bottom: 50px;
}

.section-title h2 {
  position: relative;
  font-weight: 400;
  font-size: 32px;
}
/* remove this from section-titles: text-transform: capitalize; */

.section-title h3 {
  position: relative;
  font-weight: 400;
}

.section-title h4 {
  position: relative;
  font-size: 20px;
  font-weight: 400;
}

.scroll-top {
  width: 50px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  display: none;
  color: #fff;
  text-align: center;
  background: #8dc63f;
}

.scroll-top span:before {
  font-size: 27px;
}

.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: -webkit-radial-gradient(
    center ellipse,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/icons/preloader.gif);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #292121;
  background: #292121;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.75);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.75);
}

/*============ social icon ===================*/

.social-icon li {
  display: inline-block;
}

.social-icon li a {
  display: inline-block;
  color: #999;
  margin-right: 15px;
  font-size: 16px;
  transition: 0.5s ease;
}

.social-icon li a:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

.player .controls .logo {
  display: none !important;
}

/*============= accordion ===============*/

.accordion-box {
}

.accordion {
  margin-bottom: 20px;
  position: relative;
}

.accordion .acc-btn .left-icon {
  position: absolute;
  left: 0;
  top: 0;
  background: #f1f1f1;
  width: 60px;
  height: 100%;
  color: #9c9c9c;
  font-size: 20px;
  text-align: center;
}

.accordion .acc-btn.active .left-icon {
  color: #fff;
}

.accordion .left-icon span {
  position: relative;
  top: 18px;
}

.accordion .acc-btn {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 30px;
  margin: 0px;
  padding-right: 50px;
  border: 1px solid #f4f4f4;
}

.accordion .acc-btn.active {
  border-color: #8dc63f;
  border-bottom: 0px;
}

.accordion .acc-btn p {
  color: #303030;
  font-size: 16px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.accordion .acc-btn.active p {
}

.accordion .acc-btn.pr {
  position: relative;
}

.accordion .acc-btn .img-box {
  display: none;
}

.accordion .acc-btn.active .img-box {
  display: block;
}

.accordion .acc-btn .toggle-icon {
  color: #c8c8c8;
  display: block;
  height: 24px;
  font-size: 14px;
  line-height: 25px;
  position: absolute;
  right: 15px;
  top: 13px;
  width: 25px;
  border-left: 1px solid #c8c8c8;
}

.accordion .acc-btn .toggle-icon.left {
  color: #9c9c9c;
  display: block;
  font-weight: 100;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: 30px;
}

.accordion .acc-btn .toggle-icon .plus:before {
  transition: all 0.3s ease 0s;
  top: 0;
  right: 0;
  position: absolute;
  line-height: 25px;
}

.accordion-box .accordion .acc-btn.active .toggle-icon .plus {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.accordion-box .accordion .acc-btn .toggle-icon .minus:before {
  transition: all 0.3s ease 0s;
  color: #8dc63f;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 25px;
}

.accordion-box .accordion .acc-btn .toggle-icon .minus {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.accordion-box .accordion .acc-btn.active .toggle-icon .minus {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.accordion-box .accordion .acc-content {
  color: #9c9c9c;
  background: #f7f7f7;
  border: 1px solid #8dc63f;
  display: none;
  padding: 20px 20px;
  border-top: 0px;
}

.accordion-box .accordion .acc-content.collapsed {
  display: block;
}

.accordion-box .accordion .acc-content p {
  margin: 0;
}

.accordion-box .accordion .acc-btn.active .toggle-icon .minus {
  color: #121d2f;
}

/*=== style-2 ==========*/

.accordion-box.style-three .accordion .acc-btn .toggle-icon {
  left: 12px;
  border: 1px solid;
  width: 35px;
  height: 35px;
}

.accordion-box.style-three .accordion .acc-btn {
  padding: 16px 30px;
  padding-left: 65px;
}

.accordion-box.style-three .accordion .acc-btn .toggle-icon .plus:before {
  right: 11px;
  top: 6px;
  color: #8dc63f;
}

.accordion-box.style-three .accordion .acc-btn .toggle-icon .plus:before {
  right: 11px;
  top: 6px;
  color: #8dc63f;
}

/*============= cards ===============*/
.card.spacing {
  margin-bottom: 30px;
}

/*============= graphs ===============*/
/* .apexcharts-svg {
    position: absolute;
    top: -25%;
} */

.apex-chart.circle-graph {
  margin-bottom: -100px;
}

@media (max-width: 992px) {
  .apex-chart.circle-graph {
    margin-bottom: 0px;
  }
}

/*============= default-form ===============*/

.default-form {
  position: relative;
}

.default-form .form-box {
  position: relative;
}

.comment-form .form-box {
  padding: 25px 25px 0px 25px;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.default-form .comment-form .form-box .form-group {
  margin-bottom: 25px;
}

.default-form.form-box .row {
  margin-left: -10px;
  margin-right: -10px;
}

.default-form.form-box .row .form-group {
  padding: 0px 0px;
}

.default-form .form-group .field-label {
  display: block;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #232323;
  font-weight: 500;
  font-size: 13px;
}

.default-form .form-group .field-label sup {
  color: #ff0000;
  font-size: 14px;
}

.default-form p {
  margin-bottom: 6px;
}

.default-form input[type="text"],
.default-form input[type="email"],
.default-form input[type="password"],
.default-form input[type="number"],
.default-form select,
.default-form textarea {
  display: block;
  width: 100%;
  line-height: 24px;
  height: 50px;
  font-size: 16px;
  box-shadow: none;
  border: 1px solid #f4f4f4;
  padding: 12px 15px;
  background: #fff;
  border-radius: 0px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.default-form textarea {
  height: 100px;
}

.default-form .bootstrap-select {
  border: 1px solid #f4f4f4;
  background: #f7f7f7;
  color: #a2a2a2;
  padding: 10px 0;
  font-size: 16px;
  height: auto;
}

.default-form .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.default-form .btn-group.open .dropdown-toggle:focus {
  border: none;
  outline: none;
}

.default-form .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.default-form .dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #8dc63f;
  color: #fff;
}

.default-form .form-control:focus {
  border-color: #8dc63f;
  box-shadow: none;
}

.select-box .btn:after {
  position: absolute;
  content: "\f107";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 16px;
  color: #8dc63f;
  width: 20px;
  right: 15px;
  top: 50%;
  margin-top: -11px;
}

.default-form .bootstrap-select {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
}

.default-form .g-select {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0px;
}

.default-form .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  color: #848484;
  padding: 13px 15px;
  border: 1px solid #eee;
}

.default-form .bs-caret {
  display: none;
}

.default-form .dropdown-menu {
  border-radius: 0px;
  padding: 0px;
  top: 50px;
  transform: scaleY(0);
  transform-origin: top center;
  transition: 0.4s ease;
}

.default-form .bootstrap-select.open .dropdown-menu {
  transform: scaleY(1);
  transition: 0.4s ease;
}

.default-form .dropdown-menu > li > a {
  padding: 8px 15px;
}

.default-form .dropdown-menu > li > a:focus {
  background-color: #fff;
  color: #222;
}

.default-form .dropdown-menu > li > a:hover {
  background-color: #8dc63f !important;
  color: #fff !important;
}

/*placeholder*/

.default-form form input::-webkit-input-placeholder {
  color: #9e9e9e;
}

.default-form form input:-moz-placeholder {
  /* Firefox 18- */
  color: #9e9e9e;
}

.default-form form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9e9e9e;
}

.default-form form input:-ms-input-placeholder {
  color: #9e9e9e;
}

.default-form .alert-success {
  color: #8dc63f;
  background: none;
  border: none;
  font-size: 18px;
}

/*** 
=============================================
    page_pagination style
=============================================
***/

.page_pagination {
  position: relative;
  padding-top: 20px;
}

.page_pagination li {
  display: inline-block;
  margin: 0 4px;
}

.page_pagination li a {
  width: 55px;
  line-height: 55px;
  border: 1px solid rgba(229, 229, 229, 0.57);
  text-align: center;
  color: #252525;
  font-size: 18px;
  border-radius: 50%;
}

.page_pagination li a.active,
.page_pagination li a:hover {
  color: #fff;
  background: #8dc63f;
}

/*=======pagination style-2============*/

.page_pagination2 {
  overflow: hidden;
  display: block;
  padding-bottom: 22px;
  padding-top: 22px;
  margin: 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-top: 30px;
}

.page_pagination2 .button a {
  color: #222;
  font-size: 18px;
  font-weight: 400;
  transition: all 500ms ease;
}

.page_pagination2 .button a:hover {
  color: #8dc63f;
}

.page_pagination2 .button a i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 2px;
}

.page_pagination2 .button.next a i {
  padding-right: 0px;
  padding-left: 0;
}

.page_pagination2 .icon-holder a i {
  color: #252525;
  font-size: 24px;
  position: relative;
  top: 4px;
  transition: all 500ms ease 0s;
}

.page_pagination2 .icon-holder a:hover i {
  color: #8dc63f;
}

/*** 

====================================================================
  donate Popup
====================================================================

***/

.donate-popup {
  position: fixed;
  left: 0px;
  top: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.donate-popup.popup-visible {
  top: 0;
  visibility: visible;
  opacity: 1;
}

.donate-popup .close-donate {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  width: 100%;
  height: 120%;
  cursor: crosshair;
}

.donate-popup .close-donate:hover {
  opacity: 0.7;
}

/*============= donate form area =================*/

.donate-form-area {
  margin-top: 100px;
  margin-bottom: 100px;
  background: #f2f2f2;
  padding: 57px;
  border: 3px solid #8dc63f;
  position: relative;
  z-index: 9999999;
}

.donate-form-area .section-title:before {
  display: none;
}

.donate-form-area h4 {
  margin-bottom: 18px;
}

.donate-form-area h3 {
  font-weight: 700;
  margin-top: 38px;
  margin-bottom: 28px;
}

/*check list*/

.chicklet-list {
  background: #fff;
  padding: 30px 30px 20px;
}

.chicklet-list li {
  padding: 0 5px;
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}

.chicklet-list input {
  font-size: 1.4em;
  line-height: 1;
  border: 2px solid #3b7840;
  padding: 0.55em 0.55em 0.55em 0.8em;
  color: #3b7840;
}

.chicklet-list input[type="radio"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.chicklet-list label {
  display: block;
  font-size: 16px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-weight: normal;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  color: #000;
  padding: 8px 27px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.chicklet-list :checked + label {
  background: #8dc63f;
  color: #f7f7f7;
  text-shadow: none;
}

.chicklet-list li.other-amount {
  width: 38%;
  float: right;
}

.chicklet-list li span {
  position: absolute;
  left: -30px;
  top: 9px;
  font-size: 16px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
}

.chicklet-list input[type="text"] {
  height: 40px;
}

/*==========*/

.donate-form-area .form-bg {
  background: #fff;
  padding: 30px 30px 15px;
}

.donate-form-area .default-form .form-group {
  margin-bottom: 15px;
}

.donate-form-area .payment-option {
  position: relative;
  margin-top: 20px;
  margin-bottom: 35px;
}

.donate-form-area .payment-option li {
  display: inline-block;
  font-size: 18px;
  color: #303030;
  font-size: 15px;
  margin-right: 10px;
}

.video-image-box {
  position: relative;
}

.video-image-box .gallery_video {
  position: relative;
}

.video-image-box img {
  display: inline-block;
  width: 100%;
}

.video-image-box .overlay-link {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -29px;
  width: 58px;
  height: 41px;
  line-height: 43px;
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

/* ==============================
   2. header-style
   ============================== */

.top-bar {
  position: relative;
  line-height: 48px;
  z-index: 999;
  background: #f7f7f7;
  padding: 6px 0;
}

.top-bar .top-bar-info li {
  color: #999;
  margin-right: 20px;
  display: inline-block;
  position: relative;
  top: 0px;
}

.top-bar .top-bar-info li i {
  color: #8dc63f;
  margin-right: 10px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.top-bar .thm-btn {
  padding: 0 27px;
  line-height: 38px;
}

.top-bar .clearfix {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.top-bar p {
  margin-top: 12px;
  color: #999;
}

.top-bar .social {
  display: inline-block;
  margin-right: 25px;
}

.top-bar .social li a {
  margin: 0 8px;
  color: #999;
  font-size: 14px;
}

.top-bar .social li a:hover {
  color: #8dc63f;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.top-bar .social li:last-child {
  padding-right: 0px;
}

/*language switcher*/

#polyglotLanguageSwitcher {
  position: relative;
  margin-right: 30px;
  display: inline-block;
  position: relative;
  z-index: 99999;
}

#polyglotLanguageSwitcher span.trigger:before {
  content: "\f107";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  position: absolute;
  color: #cdcdcd;
  top: 0;
  right: 0;
  font-size: 14px;
  line-height: 10px;
}

#polyglotLanguageSwitcher a {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  display: inline-block;
  font-size: 14px;
  color: #333;
  font-weight: normal;
}

#polyglotLanguageSwitcher a.current:link,
#polyglotLanguageSwitcher a.current:visited,
#polyglotLanguageSwitcher a.current:active {
  position: relative;
  background-color: transparent;
  border: 0;
  line-height: 24px;
  border-radius: 5px;
  color: #999;
  text-align: center;
  height: 26px;
  padding: 0;
  width: 65px;
  padding-left: 12px;
  border: 1px solid #e0e0e0;
}

#polyglotLanguageSwitcher a.current:hover {
  background-color: transparent;
}

#polyglotLanguageSwitcher ul.dropdown {
  top: 100%;
}

/* Flags */

#en,
#fr,
#de,
#it,
#es {
  background-image: none;
}

/*======logo========*/

/*=====menu========*/

@keyframes menu_sticky {
  0% {
    margin-top: -100px;
  }

  50% {
    margin-top: -90px;
  }

  100% {
    margin-top: 0;
  }
}

.theme_menu.stricky-fixed {
  margin: 0;
  padding: 15px 0;
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  animation-name: menu_sticky;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(93, 73, 73, 0.12);
}

.theme_menu .container {
  position: relative;
}

.theme_menu .container .thm-btn {
  /* display: flex; */
}

.theme_menu {
  position: relative;
  z-index: 99;
  padding: 0 0;
}

.theme_menu .menu-column {
  padding-left: 0;
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme_menu .menuzord {
  background: transparent;
  float: left;
}

.menuzord-menu > li a:not(.dropdown-item) {
  padding: 11px 12px !important;
  font-size: 13px !important;
  position: relative;
  /* color: #888; */
  color: black;
  font-weight: 700;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.menuzord-menu > li:hover a:not(.dropdown-item) {
  color: #8dc63f;
}

.menuzord-menu > li:first-child a:not(.dropdown-item) {
  padding-left: 0px;
}

.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
  padding: 0 10px;
  min-width: 230px;
  background: #fff;
  border-top: 3px solid #8dc63f;
  right: auto !important;
}

ul.dropdown li ul.dropdown {
  padding: 0 10px;
  min-width: 230px;
  background: #fff;
  border-top: 3px solid #8dc63f;
  right: auto !important;
}

ul.dropdown li a {
  padding: 14px 0 14px;
  color: #303030;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #f4f4f4;
  transition: 0.5s ease-in-out;
  font-size: 13px;
}

.menuzord-menu ul.dropdown li a {
  padding: 14px 0 14px;
  color: #303030;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #f4f4f4;
  transition: 0.5s ease-in-out;
  font-size: 14px;
}

ul.dropdown li:hover a {
  padding-left: 0px;
  padding-right: 0px;
  color: #8dc63f;
  transition: 0.5s ease-in-out;
  font-size: 14px;
}

.menuzord-menu ul.dropdown li:hover a {
  padding-left: 0px;
  padding-right: 0px;
  color: #8dc63f;
  transition: 0.5s ease-in-out;
  font-size: 14px;
}

/*============== megamenu ======================*/

.menuzord-menu .title {
  font-size: 20px;
  margin-bottom: 30px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.menuzord-menu > li > .megamenu {
  left: inherit;
  right: -0px !important;
  width: 100%;
  padding: 40px 30px 40px;
  background: #fff;
  border-left: 3px solid #8dc63f;
}

.menuzord-menu ul.megamenu-dropdown {
  min-width: 190px;
  margin-right: 40px;
  margin-bottom: 30px;
}

.menuzord-menu ul.megamenu-dropdown li a {
  padding: 12px 0 9px;
  color: #848484;
  transition: 0.5s ease-in-out;
  font-size: 14px;
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
}

.menuzord-menu ul.megamenu-dropdown li a:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

.menuzord-menu .menu-item {
  float: left;
  width: 25%;
}

.menuzord-menu .event-section2 {
  float: left;
  width: 25%;
}

.event-carousel3 .item .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 170px;
}

.event-section2 .item {
  position: relative;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}

.event-section2 .item .lower-content {
  position: relative;
  padding: 0;
  border-left: 0px;
}

.event-section2 .item .lower-content .text p {
  margin-bottom: 25px;
  font-size: 14px;
}

.event-section2 .item .lower-content h4 {
  margin-bottom: 10px;
  margin-top: 15px;
  line-height: 26px;
  font-size: 16px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.event-section2 .item .lower-content h4 a {
  color: #222;
  transition: all 300ms ease;
  font-size: 18px;
}

.event-section2 .item .lower-content h4 a:hover {
  color: #8dc63f;
  transition: all 300ms ease;
}

.event-section2 .item .lower-content .post-meta {
  color: #8dc63f;
  font-size: 15px;
  margin-bottom: 7px;
}

.event-section2 .item .lower-content .post-meta i {
  margin-right: 5px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.event-section2 .item .lower-content .default_link {
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  transition: 0.5s ease;
}

.event-section2 .item .lower-content .default_link:hover {
  transition: 0.5s ease;
  color: #8dc63f;
}

.event-section2 .item .lower-content .default_link i {
  font-size: 11px;
}

.event-section2 .item .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
}

.megamenu .recent-post {
  width: 50%;
  float: left;
  padding-left: 30px;
}

.megamenu .post {
  position: relative;
  padding-left: 90px;
  margin-bottom: 35px;
}

.megamenu .post:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.megamenu .post-thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
}

.megamenu h5 {
  position: relative;
  top: -5px;
  font-size: 16px;
  color: #303030;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 25px;
}

.megamenu .post-info {
  color: #8dc63f;
  font-size: 15px;
}

.megamenu .post-info i {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

/*=================== Search Option ===============*/

.theme_menu .right-column {
  position: absolute;
  right: 0;
  width: 50px;
}

.nav_side_content {
  margin-top: 10px;
  position: relative;
  right: -10px;
}

.nav_side_content .search_option > button {
  height: 40px;
  font-size: 15px;
  color: #8dc63f;
  border: 1px solid #e1e1e1;
  text-align: center;
  width: 40px;
  border-radius: 50%;
}

.nav_side_content .search_option form {
  height: 50px;
  width: 280px;
  padding: 5px;
  border-radius: 4px;
  margin: 0;
  left: auto;
  right: 10px;
  border-color: #8dc63f;
  top: 54px;
}

.nav_side_content .search_option form input {
  font-weight: normal;
  width: 100%;
  height: 100%;
  background: transparent;
  color: #9e9e9e;
  border: 1px solid rgba(51, 51, 51, 0.16);
  padding: 0 47px 0 10px;
  font-size: 15px;
  border-radius: 3px;
  box-shadow: inset 0 0 11px 2px rgba(119, 119, 119, 0.25);
}

.nav_side_content .search_option form button {
  display: block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 4px;
  right: 5px;
  color: #8dc63f;
}

.nav_side_content .cart_select {
  border-left: 1px solid #ececec;
  padding-left: 5px;
}

.theme_menu .link_btn {
  margin-top: 24px;
}

/*** 
=============================================
    Rev Slider Wrapper style
=============================================
***/

.rev_slider_wrapper {
}

.rev_slider_wrapper .slide-content-box h1 {
  color: #ffffff;
  font-size: 80px;
  line-height: 54px;
  font-weight: 400;
  text-shadow: 4px 5px 4px rgba(0, 0, 0, 0.35);
}

.rev_slider_wrapper .slide-content-box h1 span {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.rev_slider_wrapper .slide-content-box h2 {
  color: #ffffff;
  font-size: 60px;
  line-height: 54px;
  font-weight: 400;
  margin: 0px 0 25px;
  text-transform: uppercase;
  text-shadow: 4px 5px 4px rgba(0, 0, 0, 0.35);
}

.rev_slider_wrapper .slide-content-box h3 {
  color: #ffffff;
  font-size: 34px;
  line-height: 54px;
  font-weight: 400;
  margin: 0px 0 5px;
  text-transform: uppercase;
  text-shadow: 4px 5px 4px rgba(0, 0, 0, 0.35);
}

.rev_slider_wrapper .slide-content-box p {
  color: #e2e2e2;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  margin: 0 0 30px;
}

.rev_slider_wrapper a.thm-btn.style-3,
.rev_slider_wrapper a.thm-btn {
  transition: all 500ms ease !important;
  padding: 13px 38px;
}

.rev_slider_wrapper .slide-content-box.last-slide p {
  margin: 0;
}

.rev_slider_wrapper .slide-content-box.last-slide a {
  margin: 0 8px;
}

.rev_slider_wrapper .slotholder {
  position: relative;
}

.slotholder:after {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.tp-bannertimer {
  display: none;
}

.rev_slider_wrapper .tparrows {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.2);
}

.tparrows:before {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 60px;
}

.tparrows.tp-leftarrow:before {
  content: "\e926";
}

.tparrows.tp-rightarrow:before {
  content: "\e927";
}

/*** 

====================================================================
    our-mission style
====================================================================

***/

.our-mission {
  position: relative;
  margin-top: 80px;
  z-index: 99;
}

.our-mission .mission-bg {
  background: #fff;
  padding: 75px 0 0;
  border: 1px solid #f4f4f4;
}

.our-mission .default-title h3 {
  line-height: 34px;
  margin-bottom: 45px;
}

.our-mission .default-title h3 span {
  color: #8dc63f;
}

.our-mission .content {
  text-align: center;
}

.our-mission .column {
  position: relative;
  margin-bottom: 45px;
  display: inline-block;
  padding: 0 25px;
}

.our-mission .column:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: #f4f4f4;
  transition: 0.5s ease;
}

.our-mission .column:last-child:before {
  display: none;
}

.our-mission .icon-box {
  position: relative;
  height: 90px;
  width: 90px;
  text-align: center;
  border: 1px solid #f4f4f4;
  border-radius: 50%;
  color: #8dc63f;
  font-size: 40px;
  margin: 0 auto;
  line-height: 60px;
  transition: 0.5s ease;
  overflow: hidden;
}

.our-mission .column:hover .icon-box {
  border-color: #8dc63f;
  transition: 0.5s ease;
}

.our-mission .icon-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background: #f4f4f4;
  transition: 0.5s ease;
}

.our-mission .column:hover .icon-box:before {
  background-color: #8dc63f;
  transition: 0.5s ease;
}

.our-mission h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  transition: 0.5s ease;
}

.our-mission .column:hover h4 {
  color: #8dc63f;
  transition: 0.5s ease;
}

.our-mission p {
}

/*** 

====================================================================
    service style
====================================================================

***/

.service {
  background-size: cover;
  /* background: #003000; */
  background: white;
  padding: 2rem;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.service .section-title h2 {
  /* line-height: 20px; */
  color: #aaa;
}

.service .service-item {
  width: 100%;
  margin-bottom: 20px;
  /* border: 1px solid #ffd2cc; */
  border-radius: 3px;
  padding: 20px 5px 5px;
  position: relative;
}

.service .service-item:before {
  position: absolute;
  content: "";
  border: 12px solid transparent;
  border-top-color: #f7f7f7;
  border-right-color: #f7f7f7;
  right: 8px;
  top: 8px;
  transition: 0.5s ease;
}

.service .service-item:hover:before {
  border-top-color: #8dc63f;
  border-right-color: #8dc63f;
  transition: 0.5s ease;
}

.service .service-item .icon-box {
  position: relative;
  height: 70px;
  width: 70px;
  margin: 5px auto 20px;
  line-height: 110px;
  transition: 0.4s ease;
}

.service .service-item .icon-box span {
  position: relative;
  top: -10px;
  color: #8dc63f;
  font-size: 45px;
  transition: 0.5s ease;
}

.service .service-item:hover .icon-box span {
  color: #fff;
  transition: 0.5s ease;
}

.service .service-item .icon-box:before {
  position: absolute;
  content: "";
  border: 1px solid #f4f4f4;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transform: rotate(45deg);
  left: 0;
  transition: 0.5s ease;
}

.service .service-item h4 {
  font-size: 20px;
  color: #aaa;
}

.service .service-item p {
  color: #888;
}

.service .service-item:hover .icon-box:before {
  border-color: #8dc63f;
  background: #8dc63f;
  transition: 0.5s ease;
}

.service .service-item:hover {
  border-color: #8dc63f;
  transition: 0.5s ease;
}

/*============== default-sidebar =================*/

.default-sidebar {
  position: relative;
}

.default-sidebar .service-catergory {
  margin: 0;
  padding: 0;
  list-style: none;
  border-left: 2px solid #8dc63f;
  margin-bottom: 50px;
}

.default-sidebar .service-catergory li {
  list-style: none;
  position: relative;
  background: #f7f7f7;
  padding: 14px 0;
  margin-bottom: 5px;
}

.default-sidebar .service-catergory li.active {
  background: #8dc63f;
}

.default-sidebar .service-catergory li.active a {
  color: #fff;
}

.default-sidebar .service-catergory li.active a:after {
  color: #fff;
}

.default-sidebar .service-catergory li .view-all-icon {
  position: absolute;
  right: 20px;
  top: 22px;
  color: #848484;
}

.default-sidebar .service-catergory li a {
  display: block;
  font-size: 16px;
  color: #222;
  line-height: 30px;
  border-left: 5px solid transparent;
  font-weight: 600;
  padding-left: 23px;
  transition: all 0.5s ease;
  position: relative;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.default-sidebar .service-catergory li a:hover {
  color: #8dc63f;
}

.default-sidebar .service-catergory li.active a:hover {
  color: #fff;
}

.default-sidebar .thm-btn {
  width: 100%;
  border-radius: 0px;
  line-height: 56px;
}

.default-sidebar .service-catergory li a:after {
  position: absolute;
  content: "\f101";
  right: 20px;
  top: 0px;
  color: #a0a0a0;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.default-sidebar .event-filter {
  position: relative;
}

.default-sidebar .default-form .form-group {
  margin-bottom: 20px;
}

.default-sidebar .event-filter h3 {
  margin-bottom: 5px;
}

.default-sidebar .event-filter p {
  margin: 15px 0;
}

.default-sidebar .event-filter h5 {
  margin-bottom: 15px;
}

.default-sidebar .event-filter .thm-btn {
  width: auto;
  border-radius: 24px;
}

/*** 

====================================================================
    single-service style
====================================================================

***/

.single-service .default-list li {
  font-size: 14px;
  margin-bottom: 10px;
  color: #999;
}

.single-service .default-list li i {
  font-size: 14px;
  color: #8dc63f;
  margin-right: 10px;
}

/*tab*/

.tabs-section {
  position: relative;
  padding: 130px 0px 100px;
}

.tabs-section .column {
  margin-bottom: 30px;
}

.tabs-section .image-box img {
  width: 100%;
  display: block;
}

.tabs-style-one .tab-buttons {
  position: relative;
}

.tabs-style-one .tab-buttons .tab-btn {
  position: relative;
  display: inline-block;
  padding: 0px 0px;
  margin: 0px 0px 0px;
  font-size: 18px;
  text-align: center;
  color: #848484;
  border: 2px solid transparent;
  background-color: #f9f9f9;
  border-bottom: 0px;
  text-transform: capitalize;
  transition: all 300ms ease;
}

.tabs-style-one .tab-buttons .tab-btn input {
  display: inline-block;
  margin: 0px 5px;
  cursor: pointer;
}

.tabs-style-one .tab-buttons .tab-btn.active-btn {
  color: #8dc63f;
  background: #ffffff;
  border-color: #8dc63f;
}

.tabs-style-one .tabs-content {
  position: relative;
  padding: 3px;
  padding-bottom: 0px;
  border: 1px solid #f4f4f4;
}

.tabs-style-one .tab .text-content {
  position: relative;
}

.tabs-style-one .tab .text-content .text {
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 15px;
  color: #777777;
  font-weight: 300;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.tabs-style-one.tabs-box {
  padding-right: 15px;
}

.tabs-box .tabs-content .tab {
  position: relative;
  display: none;
}

.tabs-box .tabs-content .active-tab {
  display: block;
}

.pie-value {
  display: block;
  position: absolute;
  font-size: 36px;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  margin-left: -33px;
  left: 50%;
  font-weight: 300;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 40px;
}

.knob {
  position: relative;
  text-align: center;
}

.skills {
  background: #fff;
  padding-top: 20px;
}

.skills p {
  padding: 25px 0 50px;
  color: #383838;
  font-size: 18px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

/*** 

====================================================================
    urgent-cause style
====================================================================

***/

.urgent-cause2 {
  position: relative;
}

.urgent-cause2 .section-title h2 {
  padding-bottom: 10px;
}

.urgent-cause2 .section-title p {
  padding-bottom: 20px;
}

.urgent-cause2.style-2 .cause-list .item {
  margin-bottom: 30px;
  position: relative;
}

.urgent-cause2 .with-mb {
  margin-bottom: 30px;
}

.urgent-cause2 .img-box {
  position: relative;
  overflow: hidden;
}

.urgent-cause2 .img-box .overlay {
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 0%;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
  opacity: 0;
  visibility: hidden;
}

.urgent-cause2 .img-box .overlay .inner-box {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.urgent-cause2 .img-box .overlay .inner-box .content-box {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.urgent-cause2 .item:hover .overlay {
  height: 100%;
  transition: 0.7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
  opacity: 1;
  visibility: visible;
}

.urgent-cause2 .content {
  position: relative;
  padding: 20px;
  padding-bottom: 30px;
  border: 1px solid #f4f4f4;
  border-top: 0px;
}

.urgent-cause2 .content h4 {
  margin-bottom: 15px;
}

.urgent-cause2 .content .donate {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #999;
  font-size: 16px;
}

.urgent-cause2 .content .donate span {
  color: #8dc63f;
}

.urgent-cause2 .content p {
  margin-bottom: 18px;
}

.urgent-cause2 .content .thm-btn {
  line-height: 38px;
  border-radius: 0px 19px 19px 19px;
  padding: 0 20px;
  border: 0px;
  padding-right: 10px;
  background: #8dc63f;
  color: #fff;
}

.urgent-cause2 .content .thm-btn i {
  border-left: 1px solid #fff;
  padding: 11px 0 13px;
  padding-left: 8px;
  margin-left: 15px;
}

.urgent-cause2 .content .donator {
  color: #8dc63f;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  border-bottom: 1px solid #8dc63f;
  transition: 0.5s ease;
}

.urgent-cause2 .content:hover .donator {
  color: #8dc63f;
  border-color: #8dc63f;
  transition: 0.5s ease;
}

.urgent-cause2 .owl-theme .owl-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 0;
  margin-bottom: 80px;
}

.urgent-cause2 .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.urgent-cause2 .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*=======progress bar========*/

.urgent-cause2 .progress-box {
  position: relative;
  height: 7px;
  transition: 0.7s ease;
  padding: 35px 0 25px;
}

.urgent-cause2 .progress-box .bar {
  position: relative;
  width: 100%;
  height: 7px;
  background-color: #f7f7f7;
}

.urgent-cause2 .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0%;
  height: 7px;
  background-image: url(../images/resource/progress-bg.jpg);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.urgent-cause2 .progress-box .count-text {
  position: absolute;
  text-align: center;
  right: 0px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin-right: -23px;
  bottom: 15px;
  width: 46px;
  border-radius: 5px;
  height: 26px;
  background: #8dc63f;
  color: #ffffff;
  line-height: 26px;
  font-size: 13px;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.urgent-cause2 .progress-box .bar-inner.counted .count-text {
  opacity: 1;
}

.urgent-cause2 .progress-box .count-text:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -4px;
  top: 100%;
  border: 4px solid transparent;
  border-top: 5px solid #8dc63f;
}

/*======== cause list style =========*/

.urgent-cause2 .blog-sidebar .facebook-feed {
  background: #ebebeb;
}

.urgent-cause2 .blog-sidebar .facebook-feed .like-people {
  background: #fff;
}

.urgent-cause2.list-style {
  background: #ffffff;
}

.urgent-cause2 .cause-list-bg {
  position: relative;
}

.urgent-cause2.list-style .cause-list .item {
  display: block;
  margin-right: 50px;
}

.urgent-cause2 .cause-list-bg:before {
  position: absolute;
  content: "";
  width: 50000%;
  background: #fff;
  top: 0;
  border-right: 1px solid #f4f4f4;
  bottom: 0;
  right: 10px;
}

.urgent-cause2.list-style .cause-list .img-box {
  float: left;
  width: 45%;
}

.urgent-cause2.list-style .cause-list .img-box a {
  display: block;
}

.urgent-cause2.list-style .cause-list .img-box img {
  width: 100%;
}

.urgent-cause2.list-style .content {
  position: relative;
  float: left;
  width: 55%;
  border: 1px solid #f4f4f4;
  padding: 25px 30px 27px;
}

/*===== cause with sidebar ========*/

.urgent-cause.with-sidebar {
  background: #f7f7f7;
}

.urgent-cause.with-sidebar .cause-list-bg {
  padding-right: 100px;
}

/*** 

====================================================================
    urgent-cause style one
====================================================================

***/

.urgent-cause {
  position: relative;
}

.urgent-cause .section-title h2 {
  padding-bottom: 10px;
}

.urgent-cause .section-title p {
  padding-bottom: 20px;
}

.urgent-cause.style-2 .cause-list .item {
  margin-bottom: 30px;
  position: relative;
}

.urgent-cause .img-box {
  position: relative;
}

.urgent-cause .date {
  position: absolute;
  left: 25px;
  top: 25px;
  background: #8dc63f;
  color: #fff;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 35px;
  padding: 0 15px;
  height: 0px;
  opacity: 0;
  transition: 0.7s ease;
}

.urgent-cause .item:hover .date {
  height: 35px;
  opacity: 1;
  transition: 0.7s ease;
}

.urgent-cause .content {
  position: relative;
  padding: 20px;
  padding-bottom: 30px;
  border: 1px solid #f4f4f4;
  border-top: 0px;
}

.urgent-cause .content h4 {
  margin-bottom: 15px;
}

.urgent-cause .content .donate {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #999;
  font-size: 16px;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.urgent-cause .content .donate span {
  color: #8dc63f;
}

.urgent-cause .content p {
  margin-bottom: 18px;
}

.urgent-cause .content .thm-btn {
  line-height: 38px;
  border-radius: 0px 19px 19px 19px;
  padding: 0 20px;
  border: 0px;
  padding-right: 10px;
  background: #8dc63f;
  color: #fff;
}

.urgent-cause .content .thm-btn i {
  border-left: 1px solid #fff;
  padding: 11px 0 13px;
  padding-left: 8px;
  margin-left: 15px;
}

.urgent-cause .content .donator {
  color: #e87542;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  margin-top: 10px;
  border-bottom: 1px solid #e87542;
  transition: 0.5s ease;
}

.urgent-cause .content:hover .donator {
  color: #8dc63f;
  border-color: #8dc63f;
  transition: 0.5s ease;
}

.urgent-cause .owl-theme .owl-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 0;
  margin-bottom: 80px;
}

.urgent-cause .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.urgent-cause .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*=======progress bar========*/

.urgent-cause .progress-box {
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.7s ease;
}

.urgent-cause .item:hover .progress-box {
  opacity: 1;
  height: 5px;
  visibility: visible;
  transition: 0.7s ease;
}

.urgent-cause .progress-box .bar {
  position: relative;
  width: 100%;
  height: 5px;
  background-image: url(../images/resource/progress-bg2.jpg);
}

.urgent-cause .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 5px;
  background-image: url(../images/resource/progress-bg.jpg);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.urgent-cause .progress-box .count-text {
  position: absolute;
  text-align: center;
  right: 0px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin-right: -23px;
  bottom: 15px;
  width: 46px;
  height: 26px;
  background: #8dc63f;
  color: #ffffff;
  line-height: 26px;
  font-size: 13px;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.urgent-cause .progress-box .bar-inner.counted .count-text {
  opacity: 1;
}

.urgent-cause .progress-box .count-text:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -4px;
  top: 100%;
  border: 4px solid transparent;
  border-top: 5px solid #8dc63f;
}

/*======single cause========*/

.single-cause {
  background: #ffffff;
}

.single-cause .cause-area {
  padding-right: 100px;
}

.urgent-cause.single-cause .progress-box {
  visibility: visible;
  left: 160px;
  top: 29px;
  right: 30px;
  height: 5px;
  opacity: 1;
  background: #fff;
  padding: 21px 20px;
}

.urgent-cause.single-cause .content .donate {
  position: absolute;
  left: 30px;
  top: 30px;
}

.urgent-cause.single-cause .content {
  padding-left: 160px;
  padding-bottom: 79px;
  background: #f7f7f7;
}

.single-cause .text {
  position: relative;
}

.single-cause .thm-btn.style-2 {
  top: -50px;
}

.single-cause .list {
  margin-top: 25px;
}

.single-cause .list li {
  padding-bottom: 12px;
}

.single-cause .list li i {
  padding-right: 10px;
  color: #8dc63f;
}

.single-cause .donator .img-box {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.single-cause .donator .caption h5 {
  position: absolute;
  line-height: 26px;
  padding: 9px 0px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  width: 100%;
  bottom: -100%;
  transition: 0.5s ease;
}

.single-cause .donator .img-box:hover h5 {
  bottom: 0px;
  transition: 0.5s ease;
}

/*share-box*/

.single-cause .share {
  position: relative;
  background: #ffffff;
  padding-top: 20px;
  padding-bottom: 8px;
  margin-top: 30px;
  margin-bottom: 70px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.single-cause .share .tag-box span {
  font-size: 14px;
  color: #3f3e3e;
}

.single-cause .share .tag-box a {
  color: #8dc63f;
  font-size: 15px;
  transition: 0.3s ease;
  line-height: 30px;
  text-transform: capitalize;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.single-cause .share .tag-box a:hover {
  color: #8dc63f;
  transition: 0.3s ease;
}

.single-cause .share .social-box span {
  color: #252525;
  margin-right: 13px;
  font-size: 18px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.single-cause .share .social {
  display: inline-block;
  margin-bottom: 0;
  margin-left: -2.5px;
}

.single-cause .share .social > li {
  padding-right: 0;
  margin: 0;
  padding-left: 0;
}

.single-cause .share .social a {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  color: #bcbcbc;
  text-align: center;
  margin: 0 3px;
  border: 1px solid #eee;
  display: block;
  line-height: 38px;
  transition: all 0.5s ease;
}

.single-cause .share .social a:hover {
  color: #ffffff;
  background: #8dc63f;
  border-color: #8dc63f;
}

.single-cause .share .tag-box li {
  margin: 0;
  display: inline-block;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
  font-size: 18px;
}

.single-cause .share .thm-btn.style-2 {
  top: -6px;
}

/*** 

====================================================================
    fact-counter style
====================================================================

***/

.fact-counter.style-1 {
  position: relative;
  background-size: cover;
  background-color: #8dc63f;
  background-repeat: no-repeat;
  background-position: right center;
}

.fact-counter.style-1 .title {
  margin-bottom: 40px;
}

.fact-counter.style-1 .title h1 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  line-height: 48px;
  margin-bottom: 15px;
}

.fact-counter.style-1 .title h1 span {
  color: #8dc63f;
}

.fact-counter.style-1 .title p {
  color: #e6e6e5;
}

.fact-counter.style-1 .item {
  position: relative;
  width: 144px;
  height: 144px;
  margin-right: 0px;
  margin-left: 0px;
  border: 3px solid rgba(255, 255, 255, 0.05);
  text-align: center;
}

.fact-counter.style-1 .item:before {
  position: absolute;
  content: "";
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  border-radius: 50%;
  background: #111;
  border: 3px solid rgba(255, 255, 255, 0.05);
}

.fact-counter.style-1 .count-outer {
  font-size: 16px;
  color: #8dc63f;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin-top: 40px;
  margin-bottom: 10px;
}

.fact-counter.style-1 h5 {
  font-size: 16px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #e6e6e5;
}

.fact-counter.style-1 .link {
  margin-top: 40px;
}

/*** 

====================================================================
  Fact Counter two Section
====================================================================

***/

.fact-counter.style-2 {
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  color: #000;
}

.fact-counter.style-2 .auto-container {
  position: relative;
  width: 50%;
  z-index: 1;
}

.fact-counter.style-2 .column {
  position: relative;
  text-align: center;
  z-index: 5;
}

.fact-counter.style-2 .item {
  position: relative;
  background: #fff;
  margin: 10px 10px 10px 10px;
  height: 220px;
  border: 1px solid #fff;
}

.fact-counter.style-2 .column .icon {
  font-size: 40px;
  position: relative;
  right: 5px;
  margin-bottom: 5px;
  color: #8dc63f;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.fact-counter.style-2 .count-outer {
  position: relative;
  font-size: 40px;
  font-weight: 400;
  padding: 0px 0px 0px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
}

.fact-counter.style-2 .count-outer:before {
}

.fact-counter.style-2 .column .counter-title {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  color: #666;
}

/*** 

====================================================================
    about style
====================================================================

***/

.about {
  position: relative;
}

.about .section-title p {
  font-size: 16px;
  margin: 10px 0 0;
}

.about .img-box {
  margin-bottom: 50px;
}

.about .content {
  margin-bottom: 50px;
}

.about .content h2 {
  font-weight: 700;
  position: relative;
  top: -6px;
  margin-bottom: 10px;
}

.about .content h4 {
  font-size: 20px;
  color: #8dc63f;
  margin-bottom: 15px;
}

.about .content p {
  margin-bottom: 25px;
}

/*** 

====================================================================
    why-chooseus style
====================================================================

***/

.why-chooseus {
  padding-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}

.why-chooseus .item {
  padding: 30px 18px 24px;
  border: 1px solid #f4f4f4;
  margin-bottom: 30px;
  transition: 0.5s ease;
}

.why-chooseus .item:hover {
  border-color: #8dc63f;
  transition: 0.5s ease;
}

.why-chooseus .inner-box {
  position: relative;
  padding-left: 70px;
}

.why-chooseus .inner-box .icon_box {
  position: absolute;
  left: 0;
  top: -20px;
  color: #8dc63f;
  font-size: 50px;
}

.why-chooseus .item h4 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: 0.5s ease;
}

.why-chooseus .item:hover h4 {
  color: #8dc63f;
  transition: 0.5s ease;
}

.why-chooseus .item p {
}

.why-chooseus.style-2 .item {
  border: 0px;
  padding: 8px 18px;
}

/*** 

====================================================================
    gallery style
====================================================================

***/

.gallery {
  position: relative;
}

.gallery .section-title h2 {
  color: #fff;
}

.gallery .item {
  position: relative;
  overflow: hidden;
}

.gallery.style-2 .item {
  margin-bottom: 30px;
}

.gallery .item img {
  width: 100%;
}

.gallery .overlay .top {
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 0%;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.5s cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  visibility: hidden;
}

.gallery .item:hover .overlay .top {
  height: 100%;
  transition: 0.5s cubic-bezier(0.4, 0, 1, 1);
  opacity: 1;
  visibility: visible;
}

.gallery .overlay .top .box {
  display: table;
  width: 100%;
  height: 100%;
}

.gallery .overlay .top .content {
  display: table-cell;
  vertical-align: middle;
}

.gallery .overlay .top a {
  position: relative;
  top: -35px;
  color: #fff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

.gallery .overlay .bottom {
  position: absolute;
  left: 10px;
  bottom: -100%;
  text-align: center;
  right: 10px;
  height: 60px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease;
}

.gallery .item:hover .overlay .bottom {
  bottom: 10px;
  opacity: 1;
  visibility: visible;
  transition: 0.9s ease;
  transition-delay: 0.3s;
}

.gallery .overlay .bottom h4 {
  color: #fff;
  margin-top: 20px;
}

.gallery .img-box {
  position: relative;
  overflow: hidden;
}

.gallery .img-box .overlay {
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 0%;
  background: rgba(141, 198, 63, 0.9);
  transition: 0.7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
  opacity: 0;
  visibility: hidden;
}

.gallery .img-box .overlay .inner-box {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}

.gallery .img-box .overlay .inner-box .content-box {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.gallery .item:hover .overlay {
  height: 100%;
  transition: 0.7s cubic-bezier(0.34, -0.13, 0.34, 1.1);
  opacity: 1;
  visibility: visible;
}

.gallery .overlay a {
  position: relative;
  color: #fff;
  height: 45px;
  width: 45px;
  border: 1px solid #fff;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s ease;
}

.gallery .overlay a:hover {
  background: #fff;
  color: #8dc63f;
  transition: 0.5s ease;
}

.gallery .content h4 {
  font-size: 20px;
  color: #fff;
  margin: 15px 0 7px;
}

.gallery.grid-page .content h4,
.gallery.massonary-page .content h4 {
  color: #303030;
}

.gallery.massonary-page .content {
  padding: 20px;
  border: 1px solid #f4f4f4;
  border-top: 0px;
  padding-top: 0px;
  overflow: hidden;
}

.gallery .content p {
  font-size: 14px;
  color: #8dc63f;
}

.gallery .content .text p {
  color: #999;
}

/*======= gallery filter ==========*/

.post-filter {
  display: inline-block;
  margin: 0;
  right: 0;
  top: 0;
  margin-bottom: 30px;
  position: relative;
  z-index: 999;
}

.post-filter.style-3 {
  margin-top: -88px;
}

.gallery.grid-page {
  padding-top: 80px;
}

.massonary-page .post-filter {
  margin-bottom: 50px;
}

.post-filter li {
  padding: 0;
  margin-bottom: 20px;
}

.post-filter li span {
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin: 0 5px;
  padding: 6px 20px;
  border: 1px solid #404733;
  background: #242d0f;
  border-radius: 18px;
  cursor: pointer;
  transition: 0.5s ease;
}

.post-filter.style-2 li span {
  background: transparent;
  color: #999;
  border-color: #f4f4f4;
}

.post-filter li:hover span,
.post-filter li.active span {
  background: #8dc63f;
  border-color: #8dc63f;
  transition: 0.5s ease;
  color: #fff;
}

.post-filter li span i {
  font-size: 14px;
  margin-right: 5px;
}

/*============ single gallery ============*/

.single-gallery {
  position: relative;
}

.single-gallery .img-box {
  position: relative;
  padding-bottom: 30px;
}

.single-gallery .share-project {
  padding-left: 140px;
  position: relative;
  margin-top: 15px;
}

.single-gallery .share-project .title {
  left: 0;
  position: absolute;
  top: 2px;
}

.single-gallery .share-project .title h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.single-gallery .share-project .social-share {
  padding-left: 40px;
}

.single-gallery .share-project .social-share ul li {
  display: inline-block;
  margin-right: 15px;
}

.single-gallery .share-project .social-share ul li a {
  color: #9a9a9a;
  font-size: 14px;
}

.single-gallery .share-project .social-share ul li a:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

.single-gallery .project-info-list {
  overflow: hidden;
  margin: 0 -15px;
  margin-top: 30px;
}

.single-gallery .project-info-list li {
  border-bottom: 1px solid #f7f7f7;
  float: left;
  margin: 0 15px 20px;
  overflow: hidden;
  padding-bottom: 11px;
  width: 42%;
  padding-left: 30px;
  position: relative;
}

.single-gallery .project-info-list li .icon-holder {
  left: 0;
  position: absolute;
  /* top: -5px; */
}

.single-gallery .project-info-list li .icon-holder i {
  font-size: 14px;
  color: #8dc63f;
}

.single-gallery .project-info-list li .text-holder {
}

.single-gallery .project-info-list li .text-holder h5 {
  color: #252525;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0 0 3px;
}

.single-gallery .project-info-list li .text-holder p {
  margin: 0;
}

/*** 

====================================================================
    call-out style
====================================================================

***/

.call-out {
  background: #8dc63f;
  padding: 5px 0 25px;
}

.call-out .logo {
  margin-top: 23px;
}

.call-out h4 {
  color: #fff;
  margin-top: 33px;
  font-size: 22px;
  font-weight: 700;
}

.call-out .thm-btn.style-3 {
  margin-top: 20px;
}

/*** 

====================================================================
    Event style
====================================================================

***/

.event-style1 .thm-btn.style-2 {
  border-color: rgba(255, 255, 255, 0.3);
}

.event-style1 {
  padding: 80px 0 40px;
  background-size: cover;
}

.event-style1 .section-title h2 {
  color: #666;
}

.event-style1 .item {
  position: relative;
  transition: 0.5s ease-in-out;
  margin-bottom: 50px;
  overflow: hidden;
  padding: 1em;
  border: 1px dashed #ddd;
}

.event-style1 .item.style-2 {
  margin-bottom: 28px;
}

.event-style1 .item.style-2 .img-column {
  width: 30%;
  float: left;
}

.event-style1 .item.style-2 .text-column {
  width: 70%;
  float: left;
}

.event-style1 .item.style-2 .clearfix {
  /* padding: 19px; */
}

.event-style1 .item .lower-content {
  position: relative;
  padding: 0px 19px 0;
  border-left: 0px;
}

.event-style1 .item.style-2 .lower-content {
  padding-top: 0px;
}

.event-style1 .item .date {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 55px;
  background: #8dc63f;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  padding-top: 7px;
  z-index: 9;
  color: #666;
  text-align: center;
}

.event-style1 .item .date span {
  display: block;
  color: #8dc63f;
  background: #333;
  margin-top: 5px;
  padding: 6px 0;
}

.event-style1 .item .lower-content .text p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #666;
}

.event-style1 .item.style-2 .lower-content .text p {
  margin-bottom: 5px;
}

.event-style1 .item .lower-content h4 {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 30px;
  transition: all 300ms ease;
  color: #3b7840;
  font-size: 22px;
}

.event-style1 .item .lower-content h4:hover {
  color: #8dc63f;
  transition: all 300ms ease;
}

.event-style1 .item .post-meta {
  color: #8dc63f;
  font-size: 15px;
  border-top: 1px solid rgba(244, 244, 244, 0.1);
  padding: 17px 19px;
}

.event-style1 .item .post-meta li {
  margin-right: 10px;
}

.event-style1 .item .post-meta i {
  margin-right: 5px;
}

.event-style1 .item .lower-content .default_link {
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  transition: 0.5s ease;
}

.event-style1 .item .lower-content .default_link:hover {
  transition: 0.5s ease;
  color: #8dc63f;
}

.event-style1 .item .lower-content .default_link i {
  font-size: 11px;
}

.event-style1 .item .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: 350px;
}

.event-style1 .item .img-holder a {
  width: 100%;
  overflow: hidden;
}

.event-style1 .item .img-holder img {
  transition: all 0.5s ease 0s;
  object-fit: fill;
}

.event-style1 .item .overlay {
  background-color: rgba(16, 37, 46, 0.9);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.event-style1 .item:hover .img-holder .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotateY(0deg) scale(1, 1);
}

.event-style1 .item .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.event-style1 .item .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.event-style1 .item .img-holder .overlay .box .content a i {
  background: #8dc63f;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 45px;
  line-height: 48px;
  transition: all 500ms ease 0s;
  width: 45px;
}

.event-style1 .item .img-holder .overlay .box .content a:hover i {
  background: #8dc63f;
}

.event-style1 .owl-theme .owl-nav {
  padding: 0;
  position: absolute;
  right: 50%;
  margin: 0;
  margin-right: -51px;
}

.event-style1 .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.event-style1 .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/* event style two  */

.event-style2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.event-style2 .section-title h2 {
  color: #000;
}

.event-style2 .item {
  position: relative;
  transition: 0.5s ease-in-out;
  margin-bottom: 50px;
  overflow: hidden;
  border: 1px solid #cacaca;
}

.event-style2 .item .img-column {
  width: 31%;
  float: left;
}

.event-style2 .item .text-column {
  width: 69%;
  float: left;
}

.event-style2 .item .clearfix {
  padding: 19px;
}

.event-style2 .item .lower-content {
  position: relative;
  padding: 15px 19px 0;
  border-left: 0px;
}

.event-style2 .item .date {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 65px;
  height: 65px;
  background: #8dc63f;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9;
  color: #fff;
  text-align: center;
}

.event-style2 .item .date.old {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 65px;
  height: 65px;
  background: #999;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  z-index: 9;
  color: #fff;
  text-align: center;
}

.event-style2 .item .date span {
  display: block;
  color: #8dc63f;
  height: 55px;
  width: 55px;
  background: #fff;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 5px;
  padding-top: 7px;
}

.event-style2 .item .date.old span {
  display: block;
  color: #999;
  height: 55px;
  width: 55px;
  background: #fff;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 5px;
  padding-top: 7px;
}

.event-style2 .item .lower-content .text p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #999;
}

.event-style2 .item.style-2 .lower-content .text p {
  margin-bottom: 5px;
}

.event-style2 .item .lower-content h4 {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
  transition: all 300ms ease;
  color: #303030;
  font-size: 1.3rem;
}

.event-style2 .item .lower-content h4:hover {
  color: #8dc63f;
  transition: all 300ms ease;
}

.event-style2 .item .post-meta {
  color: #8dc63f;
  font-size: 1rem;
  border-top: 1px solid #f4f4f4;
  padding: 17px 19px;
}

.event-style2 .item .post-meta li {
  margin-right: 10px;
}

.event-style2 .item .post-meta i {
  margin-right: 5px;
}

.event-style2 .item .lower-content {
  display: inline-block;
}

.event-style2 .item .lower-content .default_link {
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  transition: 0.5s ease;
}

.event-style2 .item .lower-content .default_link:hover {
  transition: 0.5s ease;
  color: #8dc63f;
}

.event-style2 .item .lower-content .default_link i {
  font-size: 11px;
}

.event-style2 .item .img-holder {
  display: inline-block;
  width: 100%;
  height: 240px;
  overflow: hidden;
  position: relative;
}

.event-style2 .item .img-holder a {
  width: 100%;
  overflow: hidden;
  display: block;
}

.event-style2 .item .img-holder img {
  transition: all 0.5s ease 0s;
  width: 100%;
  height: 100%;
}

.event-style2 .item .overlay {
  background-color: rgba(16, 37, 46, 0.9);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.event-style2 .item:hover .img-holder .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotateY(0deg) scale(1, 1);
}

.event-style2 .item .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.event-style2 .item .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.event-style2 .item .img-holder .overlay .box .content a i {
  background: #8dc63f;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 45px;
  line-height: 48px;
  transition: all 500ms ease 0s;
  width: 45px;
}

.event-style2 .item .img-holder .overlay .box .content a:hover i {
  background: #8dc63f;
}

.event-style2 .owl-theme .owl-nav {
  padding: 0;
  position: absolute;
  right: 50%;
  margin: 0;
  margin-right: -51px;
}

.event-style2 .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.event-style2 .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*========== before event ===============*/

.event-section.with-padd {
  padding: 80px 0 40px;
}

.event-section .item {
  position: relative;
  transition: 0.5s ease-in-out;
  margin-bottom: 50px;
  max-height: 340px;
  overflow: hidden;
  border: 1px solid #f4f4f4;
}

.event-section .item .img-column {
  width: 47%;
  float: left;
}

.event-section .item .text-column {
  width: 53%;
  float: left;
}

.event-section .item .lower-content {
  position: relative;
  padding: 30px 15px 0;
  border-left: 0px;
  min-height: 337px;
}

.event-section .item .date {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 55px;
  background: #8dc63f;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  padding-top: 7px;
  z-index: 9;
  color: #fff;
  text-align: center;
}

.event-section .item .date span {
  display: block;
  color: #8dc63f;
  background: #fff;
  margin-top: 5px;
  padding: 6px 0;
}

.event-section .item .lower-content .text p {
  margin-bottom: 25px;
  font-size: 14px;
}

.event-section .item .lower-content h4 {
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 20px;
}

.event-section .item .lower-content h4 a {
  color: #222;
  transition: all 300ms ease;
  font-size: 18px;
}

.event-section .item .lower-content h4 a:hover {
  color: #8dc63f;
  transition: all 300ms ease;
}

.event-section .item .lower-content .post-meta {
  color: #8dc63f;
  font-size: 15px;
  margin-bottom: 7px;
}

.event-section .item .lower-content .post-meta i {
  margin-right: 5px;
}

.event-section .item .lower-content .default_link {
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  transition: 0.5s ease;
}

.event-section .item .lower-content .default_link:hover {
  transition: 0.5s ease;
  color: #8dc63f;
}

.event-section .item .lower-content .default_link i {
  font-size: 11px;
}

.event-section .item .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
}

.event-section .item .img-holder a {
  width: 100%;
  overflow: hidden;
}

.event-section .item .img-holder img {
  transform: scale(1);
  transition: all 0.5s ease 0s;
  width: 100%;
  height: 100%;
}

.event-section .item:hover .img-holder img {
  transform: scale(1.1);
}

.event-section .item .overlay {
  background-color: rgba(16, 37, 46, 0.9);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.event-section .item:hover .img-holder .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotateY(0deg) scale(1, 1);
}

.event-section .item .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.event-section .item .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.event-section .item .img-holder .overlay .box .content a i {
  background: #8dc63f;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 45px;
  line-height: 48px;
  transition: all 500ms ease 0s;
  width: 45px;
}

.event-section .item .img-holder .overlay .box .content a:hover i {
  background: #8dc63f;
}

.event-section .owl-theme .owl-nav {
  padding: 0;
  position: absolute;
  right: 50%;
  margin: 0;
  margin-right: -51px;
}

.event-section .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.event-section .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*========style-2===========*/

.event-section.style-2 .item {
  max-height: inherit;
}

.event-section.style-2 .item .lower-content {
  min-height: inherit;
  padding: 15px 25px 20px;
}

.event-section.style-2 .item .date {
  position: absolute;
  right: 20px;
  top: -41px;
  height: 70px;
  z-index: 9;
  width: 70px;
  font-size: 18px;
  border-radius: 50%;
  border: 2px solid #8dc63f;
  background: #ffffff;
  font-weight: 700;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  line-height: 14px;
  padding-top: 13px;
  color: #8dc63f;
  text-align: center;
}

.event-section.style-2 .item .lower-content .text p {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f4f4f4;
}

.event-section.style-2 .overlay2 {
  background-color: rgba(16, 37, 46, 0.9);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
}

.event-section.style-2 .item:hover .overlay2 {
  opacity: 1;
  visibility: visible;
  transition: 0.7s ease;
}

.event-section.style-2 .item .img-holder .thm-btn {
  width: auto;
  top: 50%;
  margin-top: -25px;
}

.event-section.style-2 .item .date span {
  background: transparent;
}

/*=========style-3==========*/

.event-section.style-3 .item {
  max-height: inherit;
  border: 0px;
}

.event-section.style-3 .item:hover .img-holder img {
  transform: scale(1);
}

.event-section.style-3 .item .img-column {
  width: 43%;
}

.event-section.style-3 .item .text-column {
  width: 57%;
}

.event-section.style-3 .item .lower-content {
  position: relative;
  padding: 0px 30px 0;
  border-left: 0px;
  top: -5px;
  min-height: inherit;
}

.event-section.style-3 .blog-sidebar .item {
  margin-bottom: 15px;
}

.event-section.style-3 .blog-sidebar .item .lower-content {
  padding: 0;
}

.event-section.style-3 .overlay2 {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
}

.event-section.style-3 .item:hover .overlay2 {
  opacity: 1;
  visibility: visible;
  transition: 0.7s ease;
}

.event-section.style-3 .item .img-holder .thm-btn {
  width: auto;
  top: 50%;
  margin-top: -25px;
}

.event-section.style-3 .item .lower-content h4 {
  margin-top: 10px;
}

.event-section.style-3 .item .lower-content .text p {
  margin-bottom: 23px;
  padding-bottom: 22px;
  border-bottom: 1px solid #f4f4f4;
}

/*** 

====================================================================
    Testimonials Section style
====================================================================

***/

.testimonials-section {
  position: relative;
  padding: 0 0 80px;
  margin-top: 120px;
}

.testimonials-section .testimonial-slider {
  position: relative;
  margin-top: -63px;
}

.testimonials-section .img-box img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.testimonials-section .quote {
  position: relative;
  color: #323232;
  font-size: 48px;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.testimonials-section .quote:after {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 40%;
  height: 1px;
  background: #323232;
}

.testimonials-section .quote:before {
  content: "";
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 40%;
  height: 1px;
  background: #323232;
}

.testimonials-section .author h4 {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #fff;
  margin-bottom: 15px;
  margin-top: 10px;
}

.testimonials-section .author h4:before {
  content: "";
  position: absolute;
  bottom: -12px;
  right: 0;
  width: 100%;
  height: 1px;
  background: #323232;
}

.testimonials-section .author h4 span {
  color: #8dc63f;
  font-size: 18px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

/*dots*/

.testimonials-section .owl-controls {
  text-align: center;
  display: block;
  position: relative;
  bottom: -10px;
  margin-top: 35px;
}

.testimonials-section .owl-dots .owl-dot {
  display: inline-block;
}

.testimonials-section .owl-dots .owl-dot span {
  border: 2px solid #504f4f;
  border-radius: 50%;
  display: block;
  height: 10px;
  background: transparent;
  width: 10px;
  margin: 5px 7px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.testimonials-section .owl-dots .owl-dot.active span,
.testimonials-section .owl-dots .owl-dot:hover span {
  background-color: #8dc63f;
  border-color: #8dc63f;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

/*** 

====================================================================
  testimonial2
====================================================================

***/

.testimonial2 {
  position: relative;
  padding: 20px 0 20px;
}

.testimonial2 .item {
  position: relative;
  border: 2px solid #f4f4f4;
  margin-bottom: 60px;
}

.testimonial2 .quote {
  position: relative;
  width: 95px;
  margin: 0 auto;
  margin-top: -27px;
  background: #fff;
  color: #ededed;
  font-size: 36px;
}

.testimonial2 .author {
  position: relative;
  max-width: 190px;
  margin: 22px auto 20px;
  border-top: 1px solid #f4f4f4;
  padding-top: 18px;
}

.testimonial2 .title {
  position: relative;
  line-height: 28px;
  margin-bottom: 15px;
  margin-top: 12px;
  font-size: 1.3rem;
}

.testimonial2 .author h4 {
  margin-bottom: 5px;
}

.testimonial2 .author p {
  color: #8dc63f;
}

/*** 

====================================================================
  default-blog
====================================================================

***/

.blog-section {
  position: relative;
}

.default-blog-news {
  position: relative;
  transition: 0.5s ease-in-out;
  margin-bottom: 50px;
}

.default-blog-news:hover {
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1);
}

.default-blog-news .lower-content {
  position: relative;
  padding: 30px 0px 20px;
  border-bottom: 1px solid #f4f4f4;
}

.default-blog-news .lower-content:before {
  width: 100%;
  height: 2px;
  position: absolute;
  content: "";
  background: #eaeaea;
  left: 0;
  bottom: 0;
}

.default-blog-news .lower-content:after {
  width: 100%;
  height: 2px;
  position: absolute;
  content: "";
  background: #8dc63f;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transition: 0.5s ease;
}

.default-blog-news .lower-content:hover:after {
  transform: scaleX(1);
  transition: 0.5s ease;
}

.default-blog-news .date {
  position: absolute;
  left: 0px;
  top: -15px;
  background: #8dc63f;
  line-height: 20px;
  padding: 5px 15px;
  z-index: 9;
  color: #fff;
  text-align: center;
}

.default-blog-news .lower-content .text p {
  margin-bottom: 10px;
}

.default-blog-news .lower-content h4 {
  color: #222;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 12px;
  transition: all 300ms ease;
  font-size: 18px;
}

.default-blog-news .lower-content h4:hover {
  color: #8dc63f;
  transition: all 300ms ease;
}

.default-blog-news .lower-content .post-meta {
  color: #848484;
  font-size: 16px;
  margin-bottom: 7px;
}

.default-blog-news .lower-content .default_link {
  text-transform: uppercase;
  color: #222;
  font-weight: 700;
  transition: 0.5s ease;
}

.default-blog-news .lower-content .default_link:hover {
  transition: 0.5s ease;
  color: #8dc63f;
}

.default-blog-news .lower-content .default_link i {
  font-size: 11px;
}

.default-blog-news .img-holder {
  display: block;
  overflow: hidden;
  position: relative;
}

.default-blog-news .img-holder a {
  width: 100%;
}

.default-blog-news .img-holder img {
  transform: scale(1);
  transition: all 0.5s ease 0s;
  width: 100%;
}

.default-blog-news:hover .img-holder img {
  transform: scale(1.1);
}

.default-blog-news .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.default-blog-news:hover .img-holder .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotateY(0deg) scale(1, 1);
}

.default-blog-news .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.default-blog-news .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.default-blog-news .img-holder .overlay .box .content a i {
  background: #8dc63f;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 45px;
  line-height: 48px;
  transition: all 500ms ease 0s;
  width: 45px;
}

.default-blog-news .img-holder .overlay .box .content a:hover i {
  background: #8dc63f;
}

/*blog-large news*/

.blog-large .default-blog-news .lower-content .text p {
  margin-bottom: 20px;
  margin-top: 10px;
}

.blog-single-post .default-blog-news .lower-content h4,
.blog-large .default-blog-news .lower-content h4 {
  font-size: 28px;
}

.blog-large .default-blog-news .lower-content {
  padding-bottom: 5px;
}

.blog-single-post .post-area,
.blog-large .post-area {
  padding: 80px 62px 80px 0px;
  border-right: 1px solid #f4f4f4;
  margin-right: 38px;
}

.blog-single-post .default-blog-news .lower-content:before,
.blog-single-post .default-blog-news .lower-content:after {
  display: none;
}

.blog-single-post .default-blog-news {
  margin-bottom: 20px;
}

/*=================== blog-sidebar ===================*/

.blog-sidebar {
  position: relative;
}

.blog-sidebar .section-title {
  margin-bottom: 35px;
}

.blog-sidebar .sidebar_search {
  height: 54px;
  position: relative;
  margin-bottom: 25px;
}

.blog-sidebar .sidebar_search input {
  width: 100%;
  height: 54px;
  border: none;
  border: 1px solid #f1f1f1;
  padding: 0 56px 0 12px;
  color: #9e9e9e;
}

.blog-sidebar .sidebar_search button {
  width: 54px;
  height: 100%;
  position: absolute;
  top: 0;
  background: #8dc63f;
  right: 0;
  color: #fff;
}

.blog-sidebar .category-style-one {
  position: relative;
}

.blog-sidebar .category-style-one form {
  margin-bottom: 3em;
  margin-top: -1em;
}

.blog-sidebar .category-style-one form label {
  border-bottom: 1px solid #eaeaea;
  margin: 0;
  padding-bottom: 0px;
  display: block;
}

.blog-sidebar .category-style-one form label p {
  font-weight: normal;
  color: #848484;
  position: relative;
  width: 100%;
  transition: 0.5s ease;
  line-height: 27px;
  display: block;
}

.blog-sidebar .category-style-one form label p:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/* Customize the label (the container) */

.checkbox-container {
  position: relative;
  font-weight: normal;
  color: #848484;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.5s ease;
  display: block;
  width: 100%;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

/* Hide the browser's default checkbox */

.checkbox-container input.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked ~ .checkmark {
  background-color: #8dc63f;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkbox-container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*post-thumb*/

.blog-sidebar .popular-post {
  position: relative;
  margin-bottom: 50px;
}

.blog-sidebar .popular-post .item {
  position: relative;
  font-size: 14px;
  margin-bottom: 20px;
  min-height: 50px;
  padding: 0px 0px 20px 85px;
  color: #cccccc;
  border-bottom: 1px solid #f1f1f1;
}

.blog-sidebar .popular-post .item .post-thumb {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 70px;
  height: 70px;
}

.blog-sidebar .popular-post .item .post-thumb img {
  width: 100%;
  display: block;
}

.blog-sidebar .popular-post .item .post-thumb a {
  position: relative;
}

.blog-sidebar .popular-post .item .post-thumb a:after {
  position: absolute;
  content: "\f0c1";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  left: 0px;
  top: 0px;
  text-align: center;
  line-height: 70px;
  width: 100%;
  height: 100%;
  background: rgba(141, 198, 63, 0.9);
  color: #ffffff;
  opacity: 0;
  transition: 0.5s ease;
}

.blog-sidebar .popular-post .item .post-thumb:hover a:after {
  opacity: 1;
  transition: 0.5s ease;
}

.blog-sidebar .popular-post .item h4 {
  position: relative;
  font-size: 16px;
  margin: 0px 0px 8px;
  line-height: 24px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.blog-sidebar .popular-post .item h4:hover {
  color: #8dc63f;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.blog-sidebar .recent-posts.post-thumb .post .post-info {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  line-height: 1.6em;
  color: #9c9c9c;
}

.blog-sidebar .popular-post .item .post-info {
  color: #848484;
  font-size: 16px;
}

.blog-sidebar .popular-post .item .post-info i {
  color: #8dc63f;
  font-size: 14px;
  margin-right: 6px;
  top: -1px;
  position: relative;
}

.blog-sidebar .event-section2 {
  margin-bottom: 40px;
}

.blog-sidebar .event-section2 .owl-theme .owl-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 0;
  margin-bottom: 27px;
}

.blog-sidebar .event-section2 .owl-theme .owl-nav [class*="owl-"] {
  color: #202f35;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  height: 40px;
  border: 0px;
  width: 18px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.blog-sidebar .event-section2 .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*facebook feed*/

.blog-sidebar .facebook-feed {
  position: relative;
  background: #f7f7f7;
  overflow: hidden;
}

.blog-sidebar .facebook-feed .img-box {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 120px;
}

.blog-sidebar .facebook-feed .overlay {
  position: absolute;
  margin: 10px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.blog-sidebar .facebook-feed .inner-box {
  position: relative;
  padding-left: 70px;
  margin-bottom: 32px;
}

.blog-sidebar .facebook-feed .inner-box .logo {
  position: absolute;
  left: 0;
  top: 0;
}

.blog-sidebar .facebook-feed .inner-box h4 {
  color: #fff;
  font-weight: 600;
  position: relative;
  margin-top: 0px;
  margin-bottom: 3px;
}

.blog-sidebar .facebook-feed .inner-box .like {
  color: #fff;
}

.blog-sidebar .facebook-feed .link a {
  color: #58606f;
  background: #fff;
  display: inline-block;
  line-height: 22px;
  font-size: 13px;
  text-align: center;
  padding: 0 10px;
  border: 1px solid #eaeaea;
}

.blog-sidebar .facebook-feed .link a i.fb-icon {
  color: #fff;
  background: #39579b;
  font-size: 12px;
  padding: 2px 3px 0 4px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.blog-sidebar .facebook-feed .link a i.mail {
  color: #6a7081;
  margin-right: 6px;
}

.blog-sidebar .facebook-feed .like-people {
  background: #fff;
  margin: 20px 9px;
  padding: 5px 9px 15px;
}

.blog-sidebar .facebook-feed .like-people p {
  font-size: 13px;
}

.blog-sidebar .facebook-feed .like-people img {
  display: block;
  overflow: hidden;
  width: 26px;
  height: 26px;
}

/*archive*/

.sidebar-archive .bootstrap-select {
  background: #f7f7f7;
  color: #848484;
  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 40px;
}

.sidebar-archive .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.sidebar-archive .btn-group.open .dropdown-toggle:focus {
  border: none;
  outline: none;
}

.sidebar-archive .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.sidebar-archive .dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #8dc63f;
  color: #fff;
}

.sidebar-archive .form-control:focus {
  border-color: #48c7ec;
  box-shadow: none;
}

/*instagram*/

.sidebar-intsgram ul {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 50px;
}

.sidebar-intsgram ul li {
  float: left;
  width: 33.333333333333333333%;
  padding: 5px 4px;
}

.sidebar-intsgram ul li .inner-box {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.sidebar-intsgram ul li .inner-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(141, 198, 63, 0.9);
  -webkit-transform: translate3d(0, 100px, 0);
  transform: translate3d(0, 100px, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s ease, opacity 0.4s ease,
    visibility 0.4s ease;
  transition: transform 0.4s ease, opacity 0.4s ease, visibility 0.4s ease;
}

.sidebar-intsgram ul li .inner-box .overlay .box {
  display: table;
  width: 100%;
  height: 100%;
}

.sidebar-intsgram ul li .inner-box .overlay .box .content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.sidebar-intsgram ul li .inner-box .overlay .box .content a {
  font-size: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}

.sidebar-intsgram ul li:hover .inner-box .overlay {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

/*=============================== blog single post ===============================================*/

.blog-single-post .content-box .text p {
  margin-bottom: 20px;
  margin-top: 20px;
}

.blog-single-post .default-blog-news .lower-content {
  padding-bottom: 0px;
  border-bottom: 0px;
}

.blog-single-post .default-blog-news:hover {
  box-shadow: none;
}

.blog-single-post .section-title {
  margin-bottom: 30px;
}

/*---------------- Author -------------*/

.blog-single-post .author {
  background-color: #ffffff;
  position: relative;
  margin-bottom: 2px;
  margin-top: 35px;
}

.blog-single-post .author img {
  position: absolute;
}

.blog-single-post .author-comment {
  margin-left: 340px;
  padding-left: 30px;
}

.blog-single-post .author-comment .quote {
  font-size: 25px;
  color: #f1f1f1;
  margin-bottom: 7px;
  position: relative;
  top: -5px;
}

.blog-single-post .author-comment h5 {
  font-weight: 600;
  margin: 16px 0 5px 0;
  text-transform: uppercase;
  font-size: 18px;
}

.blog-single-post .author-comment p.a-title {
  color: #8dc63f;
  font-size: 16px;
}

/*share-box*/

.share-box {
  background: #ffffff;
  padding-top: 20px;
  margin-top: 35px;
  margin-bottom: 50px;
  border-top: 1px solid #f4f4f4;
}

.share-box .tag-box span {
  font-size: 14px;
  color: #3f3e3e;
}

.share-box .tag-box a {
  color: #8dc63f;
  font-size: 15px;
  transition: 0.3s ease;
  line-height: 30px;
  text-transform: capitalize;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.share-box .tag-box a:hover {
  color: #8dc63f;
  transition: 0.3s ease;
}

.share-box .social-box span {
  color: #252525;
  margin-right: 13px;
  font-size: 18px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.share-box .social {
  display: inline-block;
  margin-bottom: 0;
  margin-left: -2.5px;
}

.share-box .social > li {
  padding-right: 0;
  margin: 0;
  padding-left: 0;
}

.share-box .social a {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  color: #bcbcbc;
  text-align: center;
  margin: 0 3px;
  border: 1px solid #eee;
  display: block;
  line-height: 38px;
  transition: all 0.5s ease;
}

.share-box .social a:hover {
  color: #ffffff;
  background: #8dc63f;
  border-color: #8dc63f;
}

.share-box .tag-box li {
  margin: 0;
  display: inline-block;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
  font-size: 18px;
}

/*==========author===========*/

.post-author {
  position: relative;
  color: #777777;
  margin-bottom: 64px;
}

.post-author .inner-box {
  position: relative;
  padding: 33px 35px 30px 15px;
  min-height: 160px;
  border: 1px solid #f4f4f4;
  text-align: center;
}

.post-author .inner-box .author-thumb {
  position: relative;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.post-author .inner-box .author-thumb img {
  display: block;
  width: 100%;
}

.post-author h4 {
  position: relative;
  margin: 13px 0px 16px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: #252525;
}

.post-author ul li {
  margin: 0;
  margin-top: 10px;
}

.post-author ul li a {
  padding: 0 4px;
  color: #999;
  font-size: 14px;
  transition: 0.5s ease;
}

.post-author ul li a:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*=============== comment =============*/

.single-comment {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 32px;
  padding-left: 80px;
}

.single-comment .img-holder {
  left: 0;
  position: absolute;
  top: 0;
}

.single-comment .text-holder {
  border: 1px solid #f4f4f4;
  padding: 20px;
}

.single-comment .text-holder .top {
  margin: 0 0 8px;
  overflow: hidden;
}

.single-comment .rating {
  margin-top: -4px;
  padding-left: 20px;
}

.single-comment .rating li {
  display: inline-block;
  margin-right: 3px;
}

.single-comment .rating i {
  color: #8dc63f;
  font-size: 14px;
}

.single-comment .text-holder p {
  margin: 0 0 15px;
}

.single-comment .text-holder .reply {
  color: #ab7442;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

/*==feedback======*/

.feedback {
  padding-bottom: 30px;
}

.feedback p {
  margin-bottom: 5px;
}

.feedback .rating {
  display: inline-block;
  margin: 0 0px;
  border-left: 1px solid #d6d6d6;
  padding-right: 10px;
  padding-left: 10px;
}

.feedback .rating:first-child {
  padding-left: 0px;
  border: 0px;
}

.feedback .rating li {
  display: inline-block;
}

.feedback .rating li a i {
  color: #d6d6d6;
  transition: all 500ms ease;
  font-size: 14px;
}

.feedback .rating.active li i,
.feedback .rating:hover i {
  color: #8dc63f;
}

/*** 

====================================================================
  feature 
====================================================================

***/

.feature {
  position: relative;
  background: #f7f7f7;
}

.feature .section-title {
  margin-bottom: 30px;
}

.feature .list-style-1 {
  margin: 25px 0 50px;
}

.feature .list-style-1 li {
  margin: 10px 0;
  color: #848484;
  font-size: 14px;
}

.feature .list-style-1 li i {
  color: #8dc63f;
  margin-right: 20px;
}

.feature .video-wrapper {
  background: #f7f7f7 !important;
}

/*** 

====================================================================
    client Section style
====================================================================

***/

span.has-tooltip {
  border-bottom: 1px dotted gray;
  cursor: help;
}

.clients-section .section-title {
  margin-bottom: 10px;
}

.clients-section .tooltip-inner {
  background: #8dc63f;
}

.clients-section .tooltip.top .tooltip-arrow {
  border-top-color: #8dc63f;
}

.clients-section .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
  padding-bottom: 70px;
  padding-top: 40px;
}

.clients-section .owl-theme .owl-nav {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin: 0;
  margin-bottom: 36px;
  bottom: 0;
}

.clients-section .owl-theme .owl-nav {
  padding: 0;
  position: absolute;
  right: 50%;
  margin: 0;
  margin-right: -51px;
}

.clients-section .owl-theme .owl-nav [class*="owl-"] {
  color: #303030;
  font-size: 15px;
  margin: 0 5px;
  padding: 0;
  background-color: transparent;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  line-height: 41px;
  border: 2px solid #f4f4f4;
  font-weight: 700;
  text-align: center;
  margin-left: 1px;
  display: inline-block;
  transition: color 0.3s ease;
}

.clients-section .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

/*** 

====================================================================
  Main Footer
====================================================================

***/

.main-footer {
  position: relative;
  background: #003000;
  /* padding: 25px 0 80px; */
}

.main-footer .footer-widget {
  margin-top: 50px;
}

.main-footer .section-title {
  margin-bottom: 20px;
}

.main-footer .section-title:after,
.main-footer .section-title:before {
  display: none;
}

.main-footer .section-title h4 {
  color: #fff;
  padding-top: 18px;
  padding-bottom: 10px;
}

.main-footer .footer-logo {
  margin-bottom: 40px;
  padding-top: 5px;
  width: 110px;
}

/*============ about-column =================*/

.main-footer .about-column .text {
  margin-bottom: 20px;
}

.about-column li {
  position: relative;
  padding-left: 30px;
  font-size: 15px;
  color: #999;
  line-height: 26px;
  margin-bottom: 14px;
  list-style: none;
}

.about-column li span {
  font-size: 18px;
  color: #8dc63f;
  position: absolute;
  left: 0;
  top: 6px;
}

/*============ link-column =================*/

.main-footer .link-column li {
  position: relative;
  margin-bottom: 10px;
  list-style: none;
}

.main-footer .link-column li a {
  position: relative;
  margin-left: 15px;
  color: #999;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.main-footer .link-column li a:hover {
  color: #8dc63f;
}

.main-footer .link-column li a:before {
  content: "\f0dd";
  position: absolute;
  font-size: 16px;
  font-family: FontAwesome;
  left: -19px;
  top: 0px;
  transform: rotate(-90deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.main-footer .link-column li a:hover:before {
  color: #8dc63f;
  transition: 0.5s ease-in-out;
}

.main-footer .link-column li a:after {
  content: "";
  position: absolute;
  left: 0px;
  background: transparent;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transition: 0.5s ease-in-out;
}

.main-footer .link-column li a:hover:after {
  background-color: #8dc63f;
  transition: 0.5s ease;
}

/*============ post-column =================*/

.main-footer .post-column .post {
  position: relative;
  padding-left: 90px;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid #363636;
}

.main-footer .post-column .post:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.main-footer .post-column .post-thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
}

.main-footer .post-column h5 {
  position: relative;
  top: -5px;
  font-size: 16px;
  color: #999;
  line-height: 26px;
}

.main-footer .post-column .post-info {
  color: #8dc63f;
  font-size: 15px;
}

/*============ contact-column =================*/

.main-footer .contact-column {
  position: relative;
}

.main-footer .contact-column h5 {
  font-size: 16px;
  color: #999;
  margin-bottom: 18px;
}

.main-footer .contact-column form {
  position: relative;
  margin-bottom: 25px;
}

.main-footer .contact-column input {
  background: #2e2e2e;
  border: 1px solid #363636;
  width: 100%;
  padding: 0 15px;
  line-height: 40px;
}

.main-footer .contact-column button {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 42px;
  width: 40px;
  line-height: 42px;
  background: #8dc63f;
  color: #fff;
}

.main-footer .contact-column p {
  padding-bottom: 19px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #363636; */
}

/*===========footer bottom ================*/

.footer-bottom {
  background: #003000;
  border-top: 1px solid #2b2f25;
  padding: 21px 0;
  z-index: 1;
}

.footer-bottom .copy-text {
  color: #848484;
}

.footer-bottom .copy-text a {
  color: #8dc63f;
}

.footer-bottom a {
  color: #8dc63f;
  font-size: 16px;
  transition: 0.3s ease;
}

/*===========footer bottom ================*/
.coders {
  background-color: #002000;
  padding: 1em;
  text-align: center;
}

/************************** 
* Inner Banner styles 
***************************/

.inner-banner {
  position: relative;
  background-repeat: no-repeat;
  z-index: 0;
  /* background-image: url(../images/kierans/dandelions.jpg); */
  /* background-image: url(https://images.pexels.com/photos/2355518/pexels-photo-2355518.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260); */
}

/* .inner-banner:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
} */

.inner-banner h1 {
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  background: #00000077;
}

.inner-banner .box {
  position: relative;
  display: table;
  height: 240px;
  width: 100%;
  text-align: center;
}

.breadcumb-wrapper {
  background: #ecffd3;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #f4f4f4;
}

.breadcumb-wrapper ul,
.breadcumb-wrapper li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 700;
  color: #8dc63f;
  display: inline-block;
}

.breadcumb-wrapper ul.link-list li .link {
  color: #398846;
  font-weight: 700;
  font-size: 14px;
  transition: 0.3s ease;
  display: inline-block;
}

.breadcumb-wrapper ul.link-list li .link:hover {
  transition: 0.3s ease;
  color: #141413;
}

.breadcumb-wrapper ul.link-list li .link i {
  color: #fac012;
  margin-right: 7px;
}

.breadcumb-wrapper ul.link-list li .link:after {
  content: ">";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin: 0 10px;
  display: inline-block;
}

.breadcumb-wrapper .link.get-qoute {
  text-transform: uppercase;
  font-weight: 700;
  color: #8dc63f;
  font-size: 14px;
}

.breadcumb-wrapper .link.get-qoute i {
  margin-left: 7px;
  color: #8dc63f;
  margin-right: 7px;
  font-size: 14px;
}

/*** 

====================================================================
  about page
====================================================================

***/

/*==========our mission2 ===========*/

.our-mission2 {
  background: #f7f7f7;
}

.our-mission2 .item {
  margin-bottom: 74px;
}

.our-mission2 h3 {
  background: #fff;
  color: #8dc63f;
  border: 1px solid #f4f4f4;
  width: 65px;
  text-align: center;
  margin: 0 auto;
  height: 80px;
  line-height: 80px;
  margin-bottom: 24px;
  margin-top: -40px;
}

.our-mission2 h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

/***
==============================
   Team Member styles
============================== 
***/

.teams-table th {
  font-size: large;
  text-align: center;
}

.teams-table td {
  text-align: center;
}

.our-team {
  position: relative;
}

.our-team .section-title .thm-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.our-team .section-title:before {
  display: none;
}

.single-team-member .img-box {
  position: relative;
  overflow: hidden;
  display: block;
}

.single-team-member .img-box img {
  transition: all 0.5s ease;
  transform: scale(1);
}

.our-team .single-team-member {
  position: relative;
  margin-bottom: 50px;
  display: inline-block;
}

.our-team .single-team-member:before {
  pointer-events: none;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 0%;
  right: 0;
  opacity: 0;
  background: #f7f7f7;
  transition: 0.7s ease;
}

.our-team .single-team-member:hover:before {
  opacity: 1;
  height: 100%;
  transition: 0.7s ease;
}

.single-team-member .img-box .overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(141, 198, 63, 0.9);
  width: 100%;
  transform: scale(0.7, 0);
  transition: 0.5s ease-in-out;
}

.single-team-member:hover .img-box .overlay {
  transform: scale(1, 1);
  transition: 0.5s ease-in-out;
}

.single-team-member .inner-box {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}

.single-team-member .social {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1);
}

.single-team-member .social li {
  padding: 0;
  margin: 0 3px;
}

.single-team-member .social li a {
  color: #ffffff;
  transition: color 0.4s ease;
  font-size: 14px;
}

.single-team-member .img-box .overlay ul li a {
  height: 42px;
  width: 42px;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  background: transparent;
  text-align: center;
  line-height: 44px;
  transition: 0.5s ease;
}

.single-team-member .img-box .overlay ul li a:hover {
  transition: 0.5s ease;
}

.single-team-member .img-box .overlay ul li:last-child a {
}

.single-team-member h4 {
  color: #222;
  margin-top: 12px;
  margin-bottom: 6px;
}

.single-team-member .author-info {
  position: relative;
  overflow: hidden;
  border: 1px solid #f4f4f4;
  padding: 15px 20px;
}

.single-team-member p {
  color: #8dc63f;
  transition: 0.5s ease;
  margin-bottom: 8px;
}

.single-team-member .text {
  margin-top: 15px;
}

.single-team-member ul {
  border-top: 1px solid #f4f4f4;
  padding-top: 10px;
}

.single-team-member ul li {
  color: #848484;
  margin: 4px 0;
}

.single-team-member ul li a {
  color: #848484;
}

.single-team-member .author-info ul li i {
  font-size: 14px;
  margin-right: 10px;
  color: #8dc63f;
  border-radius: 1px;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 15px;
}

/***
==============================
   faq-section styles
============================== 
***/

.faq-section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.faq-section .accordion-box {
  margin-bottom: 50px;
}

.faq-section .accordion-box .accordion .acc-content {
  padding: 17px 20px;
}

.faq-section .default-form-area {
  background: #f7f7f7;
  border: 1px solid #f4f4f4;
  padding: 30px 30px 10px;
  margin-bottom: 50px;
}

.faq-section .default-form input[type="text"],
.faq-section .default-form input[type="email"],
.faq-section .default-form input[type="password"],
.faq-section .default-form input[type="number"],
.faq-section .default-form select,
.faq-section .default-form textarea {
  background: transparent;
  border-color: #e2e2e2;
}

.faq-section .default-form textarea {
  height: 130px;
}

/***
==============================
   faq-section2 styles
============================== 
***/

.faq-section2 .accordion-box {
  margin-bottom: 50px;
}

.faq-section2 h2 {
  margin-bottom: 20px;
  line-height: 40px;
}

.faq-section2 .accordion-box .accordion .acc-content {
  padding: 12px 20px;
}

.faq-section2 .accordion .acc-btn p {
  color: #fff;
}

.faq-section2 .accordion .acc-btn {
  border: 1px solid rgba(244, 244, 244, 0.2);
}

.faq-section2 .default-form-area {
  background: #f7f7f7;
  border: 1px solid #f4f4f4;
  padding: 30px 30px 10px;
  margin-bottom: 50px;
}

.faq-section2 .default-form input[type="text"],
.faq-section2 .default-form input[type="email"],
.faq-section2 .default-form input[type="password"],
.faq-section2 .default-form input[type="number"],
.faq-section2 .default-form select,
.faq-section2 .default-form textarea {
  background: transparent;
  border-color: #e2e2e2;
  border-radius: 25px;
  padding: 12px 20px;
}

.faq-section2 .default-form textarea {
  height: 130px;
}

.faq-section2 .default-form .thm-btn {
  width: 100%;
}

/***
==============================
   volunteer styles
============================== 
***/

.volunteer {
  position: relative;
}

.volunteer .section-title {
  margin-top: 26px;
}

.volunteer p {
  margin-bottom: 40px;
}

.volunteer .default-form textarea {
  height: 132px;
}

/*** 
=============================================
    Get Touch Area style
=============================================
***/

.get-touch-area {
  padding-top: 80px;
  padding-bottom: 80px;
}

.get-touch-area .sec-title {
  display: block;
  padding: 0 160px 40px;
}

.get-touch-area .sec-title p {
  color: #a4a4a4;
  font-size: 20px;
  line-height: 30px;
  margin: 25px 0 0;
}

.get-touch-area .single-item {
  border: 1px solid #f4f4f4;
  display: block;
  padding: 39px 20px 31px;
}

.get-touch-area .single-item .icon-holder {
  padding-bottom: 33px;
}

.get-touch-area .single-item .icon-holder span:before {
  color: #8dc63f;
  font-size: 50px;
}

.get-touch-area .single-item .text-holder {
}

.get-touch-area .single-item .text-holder h3 {
  color: #222222;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
}

.get-touch-area .single-item .text-holder .border {
  height: 1px;
  width: 60px;
  background: #f4f4f4;
  display: inline-block;
}

.get-touch-area .single-item .text-holder p {
  margin: 15px 0 0;
}

.get-touch-area .single-item .text-holder p a {
  color: #8dc63f;
}

/*** 
=============================================
    Contact Form Area style
=============================================
***/

.contact-form-area {
  padding-bottom: 50px;
}

.contact-form-area .default-form-area {
  border: 2px solid #f4f4f4;
  padding: 28px 28px 0;
  margin-bottom: 50px;
}

.contact-form-area .default-form input[type="text"],
.contact-form-area .default-form input[type="email"],
.contact-form-area .default-form input[type="password"],
.contact-form-area .default-form input[type="number"],
.contact-form-area .default-form select,
.contact-form-area .default-form textarea {
  border-radius: 25px;
}

.contact-form-area .default-form textarea {
  border-radius: 25px;
  resize: none;
}

.contact-form-area .form-right-box {
  background: #f7f7f7;
  padding-top: 40px;
  padding-bottom: 37px;
}

.contact-form-area .form-right-box .img-box {
  display: block;
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 25px;
  overflow: hidden;
}

.contact-form-area .form-right-box h4 {
  color: #222222;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 10px;
}

.contact-form-area .form-right-box span {
  color: #8dc63f;
}

.contact-form-area .form-right-box p {
  margin: 9px 0 5px;
}

.contact-form-area .form-right-box .border {
  height: 1px;
  width: 60px;
  display: inline-block;
  background: #e3e3e3;
}

.contact-form-area .form-right-box .social-links {
  display: block;
  margin: 18px 0 0;
}

.contact-form-area .form-right-box .social-links li {
  display: inline-block;
  margin: 0 10px;
}

.contact-form-area .form-right-box .social-links li a i {
  font-size: 16px;
  color: #848484;
  transition: all 500ms ease;
}

.contact-form-area .form-right-box .social-links li a:hover i {
  color: #8dc63f;
}

/*** 

====================================================================
  google map Section
====================================================================

***/

.home-google-map #contact-google-map {
  width: 100%;
  height: 450px;
}

.home-google-map .gmnoprint {
  display: none;
}

/*============== datepicker ======================*/

#ui-datepicker-div.ui-widget-content {
  border: 1px solid #c5c5c5;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #777;
  color: #252525;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  border-radius: 0px;
  padding: 5px;
}

#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
  background: #8dc63f none repeat scroll 0 0;
  border: 2px solid #8dc63f;
  border-radius: 0;
  color: #fff;
  font-weight: 700;
  padding: 5px 0;
  position: relative;
}

.ui-datepicker .ui-datepicker-prev {
  left: 5px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border-radius: 30%;
  height: 20px;
  position: absolute;
  top: 7px;
  width: 20px;
  transition: all 500ms ease;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
  right: 5px;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  top: 7px;
  background: #fff;
  border: none;
}

.ui-datepicker table {
  border-collapse: collapse;
  font-size: 13px;
  margin: 0 0 0.4em;
  width: 100%;
}

.ui-datepicker th {
  border: medium none;
  font-weight: 600;
  padding: 2px 3px;
  text-align: center;
}

.ui-datepicker-calendar .ui-state-default {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #252525;
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  line-height: 25px;
  margin-bottom: 5px;
  padding: 4px 2px;
  text-align: center;
  transition: all 500ms ease;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
  background: #8dc63f;
  color: #fff;
  border-color: #8dc63f;
}

.default-form.style-5 .form-group i {
  position: absolute;
  right: 15px;
  top: 16px;
}

/*============ event-filter ===================*/

.event-filter {
  background: #f7f7f7;
  padding: 25px 20px 10px;
  margin-bottom: 48px;
}

.event-filter .tab-buttons {
  margin-bottom: 0px;
}

.event-filter .tab-buttons li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  padding: 0 20px;
  cursor: pointer;
  padding-right: 15px;
}

.event-filter .tab-buttons li:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f4f4f4;
  left: 0;
  top: 5px;
}

.event-filter .tab-buttons li:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
  left: 3px;
  top: 8px;
}

.event-filter .tab-buttons li.active-btn:after {
  background: #8dc63f;
}

.event-filter .tab-buttons li.active-btn:before {
  border-color: #8dc63f;
}

.default-form.style-5
  .bootstrap-select.btn-group
  .dropdown-toggle
  .filter-option {
  background: #fff;
}

.default-form.style-5 .select-box .btn:after {
  color: #000;
}

.default-form.style-5 .thm-btn {
  padding: 0 20px;
  line-height: 38px;
}

/*============ even-single ============*/

.single-event {
  border-right: 1px solid #f4f4f4;
}

.single-event .content {
  /* padding: 20px 30px 40px; */
  /* border-left: 1px solid #f4f4f4; */
  /* border-right: 1px solid #f4f4f4; */
}

.single-event .section-title {
  margin-bottom: 30px;
}

.single-event .content .text h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.single-event .content .text p {
}

.single-event .event-timeline {
  background: #f6fff4;
  padding: 40px 30px 20px;
}

.single-event .event-timeline li {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  line-height: 26px;
}

.single-event .event-timeline li:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.single-event .event-timeline li i {
  color: #8dc63f;
  margin-right: 10px;
}

.single-event .event-timeline li b {
  color: #303030;
}

.single-event .list2 {
  margin-top: 25px;
}

.single-event .list2 li {
  padding-bottom: 12px;
}

.single-event .list2 li i {
  padding-right: 10px;
  color: #8dc63f;
}

.single-event .donator .caption {
  text-align: center;
}

.single-event .donator .caption h5 {
  margin: 20px 0 5px;
  font-size: 16px;
}

.single-event .donator .caption p {
  color: #8dc63f;
}

/*share*/

.single-event .share {
  position: relative;
  background: #f7f7f7;
  padding: 20px 30px 8px;
  margin-bottom: 70px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.single-event .share .tag-box span {
  font-size: 14px;
  color: #3f3e3e;
}

.single-event .share .tag-box a {
  color: #8dc63f;
  font-size: 15px;
  transition: 0.3s ease;
  line-height: 30px;
  text-transform: capitalize;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.single-event .share .tag-box a:hover {
  color: #8dc63f;
  transition: 0.3s ease;
}

.single-event .share .social-box span {
  color: #252525;
  margin-right: 13px;
  font-size: 18px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.single-event .share .social {
  display: inline-block;
  margin-bottom: 0;
  margin-left: -2.5px;
}

.single-event .share .social > li {
  padding-right: 0;
  margin: 0;
  padding-left: 0;
  display: inline-block;
}

.single-event .share .social a {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  color: #bcbcbc;
  text-align: center;
  margin: 0 3px;
  border: 1px solid #eee;
  display: block;
  line-height: 38px;
  transition: all 0.5s ease;
}

.single-event .share .social a:hover {
  color: #ffffff;
  background: #8dc63f;
  border-color: #8dc63f;
}

.single-event .share .tag-box li {
  margin: 0;
  display: inline-block;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  color: #303030;
  font-size: 18px;
}

.single-event .share .thm-btn.style-2 {
  top: -6px;
}

.single-event .img-box {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.single-event .img-box image {
  position: relative;
  margin: auto auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.countdown-timer {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 15px 0;
}

.countdown-timer li {
  position: relative;
  display: inline-block;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  text-align: center;
  margin: 0 10px;
  border: 2px solid #8dc63f;
  line-height: 24px;
  padding-top: 20px;
}

.countdown-timer li span.days,
.countdown-timer li span.hours,
.countdown-timer li span.minutes,
.countdown-timer li span.seconds {
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  display: block;
}

.countdown-timer li span.timeRef {
  position: relative;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
}

.event-tab .tab-buttons {
  margin-bottom: 50px;
  background: #f7f7f7;
  padding: 26px 0;
  text-align: center;
}

.event-tab .tab-buttons li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  padding: 0 20px;
  cursor: pointer;
  padding-right: 15px;
}

.event-tab .tab-buttons li:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f4f4f4;
  left: 0;
  top: 5px;
}

.event-tab .tab-buttons li:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
  left: 3px;
  top: 8px;
}

.event-tab .tab-buttons li.active-btn:after {
  background: #8dc63f;
}

.event-tab .tab-buttons li.active-btn:before {
  border-color: #8dc63f;
}

/************************** 
* Cart Page styles 
***************************/

.cart-section {
  position: relative;
}

.cart-section ul {
  margin: 0;
  padding: 0;
}

.cart-section ul li {
  list-style: none;
}

.cart-section .thm-btn {
  line-height: 46px;
}

.cart-section .sec-pad {
  padding-bottom: 70px;
}

.cart-section .sec-title.medium {
  padding-bottom: 25px;
}

.cart-outer {
  position: relative;
  background: #fafafa;
}

.cart-outer h3 {
  color: #fff;
  background: #428a36;
}

.cart-section .cart-outer .select-box:before {
  display: none;
}

.cart-section .table-outer {
  position: relative;
  width: 100%;
}

.cart-section .cart-table {
  width: 100%;
}

.cart-table .cart-header {
  position: relative;
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 7px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  background: #f7f7f7;
}

.cart-table thead tr th {
  line-height: 24px;
  padding: 20px 15px;
  font-weight: normal;
}

.cart-table thead tr th.prod-column {
  text-align: left;
}

.cart-table tbody tr td {
  line-height: 24px;
  padding: 0px 0px;
  font-size: 16px;
  font-weight: bold;
  color: yellow;
}

.cart-table tbody tr .qty {
  width: 120px;
  padding-right: 10px;
}

.cart-table tbody tr .qty .quantity-spinner {
  background: black;
}

.cart-table tbody tr .prod-column .column-box {
  position: relative;
  min-height: 70px;
  overflow: hidden;
  padding-top: 5px;
  text-align: left;
}

.cart-table tbody tr .prod-column .column-box .prod-title {
  color: #333;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
  position: absolute;
  height: 70px;
  max-width: 70px;
  left: 0px;
  top: 0px;
  padding: 0px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
  display: block;
  max-width: 70px;
}

.cart-table tbody tr .prod-column .column-box h4 {
  font-size: 15px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #222;
  text-transform: capitalize;
}

.cart-table tbody tr .sub-total {
  font-weight: 400;
  color: #8dc63f;
}

.done-btn {
  display: inline-block;
  margin: auto;
}

.remove-btn {
  display: inline-block;
  margin: auto;
}

.edit-btn {
  display: inline-block;
  margin: auto;
}

.remove-btn .fa {
  display: inline-block;
  font-size: 24px;
  line-height: 30px;
  transition: 0.5s ease;
  color: red;
}

.edit-btn .fa {
  display: inline-block;
  font-size: 24px;
  line-height: 30px;
  transition: 0.5s ease;
  color: green;
}

.done-btn .fa {
  display: inline-block;
  padding: 5px 5px;
  font-size: 24px;
  line-height: 30px;
  transition: 0.5s ease;
  color: green;
}

.done-btn .fa:hover {
  color: darkgreen;
  transition: 0.5s ease;
}

.edit-btn .fa:hover {
  color: darkgreen;
  transition: 0.5s ease;
}

.remove-btn .fa:hover {
  color: darkred;
  transition: 0.5s ease;
}

.cart-table tbody tr .remove-btn span:before {
  font-size: 30px;
}

.cart-table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.cart-table tbody tr td {
  vertical-align: middle;
}

.cart-table tbody tr td .quantity-spinner {
  line-height: 24px;
  height: 46px;
  text-align: center;
  display: block;
  box-shadow: none;
  width: 100%;
  font-size: 20px;
  position: relative;
  color: #222;
}

.cart-table tbody .available-info {
  position: relative;
  padding-left: 50px;
  color: #9e9e9e;
  font-size: 14px;
}

.cart-table tbody .available-info .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background: #8dc63f;
  border-radius: 50%;
}

.cart-section
  .cart-table
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-up,
.cart-section
  .cart-table
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  background: #f7f7f7;
  border-color: #f7f7f7;
  padding: 12px 12px;
  text-align: center;
}

.cart-section .cart-table .bootstrap-touchspin .input-group-btn-vertical i {
  color: #999;
  left: 6px;
  top: 7px;
  font-size: 10px;
}

.cart-section
  .cart-table
  .bootstrap-touchspin
  .input-group-btn-vertical
  i:hover {
  color: #8dc63f;
}

.cart-table tbody tr .qty .quantity-spinner {
  background: #fff;
  border: none;
  border-radius: 40px;
}

.cart-section .apply-coupon {
  position: relative;
}

.cart-section .apply-coupon .form-group {
  position: relative;
  float: left;
  margin-right: 20px;
}

.cart-section .apply-coupon .form-group input[type="text"] {
  display: block;
  line-height: 24px;
  padding: 10px 15px;
  border-radius: 3px;
  width: 230px;
  background: none;
  border: 1px solid #fff;
  font-weight: 500;
  outline: none;
  height: 50px;
}

.cart-section .estimate-form {
  position: relative;
}

.cart-section h3 {
  font-size: 24px;
  margin-bottom: 30px;
}

.cart-section .estimate-form .row {
  margin-left: -7px;
  margin-right: -7px;
}

.cart-section .estimate-form .row .form-group {
  padding: 0px 7px;
  margin-bottom: 25px;
}

.cart-section .totals-table {
  position: relative;
  border: 2px solid #f4f4f4;
}

.cart-section .totals-table .col {
  position: relative;
  display: block;
  font-size: 17px;
  float: left;
  padding: 14.5px 15px;
  line-height: 24px;
  width: 50%;
}

.cart-section .totals-table .col-title {
  font-size: 16px;
  color: #222;
  border-right: 1px solid #f4f4f4;
  font-weight: 400;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.cart-section .totals-table li {
  position: relative;
  border-bottom: 1px solid #f4f4f4;
}

.cart-section .totals-table li:last-child {
  border-bottom: none;
}

.cart-section .update-cart-box {
  padding-top: 40px;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-toggle
  .caret {
  border: none;
  top: 2px;
  right: 20px;
}

.cart-section .estimate-form .select-box .btn-group.bootstrap-select {
  width: 100%;
  outline: none;
}

.cart-section .estimate-form .select-box .btn-group.bootstrap-select .btn {
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  outline: none;
  border-radius: 0;
}

.cart-section .estimate-form .select-box .dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.cart-section .estimate-form .select-box .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #8dc63f;
  transition: all 0.3s ease;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a:hover
  span.text {
  color: #8dc63f;
  transition: all 0.3s ease;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a
  span.text {
  font-size: 13px;
  color: #8dc63f;
}

.cart-section .estimate-form h3 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 40px;
  margin-top: 80px;
}

.cart-section .cart-total h3 {
  font-size: 24px;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 80px;
}

.cart-section .cart-total .thm-btn {
  margin-top: 30px;
}

.cart-section .estimate-form input {
  width: 100%;
  border: 2px solid yellow;
  outline: none;
  height: 50px;
  padding-left: 15px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.cart-section .qty .input-group {
  width: 74px;
  background: #fff;
  border-radius: 67px;
  height: 50px;
  overflow: hidden;
  border: 2px solid #f4f4f4;
}

.cart-section .thm-btn.update-cart {
  border: 2px solid black;
  background-color: transparent;
}

.cart-table tbody tr td,
.cart-table tbody tr .remove-btn {
  color: #9e9e9e;
  font-weight: normal;
}

.cart-section .thm-btn.update-cart {
  color: #222;
  border-color: #f7f7f7;
  text-shadow: none;
}

.cart-section .apply-coupon .form-group input[type="text"] {
  border: 2px solid #f7f7f7;
  text-transform: uppercase;
  font-size: 14px;
}

.cart-section .estimate-form input {
  border-color: #f4f4f4;
}

.cart-section .estimate-form .select-box .btn-group.bootstrap-select .btn {
  background: #fff;
  color: #9f9f9f;
  border-color: #f7f7f7;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-toggle
  .caret:before {
  color: #9f9f9f;
}

.cart-table tbody tr .prod-column .column-box {
  margin-left: 15px;
}

.cart-section .thm-btn.update-cart:hover {
  background: #8dc63f;
  color: #fff;
}

.cart-table tbody tr td {
  padding: 10px 10px 10px;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a
  span.text {
  color: #999;
}

.cart-section
  .estimate-form
  .select-box
  .bootstrap-select.btn-group
  .dropdown-menu
  li
  a:hover
  span.text {
  color: #fff;
}

/*** 
=============================================
    Checkout Area style
=============================================
***/

.checkout-area {
  padding-bottom: 100px;
  padding-top: 80px;
}

.checkout-area .exisitng-customer {
  border: 1px solid #f7f7f7;
  display: block;
  padding: 20px 28px 21px;
  position: relative;
  margin-bottom: 20px;
}

.checkout-area .exisitng-customer:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #8dc63f;
  content: "";
}

.checkout-area .exisitng-customer h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 300;
}

.checkout-area .exisitng-customer h5 a {
  color: #8dc63f;
  display: inline-block;
  padding-left: 15px;
}

.checkout-area .coupon {
  border: 1px solid #f7f7f7;
  display: block;
  padding: 20px 28px 21px;
  position: relative;
  margin-bottom: 60px;
}

.checkout-area .coupon:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #8dc63f;
  content: "";
}

.checkout-area .coupon h5 {
  color: #252525;
  font-size: 16px;
  font-weight: 300;
}

.checkout-area .coupon h5 a {
  color: #8dc63f;
  display: inline-block;
  padding-left: 15px;
}

.checkout-area .form form .field-label {
  color: #222;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 15px;
}

.checkout-area .form form .field-input input[type="text"] {
  border: 2px solid #f7f7f7;
  color: #9a9a9a;
  display: block;
  font-size: 16px;
  height: 48px;
  margin-bottom: 25px;
  padding: 0 15px;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.checkout-area .form form .field-input input[type="text"]:focus {
  border: 2px solid #8dc63f;
}

.checkout-area .create-acc .checkbox {
  margin: 7px 0 0;
}

.checkout-area .create-acc .checkbox label {
  color: #8dc63f;
  font-weight: 300;
  font-size: 16px;
}

.checkout-area .create-acc .checkbox input {
  margin-top: 3px;
}

.checkout-area .shipping-info input[type="checkbox"] {
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0 40px;
  position: relative;
  top: -2px;
  vertical-align: middle;
}

.checkout-area .form form .field-input textarea {
  border: 2px solid #f7f7f7;
  color: #9e9e9e;
  display: block;
  font-size: 16px;
  height: 124px;
  margin-bottom: 28px;
  padding: 10px 15px;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.checkout-area .form form .field-input textarea:focus {
  border-color: #8dc63f;
}

.checkout-area .bottom {
  padding-top: 70px;
}

.checkout-area .table .sec-title-two {
  padding-bottom: 32px;
}

.checkout-area .table {
  overflow-x: auto;
  position: relative;
  width: 100%;
  margin-bottom: 0;
}

.checkout-area .table .cart-table {
  min-width: auto;
  width: 100%;
}

.checkout-area .table .cart-table .cart-header {
  background: #f7f7f7;
  color: #303030;
  font-size: 18px;
  position: relative;
  text-transform: capitalize;
  width: 100%;
  font-weight: 400;
}

.checkout-area .table .cart-table thead tr th {
  font-weight: 400;
  line-height: 24px;
  min-width: 110px;
  padding: 19px 30px 17px;
}

.checkout-area .table .cart-table thead tr th.product-column {
  padding-left: 30px;
  text-align: left;
}

.checkout-area .table .cart-table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.checkout-area .table .cart-table tbody tr td {
  vertical-align: middle;
}

.checkout-area .table .cart-table tbody tr td {
  min-width: 110px;
  padding: 21px 30px;
  padding-right: 0px;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box {
  min-height: 70px;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .prod-thumb {
  width: 70px;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .prod-thumb
  img {
  display: block;
  max-width: 100%;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .prod-thumb,
.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .product-title {
  display: table-cell;
  vertical-align: middle;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .product-title {
  padding-left: 20px;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .product-column
  .column-box
  .product-title
  h3 {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.checkout-area .table .cart-table tbody tr .qty {
  padding-right: 30px;
  width: 130px;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .qty
  .input-group.bootstrap-touchspin {
  float: right;
  width: 70px;
}

.checkout-area .table .cart-table tbody tr .qty .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0 !important;
  box-shadow: none;
  color: #252525;
  display: block;
  font-size: 18px;
  font-weight: 400;
  height: 34px;
  padding: 6px 0;
  text-align: center;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .qty
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-up {
  border-radius: 0;
}

.checkout-area .table .cart-table tbody tr .qty .btn-default {
  background-color: #8dc63f;
  border-color: #8dc63f;
  color: #fff;
}

.checkout-area
  .table
  .cart-table
  tbody
  tr
  .qty
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.checkout-area .table .cart-table tbody tr td.price {
  color: #8dc63f;
  font-size: 16px;
  font-weight: 300;
}

.cart-total .sec-title-two {
  padding-bottom: 32px;
}

.cart-total-table {
  border: 2px solid #ebebeb;
}

.cart-total-table li {
  border-bottom: 1px solid #ebebeb;
}

.cart-total-table li:last-child {
  border-bottom: none;
}

.cart-total-table li span.col.col-title {
  border-right: 1px solid #ebebeb;
  color: #303030;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  padding: 10px 18px;
  position: relative;
  width: 50%;
}

.cart-total-table li span.col {
  color: #9a9a9a;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  padding: 10px 18px;
  position: relative;
  width: 50%;
}

.cart-total-table li span.col b {
  color: #8dc63f;
  font-weight: 300;
  text-decoration: underline;
}

.cart-total .payment-options {
  background: #f7f7f7 none repeat scroll 0 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 23px 20px 35px;
}

.cart-total .payment-options .option-block {
  margin-bottom: 14px;
}

.cart-total .payment-options .option-block .checkbox {
  margin: 0 0 5px;
  font-size: 16px;
}

.cart-total .payment-options .option-block .checkbox label {
  font-weight: 400;
  min-height: 20px;
  padding-left: 20px;
}

.cart-total .payment-options .option-block .checkbox label input {
  top: 0;
}

.cart-total .payment-options .option-block .checkbox label span {
  color: #303030;
  padding-left: 5px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 16px;
}

.cart-total .payment-options .option-block .checkbox label span b {
  color: #8dc63f;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  padding-left: 25px;
  text-decoration: underline;
}

.cart-total .payment-options .option-block .text {
  padding-left: 30px;
}

.cart-total .payment-options .option-block .text p {
  margin: 0;
  line-height: 28px;
}

.placeorder-button button {
}

.placeorder-button button:hover {
}

/*** 
=============================================
     shop style
=============================================
***/

.shop {
  position: relative;
}

.shop .hover-effect {
  margin-bottom: 15px;
  padding-top: 10px;
  transition: 0.5s ease;
}

.shop .hover-effect:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s ease-in-out;
}

/* .single-shop-item .img-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 175px;
  transition: .5s ease;
  background: #f7f7f7;
  text-align: center;
} */

.float-testimonial-btn {
  position: fixed;
  right: -3px;
  top: 130px;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  z-index: 3;
}

.action-img-correction {
  width: 70% !important;
  height: 140px;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
}
.action-background-card {
  /* height: 360px; */
  min-height: 202px;
  width: 70%;
}

.new-action-title-container {
  background: rgb(249 249 249);
  /* margin-top: 5px; */
  margin-top: 12px;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.new-action-btns-div {
  position: absolute;
  height: 200px;
  right: 0px;
  top: 0px;
  width: 29%;
  padding: 10px;
  height: 80%;
  border-radius: 10px;
  z-index: 1;
  margin-top: 3px;
}

.single-shop-item .img-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* height: 295px; */
  height: 160px;
  transition: 0.5s ease;
  background: white;
  text-align: center;
  border-radius: 11px;
}

.single-shop-item:hover .img-box {
  transition: 0.5s ease;
}

.single-shop-item .img-box .offer-box {
  position: absolute;
  top: 10px;
  left: 10px;
}

.single-shop-item .img-box .offer-box .inner {
  position: relative;
}

.single-shop-item .img-box .offer-box .inner:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  background-color: transparent;
  border-bottom: 35px solid transparent;
  border-bottom: 35px solid transparent;
  border-top: 35px solid #ab7442;
  border-left: 35px solid #ab7442;
  border-right: 35px solid transparent;
}

.single-shop-item .img-box .offer-box .inner span {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transform: rotate(-45deg);
  line-height: 35px;
  transform-origin: 70% 35%;
}

.single-shop-item .text-box h3,
.single-shop-item .text-box p {
  margin: 0;
}

.single-shop-item .text-box h3 {
  font-size: 18px;
  line-height: 50px;
  color: #1f1f1f;
  margin-top: 10px;
}

.single-shop-item .text-box p {
  font-size: 20px;
  line-height: 24px;
}

.single-shop-item .text-box p i {
  font-size: 18px;
}

.single-shop-item .text-box p span {
  display: inline-block;
  vertical-align: top;
  color: #ab7442;
  font-size: 18px;
  font-weight: 400;
}

.single-shop-item .text-box p del {
  color: #9f9f9f;
  font-size: 14px;
  line-height: 24px;
  vertical-align: super;
  display: inline-block;
  font-weight: normal;
}

.single-shop-item .text-box p del i {
  font-size: 14px;
}

.single-shop-item {
  margin-bottom: 40px;
}

.single-shop-item .content-box a.cart-btn {
  position: relative;
  color: #848484;
  font-size: 12px;
  transition: 0.7s ease;
}

.single-shop-item .content-box a.cart-btn:hover {
  color: #8dc63f;
  transition: 0.7s ease;
}

.single-shop-item .content-box a.cart-btn:before {
  content: "";
  position: absolute;
  left: 0px;
  background: transparent;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transition: 0.5s ease-in-out;
}

.single-shop-item .content-box a.cart-btn:hover:before {
  background-color: #8dc63f;
}

.single-shop-item .content-box a.cart-btn i {
  margin-right: 6px;
}

.single-shop-item .content-box {
  position: relative;
  border: 1px solid #f4f4f4;
  border-top: 0px;
}

.single-shop-item .content-box .inner-box {
  border-bottom: 1px dashed #eee;
  /* height: 7em; */
  padding: 14px 20px;
}

.single-shop-item .content-box .price-box {
  padding: 15px 20px 15px;
  border-bottom: 1px dashed #eee;
}

.single-shop-item .content-box .price-box2 {
  padding: 5px 30px 0px;
  border-bottom: 1px dashed #eee;
}

.single-shop-item .content-box .price-box3 {
  padding: 5px 10px 5px;
  border-bottom: 1px dashed #eee;
}

.action-tags {
  line-height: 2em;
  margin: auto;
}

.action-tags span {
  background: #eee;
  border-radius: 10px;
  color: #333;
  padding: 0px 5px;
  margin: 0px 5px;
}

.single-shop-item .content-box h4 {
  font-size: 1.3rem;
  margin: 0px 0px;
  line-height: 1.4em;
  color: #222;
  margin-bottom: 6px;
}

.single-shop-item .content-box a,
.single-shop-item .content-boxa:hover {
}

.single-shop-item .content-box h4 a {
  color: #252525;
}

.single-shop-item .content-box .rating {
  font-size: 13px;
  color: #8dc63f;
  right: 5px;
  top: 3px;
  position: relative;
}

.single-shop-item .content-box .item-price {
  font-size: 16px;
  font-weight: 400;
  color: #8dc63f;
  line-height: 24px;
}

.single-shop-item .content-box .item-price del {
  color: #a2a2a2;
  margin-left: 7px;
  font-size: 15px;
}

.single-shop-item .content-box .item-price .strike-through {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
  line-height: 20px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

/*============================= Sidebar Style One ==================*/

.sidebar_styleOne .theme_title,
.sidebar_styleTwo .theme_title {
  padding-bottom: 18px;
}

.sidebar_search {
  height: 54px;
  position: relative;
}

.sidebar_search input {
  width: 100%;
  height: 54px;
  border: none;
  border: 1px solid #f1f1f1;
  padding: 0 56px 0 12px;
  color: #9e9e9e;
}

.sidebar_search button {
  width: 54px;
  height: 100%;
  position: absolute;
  top: 0;
  background: #8dc63f;
  right: 0;
  color: #fff;
}

.sidebar_categories {
  margin-top: 60px;
}

.sidebar_categories ul {
  margin-top: 22px;
  margin-bottom: 35px;
}

.sidebar_categories ul li {
  line-height: 48px;
  border-bottom: 1px solid #f1f1f1;
}

.sidebar_categories ul li:last-child {
  border: none;
}

.sidebar_categories ul li a {
  font-weight: normal;
  color: #9e9e9e;
  position: relative;
}

.sidebar_categories ul li a:hover {
}

.sidebar_categories ul li input[type="checkbox"] {
  float: right;
  position: relative;
  top: 14px;
}

.sidebar_categories ul li a:before {
  content: "\f114";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin-right: 10px;
}

.sidebar_categories.archive ul li a:before {
  content: "\f00c";
}

.sidebar_categories ul li a:hover {
  color: #8dc63f;
}

.recent_news_sidebar .theme_title {
  margin-bottom: 40px;
}

.recent_news_sidebar {
  margin-bottom: 62px;
}

.recent_news_sidebar .single_news_sidebar {
  margin-bottom: 29px;
}

.recent_news_sidebar .img_holder img {
  width: 75px;
  height: 75px;
  border-radius: 5px;
}

.recent_news_sidebar .post {
  padding-left: 26px;
  width: 72%;
  margin-top: -10px;
}

.recent_news_sidebar .post h6 a {
  font-size: 16px;
  color: #272727;
  line-height: 28px;
  font-weight: 900;
  margin-bottom: 2px;
}

.recent_news_sidebar .post p a {
  font-size: 14px;
}

.recent_news_sidebar .post p a i {
  margin-right: 4px;
  font-size: 16px;
}

.recent_news_sidebar .img_holder {
  position: relative;
}

.recent_news_sidebar .img_holder .opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
}

.recent_news_sidebar .img_holder .opacity .icon {
  display: table;
  width: 100%;
  height: 100%;
}

.recent_news_sidebar .img_holder .opacity .icon span {
  display: table-cell;
  vertical-align: middle;
}

.recent_news_sidebar .img_holder .opacity .icon span a {
  display: block;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  line-height: 45px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.recent_news_sidebar .single_news_sidebar:hover .opacity {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.sidebar_tags {
  margin: 60px 0 -6px 0;
}

.sidebar_tags ul {
}

.sidebar_tags ul li {
  display: inline-block;
  margin: 0;
}

.sidebar_tags ul li a {
  display: block;
  font-weight: normal;
  font-size: 15px;
  color: #9e9e9e;
  line-height: 32px;
  background: #fff;
  border-radius: 2px;
  text-align: center;
  padding-right: 20px;
}

.sidebar_tags ul li a:hover {
  color: #8dc63f;
}

.single-sidebar.price-ranger .ui-widget-content {
  background: #f7f7f7;
  border: none;
  height: 2px;
}

.single-sidebar.price-ranger .ui-slider-handle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #222;
  margin-left: -4px;
  outline: none;
  background: #8dc63f;
}

.single-sidebar.price-ranger .ui-slider .ui-slider-range {
  background: #252525;
}

.single-sidebar.price-ranger .ui-slider-horizontal .ui-slider-handle {
  top: -5px;
}

.single-sidebar.price-ranger #slider-range {
  margin-top: 25px;
}

.single-sidebar.price-ranger .ranger-min-max-block {
  margin-top: 20px;
  margin-bottom: 45px;
}

.single-sidebar.price-ranger .ranger-min-max-block input {
  display: inline-block;
}

.single-sidebar.price-ranger .ranger-min-max-block input[type="submit"] {
  width: 80px;
  border: none;
  border-radius: 0px;
  text-align: center;
  outline: none;
  line-height: 35px;
  font-size: 14px;
  margin-right: 37px;
  color: #ffffff;
  font-weight: 700;
  background: #8dc63f;
  text-transform: uppercase;
  border: 2px solid #8dc63f;
}

.single-sidebar.price-ranger .ranger-min-max-block input[type="text"] {
  border: none;
  text-align: center;
  outline: none;
  width: 40px;
  color: #9c9c9c;
  font-weight: 400;
  text-align: right;
}

.single-sidebar.price-ranger .ranger-min-max-block input[type="text"].max {
}

.single-sidebar.price-ranger .ranger-min-max-block span {
  color: #9c9c9c;
  font-weight: 400;
}

.best_sellers .theme_title {
  margin-bottom: 36px;
}

.best_sellers .best_selling_item .text {
  padding-left: 20px;
  margin-top: -3px;
}

.best_sellers .best_selling_item .text h4 {
  color: #222;
}

.best_sellers .best_selling_item .text h6 {
  font-size: 16px;
  font-weight: 700;
  color: #272727;
}

.best_sellers .best_selling_item .text ul li {
  display: inline-block;
  margin-right: 3px;
  font-size: 12px;
  margin-top: 10px;
  color: #8dc63f;
}

.best_sellers .best_selling_item .text span {
  display: block;
  margin-top: 10px;
  color: #8dc63f;
  font-size: 16px;
  font-weight: 400;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.best_sellers .best_selling_item {
  margin: 20px 0 20px 0;
  padding-bottom: 17px;
}

.best_sellers .best_selling_item.border {
  border-bottom: 1px solid #f7f7f7;
}

.sidebar_styleTwo .sidebar_categories {
  margin-top: 30px;
}

.sidebar_styleTwo .sidebar_tags {
  margin: 19px 0 51px 0;
}

/*** 
=============================================
   Shop Single area  style
=============================================
***/

.shop-single-area {
  padding-bottom: 80px;
  padding-top: 80px;
}

.product-content-box {
  margin-bottom: 106px;
}

.product-content-box .img-box {
  text-align: center;
  margin: auto;
  width: 90%;
}

.product-content-box .flexslider .slides img {
  width: auto;
  display: inline-block;
}

.product-content-box .flexslider {
  background: #f7f7f7 none repeat scroll 0 0;
  border: none;
  padding: 32px 0;
  border-radius: 0;
  margin: 0 50px 10px 0;
  position: relative;
  text-align: center;
}

.product-content-box .flex-viewport {
  border: none;
  max-height: 450px;
  transition: all 1s ease 0s;
}

.product-content-box .flex-control-thumbs {
  margin: 10px 0 0;
  overflow: hidden;
  position: static;
  display: none;
}

.product-content-box .flex-control-thumbs li {
  float: left;
  margin: 0 3% 0 0;
  width: 25%;
}

.product-content-box .flex-control-thumbs img {
  border: medium none;
  cursor: pointer;
  display: block;
  height: auto;
  opacity: 0.6;
  transition: all 1s ease 0s;
  width: 100%;
  border: 3px solid #f7f7f7;
}

.product-content-box .flex-control-thumbs .flex-active {
  cursor: default;
  opacity: 1;
  border: 3px solid #ab7442;
}

.product-content-box .flex-control-thumbs .flex-active:focus {
  outline: none;
}

.product-content-box .content-box {
  margin-left: 20px;
  margin-right: 20px;
}

.product-content-box .content-box h3 {
  color: #222;
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 10px;
  text-transform: capitalize;
}

.product-content-box .content-box del {
  color: #a2a2a2;
  margin-left: 10px;
}

.product-content-box .content-box .review-box {
  margin: 0 0 10px;
}

.product-content-box .content-box .review-box ul li {
  display: inline-block;
  margin-right: 3px;
}

.product-content-box .content-box .review-box ul {
  display: inline-block;
  padding-right: 1px;
}

.product-content-box .content-box .review-box ul li i {
  color: #8dc63f;
  font-size: 14px;
}

.product-content-box .content-box span.price {
  color: #8dc63f;
  font-size: 22px;
}

.product-content-box .content-box .text {
  margin: 0px 0 0;
  overflow: hidden;
  padding: 24px 0 9px;
}

.product-content-box .content-box .location-box {
  margin: 4px 0 18px;
  position: relative;
}

.product-content-box .content-box .location-box p {
  color: #1f1f1f;
  margin: 0 0 12px;
}

.product-content-box .content-box .location-box form input {
  border: 2px solid #f7f7f7;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 170px;
  transition: all 500ms ease;
}

.product-content-box .content-box .location-box form input:focus {
  border-color: #8dc63f;
}

.product-content-box .content-box .location-box form button {
  background: #f7f7f7 none repeat scroll 0 0;
  border: medium none;
  color: #292929;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 178px;
  top: 37px;
  height: 40px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 95px;
  margin-left: 6px;
  transition: all 500ms ease;
}

.product-content-box .content-box .location-box form button:hover {
  background: #8dc63f;
  color: #fff;
}

.product-content-box .content-box .location-box form span {
  color: #8dc63f;
}

.product-content-box .content-box .addto-cart-box {
}

.product-content-box
  .content-box
  .addto-cart-box
  .input-group.bootstrap-touchspin {
  float: left;
  width: 70px;
  margin-right: 20px;
}

.product-content-box .content-box .addto-cart-box .form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid #eaeaea;
  border-radius: 0 !important;
  box-shadow: none;
  color: #272727;
  display: block;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  padding: 6px 0 8px;
  text-align: center;
}

.product-content-box
  .content-box
  .addto-cart-box
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-up {
  border-radius: 0;
}

.product-content-box
  .content-box
  .addto-cart-box
  .bootstrap-touchspin
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.product-content-box
  .content-box
  .addto-cart-box
  .bootstrap-touchspin
  .input-group-btn-vertical
  > .btn {
  display: block;
  float: none;
  margin-left: -1px;
  max-width: 100%;
  padding: 12px 12px;
  position: relative;
  width: 100%;
}

.product-content-box .content-box .addto-cart-box .btn-default {
  background-color: #efefef;
  border-color: #efefef;
  color: #9e9e9e;
}

.product-content-box
  .content-box
  .addto-cart-box
  .bootstrap-touchspin
  .input-group-btn-vertical
  i {
  color: #9e9e9e;
  font-weight: normal;
  left: 8px;
  position: absolute;
  top: 7px;
}

.product-content-box .content-box .addto-cart-box button.addtocart {
  margin-left: 25px;
}

.product-tab-box {
  padding: 30px;
  padding-top: 0;
  border: 1px solid #f4f4f4;
}

.product-tab-box .tab-menu {
  border-bottom: 1px solid #f7f7f7;
  margin-top: -50px;
  margin-bottom: 33px;
  position: relative;
  width: 500px;
  left: -31px;
}

.product-tab-box .tab-menu li {
  display: block;
  float: left;
  margin-bottom: -1px;
}

.product-tab-box .tab-menu li button {
  border: 1px solid transparent;
  border-radius: 0;
  display: block;
  margin-right: 10px;
  padding: 13px 5px;
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 600;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  background: #f7f7f7;
  transition: all 500ms ease;
}

.product-tab-box .tab-menu li.active button,
.product-tab-box .tab-menu li:hover button {
  background: #8dc63f;
  color: #fff;
  border-color: #8dc63f;
}

.product-tab-box .tab-content {
}

.single-review-box {
  margin-bottom: 10px;
  padding-bottom: 32px;
  padding-left: 80px;
  position: relative;
}

.single-review-box .img-holder {
  left: 0;
  position: absolute;
  top: 0;
}

.single-review-box .text-holder {
  border: 1px solid #f4f4f4;
  padding: 20px;
}

.single-review-box .text-holder .top {
  margin: 0 0 8px;
  overflow: hidden;
}

.single-review-box .text-holder .top .review-box ul {
  margin-top: -4px;
  padding-left: 20px;
}

.single-review-box .text-holder .top .review-box ul li {
  display: inline-block;
  margin-right: 3px;
}

.single-review-box .text-holder .top .review-box ul li i {
  color: #8dc63f;
  font-size: 14px;
}

.single-review-box .text-holder .top .review-date {
  color: #ab7442;
  margin-top: -5px;
}

.single-review-box .text-holder .text p {
  margin: 0 0 15px;
}

.single-review-box .text-holder .text a.reply {
  color: #ab7442;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.product-tab-box .tab-content .product-details-content {
  margin-top: -5px;
}

.product-tab-box .tab-content .product-details-content .highlight {
  margin: 42px 0 0;
}

.product-tab-box
  .tab-content
  .product-details-content
  .highlight
  .highlight-title {
  color: #292929;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  margin: 0 0 28px;
}

.product-tab-box .tab-content .product-details-content .highlight li {
  position: relative;
  padding-bottom: 12px;
  padding-left: 25px;
}

.product-tab-box .tab-content .product-details-content .highlight li:before {
  content: "\f00c";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #ab7442;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0;
}

.review-form {
  padding-top: 16px;
}

.review-form .sec-title-two {
  padding-bottom: 34px;
}

.review-form .add-rating-box {
  margin: 0 0 23px;
}

.review-form .add-rating-box .add-rating-title h4 {
  color: #292929;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 10px;
  text-transform: none;
}

.review-form .add-rating-box ul li {
  display: inline-block;
  margin: 0 0px;
  border-right: 1px solid #e5e5e5;
  padding: 0 11px;
}

.review-form .add-rating-box ul li:last-child {
  margin-right: 0;
}

.review-form .add-rating-box ul li a i {
  color: #ddd;
  margin-right: 3px;
  transition: all 500ms ease;
  font-size: 14px;
}

.review-form .add-rating-box ul li.active a i,
.review-form .add-rating-box ul li a:hover i {
  color: #8dc63f;
}

.review-form form input[type="text"],
.product-tab-box .tab-content .review-form form textarea {
  height: 50px;
  width: 100%;
  border: 1px solid #eaeaea;
  color: #9e9e9e;
  font-size: 14px;
  padding: 0 15px;
  margin-bottom: 30px;
  transition: all 500ms ease;
}

.review-form form textarea {
  height: 110px;
  padding: 10px 15px;
  margin-bottom: 32px;
}

.review-form .add-rating-box ul li:hover a i {
  opacity: 1;
}

.review-form form input[type="text"]:focus {
  border-color: #8dc63f;
}

.review-form form textarea:focus {
  border-color: #8dc63f;
}

.related-product {
  border-top: 1px solid #f7f7f7;
  margin-top: 60px;
  padding-top: 56px;
}

.related-product .single-product-item {
  margin-bottom: 0;
}

.product-review-tab .add_your_review .review-title h3 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  color: #252525;
}

.tab-title-h4 h4 {
  color: #3d3d3d;
  font-size: 20px;
  margin-bottom: 40px;
}

/*** 

====================================================================
  Login / Register Section
====================================================================

***/

.register-section {
  position: relative;
}

.register-section .sec-title {
  margin-bottom: 30px;
}

.register-section .sec-title h2 {
  font-size: 28px;
  padding-bottom: 5px;
  font-weight: 600;
}

.register-section .form-column {
  margin-bottom: 70px;
}

.styled-form {
  position: relative;
}

.styled-form .form-group {
  position: relative;
  margin-bottom: 25px;
  margin-top: 0px;
  font-size: 15px;
  color: #9e9e9e;
}

.styled-form .form-group.social-links-two {
  margin-top: 7px;
}

.styled-form .form-group.register-text {
  margin-top: 15px;
  margin-left: 10px;
}

.checkout-section .styled-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.styled-form .form-group .check-box {
  line-height: 24px;
  font-size: 15px;
  font-weight: normal;
  padding-top: 5px;
  color: #1f1f1f;
}

.styled-form .form-group .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0px;
  font-size: 15px;
  cursor: pointer;
}

.styled-form .pull-left label,
.styled-form .pull-right label {
  cursor: pointer;
  font-weight: 400;
}

.styled-form .pull-left input {
  display: inline-block;
}

.styled-form .form-group .thm-btn {
  line-height: 44px;
}

.styled-form .form-group .thm-btn:disabled {
  background: #aaa;
  border: #aaa;
  line-height: 44px;
}

.styled-form .form-group .adon-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  color: #999999;
  z-index: 2;
}

.styled-form .form-group .field-label {
  display: block;
  color: #1f1f1f;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 400;
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  line-height: 30px;
  padding: 8px 20px;
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select {
  line-height: 26px;
  height: 44px;
}

.styled-form select {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background: #ffffff url(../images/icons/icon-select.png) right center
    no-repeat;
  cursor: pointer;
}

.styled-form select option {
  text-indent: 20px;
}

.styled-form textarea {
  resize: none;
  height: 110px;
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus {
  border-color: #8dc63f;
}

.styled-form .form-group .theme-btn {
  min-width: 150px;
  text-align: center;
  padding: 10px 30px;
}

.styled-form .forgot {
  font-size: 14px;
}

.social-links-two button {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 42px;
  height: 42px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  background: #b2dd4c;
  margin: 0px 0px 0px 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.social-links-three button {
  position: relative;
  display: inline-block;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  font-size: 14px;
  height: 42px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  background: #b2dd4c;
  margin: 0px 10px;
  padding: 0px 10px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.social-links-two button:hover {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

.social-links-two .facebook {
  background: #3b5998;
}

.social-links-two .twitter {
  background: #33ccfe;
}

.social-links-two .google {
  background: #dd4b39;
}

.social-links-three .facebook {
  background: #3b5998;
}

.social-links-three .twitter {
  background: #33ccfe;
}

.social-links-three .google {
  background: #dd4b39;
}

.shop-sidebar .section-title {
  margin-bottom: 0px;
}

.shop-sidebar .category-style-one {
  position: relative;
}

.shop-sidebar .category-style-one form {
  margin-bottom: 0px;
  margin-top: 0px;
}

.shop-sidebar .category-style-one form label {
  border-bottom: 1px solid #eaeaea;
  margin: 0;
  padding-bottom: 0px;
}

.shop-sidebar .category-style-one form label p {
  font-weight: normal;
  color: #848484;
  position: relative;
  vertical-align: center;
  width: 100%;
  transition: 0.5s ease;
  line-height: 20px;
  display: block;
}

.shop-sidebar .category-style-one form label p:hover {
  color: #8dc63f;
  transition: 0.5s ease;
}

.single-shop-item .overlay {
  background-color: rgba(0, 0, 0, 0.49);
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 450ms ease-out 0s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.single-shop-item:hover .img-box .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  transform: rotateY(0deg) scale(1, 1);
}

.single-shop-item .overlay .box {
  display: table;
  height: 100%;
  width: 100%;
}

.single-shop-item .overlay .box .content {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.single-shop-item .img-box .overlay .box .content a i {
  background: #8dc63f;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  height: 45px;
  line-height: 48px;
  transition: all 500ms ease 0s;
  width: 45px;
}

.single-shop-item .img-box .overlay .box .content a:hover i {
  background: #8dc63f;
}

.best_sellers .best_selling_item .img_holder {
  position: relative;
}

.best_sellers .best_selling_item .img_holder a:after {
  position: absolute;
  content: "\f0c1";
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
  left: 0px;
  top: 0px;
  text-align: center;
  line-height: 80px;
  width: 100%;
  height: 100%;
  background: rgba(141, 198, 63, 0.9);
  color: #ffffff;
  opacity: 0;
  transition: 0.5s ease;
}

.best_sellers .best_selling_item .img_holder:hover a:after {
  opacity: 1;
  transition: 0.5s ease;
}

/*** 
=============================================
    Not found area style
=============================================
***/

.not-found-area {
  background: #ffffff none repeat scroll 0 0;
  padding-bottom: 100px;
  padding-top: 72px;
}

.not-found-content h1 {
  font-size: 150px;
  margin: 0 0 7px;
}

.not-found-content h3 {
  color: #222;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 20px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.not-found-content p {
  font-size: 18px;
  margin: 0 0 42px;
}

.not-found-content .search-form {
  max-width: 432px;
  position: relative;
}

.not-found-content .search-form input[type="text"] {
  background: #fff;
  border: 1px solid #f1f1f1;
  color: #9a9a9a;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  height: 55px;
  letter-spacing: 1px;
  padding-left: 20px;
  padding-right: 60px;
  position: relative;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 432px;
}

.not-found-content .search-form button {
  background: #8dc63f none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  height: 55px;
  padding: 11px 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  width: 55px;
}

.not-found-content .search-form button i {
  font-size: 14px;
}

.not-found-content .search-form input[type="text"]:focus {
  border: 1px solid #8dc63f;
  background: #fff;
  color: #000;
}

.not-found-content .search-form input[type="text"]:focus + button,
.not-found-content .search-form button:hover {
  background: #8dc63f none repeat scroll 0 0;
  color: #fff;
}

/*** 

====================================================================
    Testimonials Section style
====================================================================

***/

.testimonials-section2 {
  position: relative;
}

.testimonials-section2 .slide-item {
  border: 1px solid #f4f4f4;
  padding: 30px 20px 25px;
  transition: 0.5s ease;
  margin-bottom: 15px;
}

.testimonials-section2 .slide-item:hover {
  transition: 0.5s ease;
  box-shadow: 0px 9px 17px 5px rgba(0, 0, 0, 0.03);
}

article.slide-item.center {
}

.testimonials-section2 .slide-text span {
  color: #f4f4f4;
  font-size: 40px;
  transition: 0.5s ease;
}

.testimonials-section2 .slide-item:hover .slide-text span {
  color: #8dc63f;
  transition: 0.5s ease;
}

.testimonials-section2 .slide-text p {
  padding-top: 14px;
}

.testimonials-section2 .rating {
  position: relative;
  max-width: 220px;
  margin: 15px auto;
}

.testimonials-section2 .rating:before {
  position: absolute;
  content: "";
  right: 0;
  width: 62px;
  height: 1px;
  top: 10px;
  background: #eaeaea;
}

.testimonials-section2 .rating:after {
  position: absolute;
  content: "";
  left: 0;
  width: 62px;
  height: 1px;
  top: 10px;
  background: #eaeaea;
}

.testimonials-section2 .rating li {
  position: relative;
  color: #ababab;
  display: inline-block;
  font-size: 14px;
}

.testimonials-section2 .author h4 {
  font-size: 22px;
  margin-bottom: 5px;
}

.testimonials-section2 .author p {
  color: #8dc63f;
}

.testimonials-section2 .donate-us h2 {
  margin-top: 160px;
  margin-bottom: 30px;
}

/*** 

====================================================================
    Custom styles
====================================================================

***/

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.row.no-gutter [class*="col-"]:not(:first-child),
.row.no-gutter [class*="col-"]:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}

/* Tooltip text */

.remove-btn .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */

.remove-btn:hover .tooltiptext {
  visibility: visible;
}

.login-link {
  position: absolute;
  color: green;
  top: 20px;
  right: 40px;
  font-size: 20px;
  font-family: "Google Sans", "Oxygen", "Open Sans", sans-serif;
}

.login-link:hover {
  color: #8dc63f;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }

  100% {
    width: 90%;
  }
}

.tag-bar {
  display: inline-block;
  position: relative;
  margin: 2px;
  width: 20px;
  height: 10px;
}

.tag-bar.one {
  background: #8dc63f;
}

.tag-bar.two {
  background: #72a033;
}

.tag-bar.three {
  background: #6f9c31;
}

.col-centered {
  float: none;
  text-align: center;
}

.accordian-header {
  cursor: pointer;
  border-bottom: 1px solid #aaa;
  border-radius: 3px;
  padding: 10px 0;
}

.accordian-content.hidden {
  display: none;
}

.profile-table {
  margin: auto;
  border: solid 1px lightgray;
}

.profile-table th {
  padding: 15px;
  background: #eee;
}

.profile-table td {
  padding: 15px;
  border: solid 1px #aaa;
}

.profile-table td button {
  display: block;
  margin: 5px auto;
}

.apexcharts-canvas {
  margin: auto;
}

.thumbnail {
  width: 50px;
  height: 50px;
}

button.as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #007bff;
  cursor: pointer;
}

button.as-link:hover {
  color: #0056b3;
}

.householdForm .col p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.review-form p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.home-subtitle {
  background: #003000;
  color: white;
}

.me-sub-teams-box {
  padding-left: 10%;
  padding-top: 10px;
  border: dotted 0px #d5dcd7fa;
  border-left-width: 7px;
}
.teams-search {
  padding: 11px 11px 11px 30px;
  border: solid 2px #f3f3f3;
  border-radius: 55px;
  width: 100%;
  height: 100%;
}

.team-card {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0px 0px 0 rgba(0,0,0,55.16), 0 4px 8px 0 rgba(0,0,0,.2);
    -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,55.16), 0  4px 8px 0 rgba(0,0,0,.2); */
  padding: 20px;
  margin-bottom: 20px;
  height: auto;
  cursor: pointer;
  border-radius: 12px;
  display: block;
  align-items: center;
}

.team-card:hover {
  background: #f8fff4;
  transition: 0.2 forwards;
}

.team-card-title {
  font-size: 20px;
  line-height: 21px;
  margin: 0;
  word-wrap: break-word;
  hyphens: auto;
}

.team-card-description {
  font-size: 15px;
  line-height: 16px;
  margin: 5px 0;
  word-wrap: break-word;
  hyphens: auto;
}

.team-card-column {
  display: flex;
  align-items: center;
  width: 100%;
}

.team-card-content {
  width: 95%;
  display: block;
  margin: auto;
}
.team-name-container {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.info-section {
  border-radius: 4px;
}

.info-section.members {
  /* background: #f6986130; */
  background: #fafbf8;
}

.info-section.members p {
  font-size: 16px;
  line-height: 17px;
}

.info-section.data {
  /* background: #8dc63f30; */
  background: #f2ffe0;
}

.info-section.data p {
  font-size: 14px;
  line-height: 15px;
}

.info-section p {
  color: black;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
  margin: 4px 0;
}

.team-card-img {
  height: auto;
  max-height: 120px;
  display: block;
  margin: auto;
  object-fit: cover;
  border-radius: 10px;
}
.one-team-image-mobile {
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 5px;
  max-height: 30px;
}
.one-team-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 160px;
  margin: auto;
  display: block;
  object-fit: contain;
  border-radius: 6px;
}

.one-team-content-section {
  margin: 5px 0px;
  height: auto;
  width: 100%;
  border: 1px solid #f3f3f3;
  padding: 25px;
  border-radius: 12px;
}

.one-team-content-section h5 {
  text-align: center;
}

.one-team-content-section p {
  margin: 0px;
}

.one-team-content-section small {
  width: 100%;
  text-align: center;
  font-size: small;
  color: darkgrey;
  margin-bottom: 5px;
}

.team-ul ul {
  /* color: #999; */
  color: #282828;
  font-size: 17px;
  margin: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.team-modal {
  height: auto;
  max-height: 450px;
  width: auto;
  max-width: 600px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  z-index: 102;
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}

.team-modal textarea {
  resize: none;
}

.team-modal label {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.team-modal small {
  line-height: 1.1;
}

.error-p {
  color: darkred;
  font-size: 16px;
  font-style: italic;
}

.team-modal-error-p {
  max-width: 70%;
  padding-left: 20px;
}

.team-modal-inline {
  display: inline-block;
  margin: 0;
}

.team-modal-loader {
  height: 60px;
}

.team-modal-button-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.add-story-btn{
  border-radius: 55px;
  padding: 10px 15px;
  cursor: pointer;
}
.add-story-btn:hover{
  background-color: var(--app-theme-green);
  color: white;
}

.round-sticky-btn-container{
  width: max-content;
  /* margin-left: 30px; */
}

.round-sticky-btn{

  cursor: pointer;
  background: #f57b34;
  color: white;
  padding: 8px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
/* .round-sticky-btn {
  cursor: pointer;
  background: #f57b34;
  color: white;
  padding: 10px 15px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
} */
.round-sticky-btn i {
  margin: 0px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.position-btn-and-title{
  display: flex;
 justify-content: space-between;
}

.page-title-container{
 width: 100%;
align-self: flex-end;
}
.submitted-content-btn-wrapper{
  align-self: flex-end;
  padding-left: 20px;
}

@media (max-width: 480px) {
.round-sticky-btn-container{
    bottom: -40px !important;
    margin-left: 15px !important;
    margin-bottom: 20px;
}
.round-sticky-btn {
  padding: 10px 15px;
}
}