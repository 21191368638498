.me-anime-slide-from-top {
  animation: slide-from-top 0.3s forwards;
  transform-box: fill-box;
}
@keyframes slide-from-top {
  from {
    transform: translate(0px);
    opacity: 0;
  }
  to {
    transform: translate(0px, 40px);
    opacity: 1;
  }
}
.me-anime-move-from-left-normal {
  opacity: 0;
  animation: move-from-left-to-scale 0.4s ease-in 0.4s forwards;
}

.me-anime-move-from-left-fast {
  opacity: 0;
  animation: move-from-left-to-scale 0.3s ease-in 0.3s forwards;
}
.me-anime-move-from-left-fastest {
  opacity: 0;
  animation: move-from-left-to-scale 0.2s ease-in 0.2s forwards;
}
.me-anime-move-from-left {
  opacity: 0;
  animation: move-from-left-to-scale 0.4s ease-in 0.5s forwards;
}
@keyframes move-from-left-to-scale {
  from {
    opacity: 0;
    transform: translate(-100px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}
.me-open-in-slowest {
  opacity: 0;
  animation: do-show-in 0.4s ease-in 1s forwards;
}
.me-open-in-slower {
  opacity: 0;
  animation: do-show-in 0.4s ease-in 0.8s forwards;
}
.me-open-in {
  opacity: 0;
  animation: do-show-in 0.4s ease-in 0.4s forwards;
}
@keyframes do-show-in {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes from-bottom-scale {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
@keyframes do-show-in {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes do-open-in {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1.2);
  }
}

.me-anime-show-up {
  /* animation-delay:.2s; */
  animation: me-fade-in-up 0.3s forwards;
}
.me-anime-show-up-from-top {
  /* animation-delay:.2s; */
  animation: me-fade-from-top 0.3s forwards;
  transform-box: fill-box;
}

@keyframes me-fade-from-top {
  from {
    transform: translate(0px);
    opacity: 0;
  }
  to {
    transform: translate(-50px, 60px);
    opacity: 1;
  }
}
@keyframes me-fade-in-up {
  from {
    transform: translate(-20px);
    opacity: 0;
  }
  to {
    transform: translate(0px);
    opacity: 1;
  }
}
.me-anime-open-in {
  animation-delay: 0.5s;
  animation: open-in 0.6s forwards;
}
@keyframes open-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.me-anime-fade-in-up {
  animation-delay: 1s;
  animation: me-fade-in-up 1s forwards;
}
@keyframes me-fade-in-up {
  from {
    transform: translateY(150px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@keyframes g-spin-circle {
  0% {
    transform: rotate(0);
    animation-timing-function: linear;
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes sheet-animation {
  from {
    opacity: 0;
    transform: translateY(100px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.animate-testimonial-sheet {
  opacity: 0;
  animation: sheet-animation;
  animation-duration: 0.5s;
  animation-delay: var(--sheet-anime-delay);
  /* animation-duration: var(--sheet-anime-duration); */
  animation-fill-mode: forwards;
}

@keyframes rsvp-drop-anime {
  from {
    transform: translate(0px);
    opacity: 0;
  }
  to {
    transform: translate(-70px, -10px);
    opacity: 1;
  }
}

.rsvp-drop-anime {
  opacity: 0;
  animation: rsvp-drop-anime 0.2s forwards;
}
@keyframes rsvp-drop-from-left-anime {
  from {
    transform: translate(0px);
    opacity: 0;
  }
  to {
    transform: translate(100px, 45px);
    opacity: 1;
  }
}

.rsvp-drop-from-left-anime {
  opacity: 0;
  animation: rsvp-drop-from-left-anime 0.2s forwards;
}
