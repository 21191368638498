.root-story-sheet {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: repeat(minmax(150px, auto));
  padding: 20px;
  border-radius: 6px;
  gap: 10px;
  margin-bottom: 20px;
  height: var(--sheet-height-state);
  min-height: 300px;
  overflow-y: hidden;
}

.sheet-image {
  object-fit: contain;
  width: 100%;
  height: auto;
  border: solid 3px var(--app-theme-orange);
  border-radius: 6px;
  padding: 2px;
}
.sheet-content-area {
  padding: 0px 20px;
}

.sheet-content-area h4 {
  padding: 10px 0px;
  border: solid 0px #ededed;
  border-bottom-width: 2px;
}

.sheet-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.me-testi-btn-reset {
  box-shadow: 0 0 0;
  margin: 0px;
  padding: 0px;
  color: var(--app-theme-green) !important;
  font-weight: bold;
  border-width: 0px;
  transform: scale(1) !important;
}
.me-testi-btn-reset:hover {
  background-color: white !important;
  color: var(--app-theme-green);
  border-width: 0px;
}
.me-testi-btn-reset:active,
.me-testi-btn-reset:focus {
  padding: 0px !important;
}

/*css for edit button on the testimonial page*/
.testimonial_edit_button {
  margin-left: auto;
  display: block;
  padding: 5px;
}

.testimonial_edit_button a {
  color: black;
  padding: 12px;
}

.testimonial_edit_button a:hover {
  color: white;
}

.sheet-details p {
  color: black;
  font-size: 0.9rem;
}

.sheet-text {
  font-size: 1rem;
  color: black;
}

.sheet-text p {
  color: black;
}

.sheet-link {
  color: var(--app-theme-orange);
  margin-left: auto;
  cursor: pointer;
}

.sheet-link:hover {
  color: var(--app-theme-green);
  transition: 0.3s ease-out;
}

.sheet-ghost {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  opacity: 0.8;
  z-index: 10;
  height: 100vh;
}

.phone-img-view-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .sheet-text p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 767px) {
  .sheet-text p {
    font-size: 0.8rem !important;
  }
  .me-testi-btn-reset {
    font-size: 16px !important;
  }
}
