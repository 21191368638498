@keyframes fadeItem {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeItemIn {
  opacity: 0;
  animation: 0.3s fadeItem forwards;
}
