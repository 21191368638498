:root {
  --app-theme-orange: #f57b34;
  --app-theme-green: #8dc443;
  --app-theme-green-darker: #75ab2d;
  --app-theme-orange-lighter: #fba930;
  --app-theme-error: #d44b4b;
}

.c-d-t-as-child > a {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.c-d-t-as-child > .dropdown-toggle::after {
  margin-left: auto !important;
}

.c-d-t-as-child:hover {
  background-color: var(--app-theme-orange);
  color: white;
  transition: 0.2s ease-out;
}

.c-d-t-as-child:hover > a > span,
.c-d-t-as-child:hover > .dropdown-toggle::after {
  color: white !important;
  transition: 0.1s ease-out;
}

.child-link:hover {
  background-color: var(--app-theme-orange);
  color: white;
  transition: 0.2s ease-out;
}
.child-link:hover > a {
  color: white;
  transition: 0.1s ease-out;
}
.c-d-t > .dropdown-toggle::after {
  color: rgb(181, 181, 181) !important;
  margin-left: 6px;
}
.c-d-t > .dropdown-menu {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border: solid 0px var(--app-theme-orange);
  border-top-width: 5px;
  border-radius: 0px;
  /* animation: me-fade-from-top 0.3s forwards;
  transform-box: fill-box; */
}

/* .c-d-t > .n-l-item:hover {
  background-color: var(--app-theme-orange);
}

.c-d-t > .n-l-item:hover > span {
  color: white;
} */
.n-l-item {
  font-weight: bold;
  cursor: pointer;
  color: black;
  font-size: 14px;
}

.n-l-item:hover {
  color: var(--app-theme-green);
  transition: 0.3s ease-out;
}
/* link child item */
.l-c-item {
  cursor: pointer;
  padding: 10px 20px;
  color: var(--app-theme-green-darker);
}
.l-c-item:hover {
  /* background-color: var(--app-theme-orange); */
  color: white;
  transition: 0.4s;
}

.form-gen-inner-wrapper {
  padding: 0px 30px;
}
.rich-text-container p,
.rich-text-container ul,
.rich-text-container ol {
  margin: 1em !important;
}
.rich-text-container ul,
.rich-text-container ol {
  padding-left: 2.5em !important ;
}
.shared-badge {
  padding: 2px 13px;
  color: var(--app-theme-orange);
  font-weight: bold;
  margin-left: 11px;
  font-size: 11px;
  border: solid 1px var(--app-theme-orange);
  border-radius: 55px;
  cursor: pointer;
}

.delete-account {
  color: red;
}
.delete-account:hover {
  color: white;
}
.link-to {
  border: solid 0px var(--app-theme-green);
  padding-bottom: 8px;
  color: var(--app-theme-green);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom-width: 2px;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 18px;
}
.link-to p {
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 4px;
  /* color: var(--app-theme-green); */
}
.link-to i {
  margin-left: auto;
}
.link-to:hover p {
  color: var(--app-theme-green-darker);
  opacity: 0.8;
  transition: 0.2s ease-out;
}
.login-form-content {
  border-radius: 12px;
}
.title-bar-close-btn {
  background-color: var(--app-theme-green);
  margin-left: auto;
  color: white;
}
.auth-info {
  color: black;
  /* font-weight: bold; */
}

.auth-textbox {
  border: solid 1px #d7d3d3;
  padding: 0px 20px;
  flex: 7;
}
.auth-text-btn:focus {
  outline: 0;
}
.auth-text-btn {
  display: flex;
  flex-direction: row;
  /* width: 90%; */
}
.auth-footer-root {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fffdfb;
  width: 100%;
  border-bottom-left-radius: 5px;
}
.auth-options-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.auth-title {
  font-weight: bold;
  color: black;
  margin-bottom: 12px;
}

.auth-btns {
  box-shadow: 0 0 0;
  border: 0;
  cursor: pointer;
  margin-bottom: 8px;
  color: white;
}

.auth-btns:disabled {
  background-color: grey !important;
}
/* .auth-btns:focus {
  outline: 0px;
} */

.auth-link {
  border: solid 0px var(--app-theme-green);
  padding-bottom: 3px;
  color: var(--app-theme-green);
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom-width: 2px;
  align-items: center;
}
.auth-link p {
  margin: 0px;
  font-size: medium;
  font-weight: bold;
  color: var(--app-theme-green);
  margin-right: 5px;
}

.settings-p {
  margin-bottom: 5px;
  color: black;
}
.send-sample-report-button {
  background-color: var(--app-theme-color) !important;
  color: white !important;
  border: 1px solid var(--app-theme-color) !important;
}
.send-sample-report-button:hover {
  background-color: white !important;
  color: var(--app-theme-color) !important;
}
.me-light-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #fafafa;
}
.edit-profile-root {
  border: solid 1px 1px solid rgb(243, 243, 243);
  border-radius: 10px;
}
.household-confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-f-bottom-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.stepper-line {
  flex-basis: 20%;
  border: solid 2px #f1f1f1;
}
.step-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.step-name {
  margin-top: 6px;
  font-weight: bold;
}
.stepper-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.stepper-ball-has-state {
  border: solid 2px green;
  background-color: #0f922b;
  color: white;
}
.stepper-ball {
  /* cursor: pointer; */
  margin: 4px;
  border-radius: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-ball small {
  font-size: 16px;
  font-weight: bold;
}

.become-valid-from-guest {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  /* border: solid 2px green; */
  background-color: #ffe9dc;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
  align-items: center;
}
.become-valid-from-guest i {
  margin-left: auto;
  color: var(--app-theme-orange);
  font-size: 35px;
}

.become-valid-from-guest p {
  color: #e0631a;
  font-weight: bold;
  font-size: medium;
  margin: 0px;
}

.sandbox-ribbon {
  background-color: var(--app-theme-orange);
  z-index: 20000;
  position: fixed;
  top: 130px;
  right: 0px;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
}

.sandbox-ribbon small {
  font-weight: bold;
  color: white;
  font-size: medium;
}
.cancel-process {
  margin-left: auto;
  color: var(--app-theme-error);
  text-transform: capitalize;
}
.cancel-process:hover {
  text-decoration: underline;
  color: maroon;
}
.me-auth-not {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background: rgba(187, 235, 187, 0.56);
  color: green;
  cursor: pointer;
  margin-bottom: 6px;
}
.responsive-bread-crumb {
  background-color: rgb(245, 255, 222);
}
.link-groups {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.one-story-html-view p {
  color: black;
}
.element-in-space {
  position: absolute;
  top: -100000000;
  left: -100000000000;
}
.me-team-table-cell {
  color: var(--app-theme-green-darker);
  cursor: pointer;
  margin: 5px 0px;
}
.underline-me {
  border: solid 0px black;
  border-bottom-width: 1px;
  padding-bottom: 5px;
}
.calendar-modal img {
  object-fit: cover;
}
.calendar-modal-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: var(--app-theme-green);
  text-align: center;
  color: white;
}
.calendar-modal {
  display: flex;
  width: 100%;
  flex: 2;
}
.c-event-shared {
  background: var(--app-theme-orange) !important;
}
.c-event-original {
  background: var(--app-theme-green) !important;
}

.c-event-mark {
  /* background: var(--app-theme-green) !important; */
  color: white;

  border-radius: 0px !important;
  font-size: 14px !important;
  cursor: pointer;
}
.c-event-mark:hover {
  /* background-color: var(--app-theme-green) !important; */
  opacity: 0.8;
  transition: 0.2s ease-out;
}
.event-view-togglers {
  display: flex;
  flex-direction: row;
}
.event-view-togglers div {
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

.event-view-togglers div:hover {
  background-color: var(--app-theme-green);
  color: white;
  transition: 0.2s ease-out;
}

.event-view-togglers div > i {
  margin-right: 6px;
}
.event-view-toggler-active {
  background-color: var(--app-theme-green);
  color: white;
}

.word-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}
.team-about-richtext-wrapper p {
  color: black;
}
.equivalence-small {
  color: var(--app-theme-green);
  cursor: pointer;
  font-weight: bolder;
}

.equivalence-small:hover {
  color: var(--app-theme-orange);
  transition: 0.3s ease-out;
}
.me-nav-profile-pic {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  object-fit: cover;
  padding: 2px;
  border: solid 2px var(--user-pref-color);
}
.me-nav-profile-pic:hover {
  transform: scale(0.9) translateY(-6px);
  border-color: var(--app-theme-green);
  transition: 0.3s ease-out;
}
.remove-toggle-outline:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 !important;
}
.date-string {
  font-size: 0.85rem;
}
.me-rsvp-btn {
  padding: 12px 18px;
  font-size: 0.9rem;
  background: var(--app-theme-orange);
  color: white;
  height: 100%;
  /* margin-right: -20px; */
  border-radius: 0px;
  cursor: pointer;
  border: 0px;
  font-weight: bold;
}
.me-rsvp-btn:active {
  background: orange !important;
  color: black;
}

.me-rsvp-btn:hover {
  background: var(--app-theme-green);
  transition: 0.2s ease-out;
}

.every-day-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 12;
}

.stories-sm-vendor {
  /* text-align: center; */
  cursor: pointer;
  /* padding: 10px; */
  border-radius: 5px;
  border: solid 1px #e8e7e7;
}
.stories-sm-vendor:active {
  transform: scale(1.1);
  transition: 0.3s ease-out;
}
/* 
.stories-sm-vendor:hover {
  -webkit-box-shadow: -2px 2px 20px 0px rgb(0 0 0 / 0%),
    0 2px 10px 0 rgb(0 0 0 / 9%) !important;
  box-shadow: -2px 2px 20px 0px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 9%) !important;
} */

.stories-sm-vendor img {
  border-radius: 5px;
  max-height: 300px;
  padding: 10px 20px;
  object-fit: cover;
}

.stories-sm-vendor small {
  font-size: 15px;
  color: black;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  display: block;
  padding: 10px;
}

.stories-sm-vendor small:hover {
  text-decoration: underline;
  color: rgb(74, 30, 4);
}

.events-about-content a {
  color: #8dc343;
  text-decoration: none;
}

.events-about-content a:hover {
  text-decoration: underline;
}

.stats-bar-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100vw;
  margin-bottom: 10px;
  border-radius: 6px;
}
.me-link {
  color: #8dc443;
  font-weight: bolder;
}

.me-link:hover {
  text-decoration: underline;
}

.stats-q-box:active {
  transform: scale(1.4);
  transition: 0.3s ease-out;
}
.stats-q-box:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  transition: 0.2s ease-out;
}

.stats-q-box p {
  text-align: center;
  font-size: 13px;
  color: black;
  margin-bottom: 0px;
}

.team-s-w-about-us-h {
  border: solid 0px #8dc443;
  /* border-radius: 5px; */
  border-top-width: 2px;
  border-bottom-width: 2px;
  cursor: pointer;
}
.team-s-w-about-us-h:hover {
  /* background: whitesmoke; */
  border-color: #fd704c;
  transition: 0.2s ease-out;
}

.subteams-link {
  color: black;
}
.subteams-link:hover {
  color: #8dc443;
}
.team-s-w-members-h {
  border: solid 0px #8dc443;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.team-s-w-members-h:hover {
  background: whitesmoke;
  transition: 0.3 ease-out;
}
.team-s-w-header {
  padding: 10px;
  font-weight: bold;
  text-transform: capitalize;
  z-index: 10;
  /* border: solid 0px #e8e8e8;
  border-bottom-width: 2px; */
}
.team-s-w-body {
  padding: 5px 10px;
}
.team-s-w-members-b {
  border: dotted 0px #cccccc;
  border-left-width: 4px;
  margin-top: 10px;
  margin-left: 19px;
  z-index: -1;
}

.phone-search-input {
  width: 100%;
  margin: 4px;
  border: solid 2px var(--app-theme-green);
  border-radius: 55px;
  padding: 10px 25px;
  margin-bottom: 30px;
  /* margin-top: -25px; */
  font-size: 14px;
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 7%), 0 2px 4px 0 rgb(0 0 0 / 3%);
  -webkit-box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 7%),
    0 2px 4px 0 rgb(0 0 0 / 3%);
}

.round-caret {
  position: absolute;
  right: 3px;
  top: 3px;
  padding: 7px 15px;
  background: #f1f1f1;
  border-radius: 100%;
  cursor: pointer;
}

.round-caret:hover {
  background: #cccccc;
  transition: 0.2s ease-out;
}
.me-custom-page-title {
  font-size: 24px;
}

.accordion-clear {
  padding: 7px 10px;
  border: solid 0px #f9f9f9;
  border-bottom-width: 1px;
  color: #8c8888;
}
.accordion-clear:active {
  background: #ff8787;
  color: white;
  border: #ff8787;
}

.accordion-header {
  box-shadow: 0px 0px 0px !important;
}
.accordion-item {
  padding: 7px 10px;
  margin: 0px;
  /* background:grey; */
  font-size: 15px;
  border: solid 0px #f9f9f9;
  border-bottom-width: 1px;
  color: #8c8888;
}

.accordion-item-active {
  background: green;
  color: white;
  border-color: white;
}

.accordion-item:active {
  color: white;
  border-color: green;
  background: green;
}
.filter-modal-footer {
  display: flex;
  /* padding:20px;  */
  background: #f7f7f7;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.modal-title-bar {
  display: flex;
  border: solid 0px #efefef;
  border-bottom-width: 2px;
  padding: 17px;
  background: #edffed;
}

.modal-title-bar > h3 {
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.filter-full-page-settings {
  padding: 0px !important;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0px !important;
}

.filter-custom-modal-tweaks {
  border-radius: 0px !important;
  height: 100vh;
  overflow-y: scroll;
  max-height: 100vh;
  padding: 0px !important;
}

.custom-bars-btn {
  display: inline-block;
}

.custom-bars-btn:focus {
  outline: 0;
}

.custom-bars-btn:active {
  color: #4caf50;
}
.me-light-drop-fine-tune {
  padding: 9px 16px;
}
.action-box-counter {
  display: inline-block;
  /* padding:15px;  */
  min-height: 280px;
  border: solid 3px #8dc343;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

/* .round-filter-clear-all{ 
  border-radius:55px; 
  background:maroon; 
  padding:10px; 
  position: absolute; 
  transform: translateX(50)
} */

.touchable-opacity {
  cursor: pointer;
  font-weight: bold;
}

.touchable-opacity:hover {
  opacity: 0.8;
  transition: 0.2s ease-out;
}
.add-testimonial-container {
  height: 100%;
  padding-right: 15px;
  position: absolute;
  right: -16px;
  top: 0px;
  display: inline-block;
}
.add-testimonial {
  cursor: pointer;
  height: 100%;
  background: #f57b34;
  color: white;
  padding: 13px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.add-testimonial p {
  margin: 0px;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.hori-search-box {
  display: inline-block !important;
  border-width: 0px !important;
  padding-left: 33px !important;
  margin-left: 30px !important;
  width: 130%;
}

.filter-close {
  font-weight: bold;
  color: #de8603;
  font-size: medium;
  cursor: pointer;
}
.filter-close:hover {
  text-decoration: underline;
  color: #467718;
  transition: 0.3s ease-in-out;
}
.full-list-btn {
  width: 100%;
  background: #8dc343;
  position: absolute;
  bottom: 0;
  padding: 7px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  color: white;
  text-align: center;
}

.full-list-btn:hover {
  background: #4caf50;
  color: white;
  transition: 0.3s ease-in-out;
}

.full-list-btn:focus {
  outline: none;
}
.box-ico {
  margin: 10px;
  font-size: 25px;
  color: #8dc343;
}

.box-counter-label-btn {
  padding: 0px 19px;
  font-size: 11px;
  background: #8dc343;
  border-radius: 55px;
  cursor: pointer;
  color: white;
  margin-top: 6px;
  display: inline-block;
  text-decoration: none;
}

.box-counter-label-btn:hover {
  color: white;
  background: #ff7709;
  transition: 0.3s ease-out;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* h-cat-select = horizontal-category-selected */
.h-cat-select {
  /* color: #ff7709; */
  cursor: pointer;
  background: white;
  /* border: solid 1px #eaeaea; */
  /* border: solid 1px #ff7709; */
  font-size: 11px;
  padding: 8px 15px;
  margin: 0px 4px;
  font-weight: bold;
  color: black;
}

.h-cat-select:hover {
  background: #e4e4e4;
  /* color: white; */
  /* border: solid 1px #8dc343; */
  transition: 0.1s ease-in-out;
}

.h-f-label {
  /* color: #696969; */
  color: black;
  font-weight: 600;
}

.h-f-label:hover {
  text-decoration: underline;
  color: #8dc343;
  transition: 0.3s ease-in-out;
}
/* ---------------- Just Overwriting Some default Bootstrap Mechs... ------------------------- */
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: transparent !important;
  color: inherit;
}
.clear-drop-after::after {
  display: none !important;
}

/* ----------------------------------------------------------------------------------------- */

.me-light-drop-clickable {
  border: 0px;
}
.me-light-drop-clickable:hover {
  background: transparent;
  color: black;

  transition: 0.3s ease-in-out;
}

.me-light-drop-clickable:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.footer-social {
  display: inline-block;
  padding: 13px 20px;
  border-radius: 55px;
  background: #3c5897;
  cursor: pointer;
  margin: 5px;
}

.footer-social i {
  color: white;
  font-size: 25px;
}
.footer-social:hover {
  transform: scale(1.06);
  transition: 0.2s ease-in-out;
}

.choose-hh-form {
  padding-bottom: 10px;
  min-height: 220px;
  max-height: 220px;
  overflow-y: scroll;
}
.act-rect {
  width: 17px;
  height: 17px;
  border: solid 2px green;
  display: inline-block;
  margin-right: 10px;
}

.act-selected {
  background: #18c125;
  border-color: #ccf3cc;
}

#act-item-Container {
  display: flex;
  align-items: center;
}

#act-item-Container_SingleHouse {
  width: 100%;
  padding: 10px 20px;
  border: dotted 0px #e2e2e2;
  border-bottom-width: 2px;
}

/*fixed CSS issue with dropdown related to action completion date */
#CompletionDate {
  padding: 5px;
  margin-left: auto;
  /* position: absolute; */
  /* right: 0; */
  /* margin:10px 15px; */
}
.act-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border: dotted 0px #e2e2e2;
  border-bottom-width: 2px;
  cursor: pointer;
  margin: 0px;
  margin-top: 3px;
  transition: transform 0.1s ease-out;
  position: relative;
}

.act-item:hover {
  background: #f7f9f7;
  color: black;
  transition: 0.2s ease-out;
}

.act-item:active {
  /* transform: scale(0.95); */
}

.act-error {
  background: #fff6e9;
  color: #a0640c;
  padding: 7px 30px;
  width: 97%;
  margin: 10px;
  border-radius: 5px;
  font-size: 15px;
}
.act-item p {
  margin-bottom: 0px;
  font-size: 15px;
  color: #282828;
  display: inline-block;
}
.act-title-bar {
  padding: 14px 20px;
  width: 100%;
  background: green;
  margin-bottom: 10px;
}

.act-title-bar h3 {
  color: white;
  font-size: 18px;
  margin-bottom: 0px;
}

/*limits modal width on parents page */
.parent-act-modal-whole {
  max-width: 500px;
  margin: auto;
}
.act-modal-whole {
  min-height: 33vh;
  max-height: 90vh;
  position: relative;
}

.act-modal-body {
  max-height: 70vh;
  overflow-y: scroll;
}

.act-status-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  /* height: 64px; */
  height: 54px;
  background: #fbf9f9;
  left: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  /* align-items:right;  */
  justify-content: space-between;
}

.close-flat {
  background: #f19090;
  color: white !important;
  border-bottom-right-radius: 6px;
}

.close-flat:hover {
  background: #cc3d3d !important;
  transition: 0.3s ease-out;
}

.flat-btn_submit {
  color: white !important;
}

.me-flat-btn {
  box-shadow: 0 0 0 !important;
}

.flat-btn {
  color: #282828;
  cursor: pointer;
  display: inline-block;
  transition: box-shadow 0.1s ease-out;
}

.flat-btn:active {
  box-shadow: 0 0 0 #f1f1f1, inset 4px 4px 4px #0c3e0c !important;
}

.flat-btn:focus {
  outline: none !important;
}

.flat-btn:hover {
  background: green;
  color: white;
  transition: 0.3s ease-out;
}

/* ----- CAMELEON BTN EXTRA CLASSES ------- */

.cam-btn-defaults {
  /* padding:6px 22px  !important; */
  font-weight: bold;
  padding: 5px 15px !important;
  font-size: 14px;
  transition: box-shadow 0.2s ease-in-out !important ;
}
.cam-btn-defaults:active {
  box-shadow: 0 0 0 #f1f1f1, inset 4px 4px 4px green !important;
}
.cam-gray-btn {
  /* background: hsl(0, 2%, 83%) !important; */
  background: gray !important;
  /* border-color: #d4d2d2 !important; */
  border-color: gray !important;
  color: white !important;
}
.cam-gray-btn:active {
  box-shadow: 0 0 0 #f1f1f1, inset 4px 4px 4px rgb(211, 211, 211) !important;
}
.cam-gray-btn:hover {
  background: white !important;
  border-color: white !important;
  color: black !important;
  transition: 0.1s linear;
}

.cam-orange-btn {
  background: #ff7600 !important;
  border-color: #ff7600 !important;
  color: white !important;
}
.cam-orange-btn:hover {
  background: #8dc343 !important;
  border-color: #8dc343 !important ;
  color: white !important;
  transition: 0.1s linear;
}

/* ---------------------------------------- */
.snackbar-main-container {
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding: 10px 10%;
}
.v-story-img {
  height: 100px;
  width: 110px;
  object-fit: cover;
  display: inline-block;
  /* margin-top:-90px; */
  margin-top: 20px;
  border-radius: 10px;
  position: absolute;
}
.extra-story-cards {
  font-size: medium;
  border-radius: 4px !important;
  padding: 10px 15px !important;
}
.ripped-border {
  /* padding: 10px 15px;
  border: dashed 4px #c5e796;
  background: white; */
  padding: 10px 15px;
  border: solid 0px #f7f7f7;
  background: white;
  /* border-radius: 5px; */
  border-bottom-width: 2px;
  border-top-width: 2px;
}
.me-social:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.me-social {
  margin: 9px;
  border-radius: 55px;
  outline: 0;
}

.me-social:focus {
  outline: 0 !important;
}
.image-chooser-default {
  border-radius: 10px;
  width: 250px;
  margin-bottom: 10px;
  cursor: pointer;
}
.image-chooser-default:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.form-title-section {
  background: #f7f7f7;
  color: black;
  padding: 11px 15px;
  font-size: medium;
  font-weight: bold;
}
.filter-check-text-font {
  font-size: 14px;
  margin-bottom: 2px;
}
.override-container-width {
  max-width: 1100px !important;
}
.put-me-in-the-middle {
  position: absolute;
  /* margin-left: 40%; */
  /* top: 67px; */
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
}
.snackbar-main-container p {
  color: white;
  font-weight: bolder;
}
.snackbar-success {
  background: linear-gradient(to right, #8dc343, rgb(30 189 145));
}
.snackbar-accent {
  background: linear-gradient(to right, #ea5b08, #ffc107);
}
.snackbar-union {
  background: linear-gradient(to right, #6dbd00, #b54510);
}
.snackbar-plain p {
  color: #282828;
}

.me-show-from-bottom {
  animation-delay: 0.2s;
  animation: from-bottom-scale 0.2s forwards;
}
.me-event-date-info {
  /* position:absolute; 
  bottom:0px; 
  right:10px; */
}
.me-event-card-img {
  display: inline-block;
  height: 220px;
  width: 30%;
  object-fit: cover;
}
.me-event-date-info {
  background: linear-gradient(to right, #8ec344, #28a745);
  padding: 20px 30px;
  color: white;
  border-bottom-radius: 10px;
  border-bottom-left-radius: 10px;
}
.me-event-content-div {
  height: 100%;
  display: inline-block;
  width: 56%;
  margin-left: 24px;
}
.me-anchor-link-new {
  /* color:#0d5813; */
  color: #583d0d;
}

.me-anchor-link-new:hover {
  color: #ea5b08;
}
.me-testimonial-about {
  display: flex;
  border: solid 0px #f5f5f5;
  /* border-top-width:2px;  */
  border-bottom-width: 2px;
  padding: 15px;
  color: black;
  /* background: linear-gradient(to right, #ea5b08, #ffc107); */
  background: #fbfbfb;
}
.me-testimonial-content-box {
  /* padding:15px;  */
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-top: -10px;
  min-height: 30vh;
  z-index: 50;
  background: white;
  border-radius: 10px;
}

.new-me-testimonial-img {
  width: 100%;

  object-fit: contain;
  border-radius: 18px;
  padding: 10px;
}
.bottom-date-area {
  /* padding: 13px; */
  background-color: #f9f9f9; /* var(--app-theme-orange); */
  display: flex;
  flex-direction: row;
  color: #75ab2d;
  font-weight: bold;
  /* min-height: 70px; */
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.me-testimonial-img {
  width: 100%;
  height: 46vh;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.testimonial-link-holder {
  border: solid 0px #f1f1f1;
  padding: 5px 10px;
  /* border-radius: 5px; */
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.me-check-container:hover .me-check-square {
  background: #f57b34;
  transition: 0.3s ease-in-out;
}

.me-check-container {
  color: #5a5959;
}

.me-text-color-app {
  color: #8dc343;
}
.me-check-container:hover {
  color: #f57b34;
  transition: 0.3s ease-in-out;
}
.me-round-sharp {
  border-radius: 55px !important;
}
.me-uploader-container {
  padding: 35px;
  width: 100%;
  border: dashed 2px gainsboro;
  min-height: 30vh;
}
.me-check-square-active {
  background: #8dc343 !important;
  transition: 4s ease-in-out;
}

.me-floating-check-active {
  animation: do-open-in 0.4s forwards;
}
.me-open-in {
  animation-delay: 0.4s;
  animation: do-show-in 0.6s forwards;
}

.me-modal-wrapper {
  padding: 20px;
}
.me-check-square {
  display: inline-block;
  height: 24px;
  width: 24px;
  background: #f7f5f5;
  margin-right: 10px;
  vertical-align: center;
  margin-bottom: -6px;
  border-radius: 3px;
}
.me-floating-check {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: white;
  position: absolute;
  bottom: 8px;
  left: 7px;
  border-radius: 2px;
}

.reset-margin {
  margin: 0;
}
.me-default-text-field {
  resize: none;
  border: solid 0px #e1f9d8;
  border-left-width: 4px;
  border-radius: 0px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  height: calc(1.5em + 0.75rem + 2px);
}
.cancel-btn-position-fix {
  position: absolute;
  /* right: 40px; */
  right: 7px;
  bottom: 45px;
}
.me-dropdown {
  margin-bottom: 50px;
  border: solid 0px #f57b34;
  max-height: 300px;
  overflow-y: scroll;
  border-top-width: 5px;
  background: white;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;
}
.ghost-cover-screen {
  background: white;
  height: 1000000000px;
  width: 10000000000px;
  position: fixed;
  /* z-index: 100; */
  top: 0px;
  left: 0px;
  opacity: 0.01 !important;
}
.me-select-head {
  /* border: solid 2px #eaeaea; */
  border: solid 2px #c3ecb3;
  border-radius: 3px;
  width: 100%;
  padding: 10px 18px;
  cursor: pointer;
}
.me-select-head p {
  color: green;
}

.me-select-head:hover {
  background: #f57b34;
  border-color: #f57b34;
  color: white;
  transition: 0.2s ease-in;
}
.me-select-head:hover > p {
  color: white;
}

.me-drop-item p {
  color: black;
}
.me-drop-item {
  cursor: pointer;
  color: black;
}
.me-drop-item:hover {
  background: #f57b34;
  color: white !important;
  transition: 0.08s ease-in;
}
.me-drop-item:hover p {
  color: white;
}
.me-drop-item-active {
  color: white !important;
  background: #f57b34;
}
.me-drop-item-active,
.me-drop-p {
  color: white !important;
}
.force-padding-20 {
  padding: 20px !important;
}

.force-padding-15 {
  padding: 12px !important;
}
.only-left-border:focus,
.only-left-border:active,
.only-left-border {
  border-radius: 0px;
  border: 0px solid #c3ecb3;
  border-left-width: 3px;
  padding: 20px 15px;
  box-shadow: none !important;
  margin-bottom: 7px;
}
.only-bottom-border:focus,
.only-bottom-border:active,
.only-bottom-border {
  margin-top: 15px !important;
  border-radius: 0px;
  border: 1px solid #c3ecb3;
  border-bottom-width: 3px;
  padding: 20px 20px;
  box-shadow: none !important;
}

.me-vendor-card {
  padding: 20px 70px !important;
  border-radius: 0px;
  border: solid 0px #8ec344;
  border-left-width: 10px;
  margin-bottom: 20px !important;
}
.me-vendor-img {
  width: 40%;
  min-height: 200px;
  object-fit: contain;
  display: inline-block;
  margin-right: 40px;
}
.circle-loader {
  padding: 10px;
  height: 70px;
  width: 70px;
  background: white;
  border-radius: 100%;
  border: dotted 10px black;
  border-top-color: #8dc343;
  border-right-color: #f57b34;
  border-bottom-color: #f57b34;
  border-left-color: #8dc343;
  animation: g-spin-circle 0.7s infinite;
}

.me-modal-sm {
  padding: 20px 25rem !important;
}

.me-close:hover {
  border-color: #f15f5f !important;
  background: #f15f5f !important;
  color: white !important;
  transition: 0.2s linear;
}
.me-modal-content {
  position: fixed;
  transform: translate(0%, 4rem);
  /* width: 100%; */
  padding: 30px;
  z-index: 600;
}
.me-modal-fade-down {
  animation: me-fade-down 0.4s forwards;
  -webkit-animation: me-fade-down 0.4s forwards;
}
@keyframes me-fade-down {
  from {
    opacity: 0;
    transform: scale(0.7);
    /* transform: translateY(-100px); */
  }
  to {
    opacity: 1;
    transform: scale(1);
    /* transform: translateY(0px); */
  }
}
.me-actions-gradient-belt {
  background: linear-gradient(to right, green red) !important;
}

.me-overlay {
  background: black;
  opacity: 0.65;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  cursor: pointer;
  top: 0;
  left: 0;
}
.move-up-and-vanish {
  transform: translateY(-80px);
  opacity: 0;
  z-index: -1;
  height: 0px;
  width: 0px;
  transition: 0.3s linear;
}

.move-down-and-appear {
  transform: translateY(0px);
  opacity: 1;
  height: auto;
  width: auto;
  transition: 0.3s linear;
}

.round-badge {
  border: solid 2px #fd704c;
  border-radius: 55px;
  padding: 2px 6px;
  font-size: 11px;
}
.me-badge {
  position: absolute;
  right: 0;
  margin-right: 20px;
  border-radius: 55px;
  color: gray;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bolder;
  background: white;
}
.me-sect-w-right {
  position: absolute;
  right: 0;
  margin-right: 20px;
  border-radius: 55px;
  color: black;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bolder;
}
.me-section-wrapper-header {
  /* border-radius:6px; */
  background: white;
  position: relative;
  padding: 15px 20px;
  min-width: 13rem;
  cursor: pointer;
  z-index: 20;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.me-card {
  padding: 20px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}
.me-card-hover {
  cursor: pointer;
}
.me-card-hover:hover {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  /* background: #ecffe2; */
  background: #f3f3f3;
  transition: 0.3s linear;
}
.me-section-wrapper-header:hover {
  background: #5b940e;
  color: white;
  transition: 0.2s linear;
}

.me-section-wrapper-body {
  /* position: absolute; */
  padding: 15px;
  border: dashed 2px #ececec;
  border-top-width: 0px;
  width: 100%;
  min-height: 30px;
  background: white;
}

.section-green {
  background: #8dc343;
  color: white;
  cursor: pointer;
}
.me-table {
  border-collapse: collapse;
}
.me-table-top-banner {
  background: #0ac131;
  color: white;
}
.me-table-header {
  padding: 10px 20px;
  cursor: pointer;
  border: solid 0px green;
  min-width: 10vh;
}
.me-table-header-hoverable:hover {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  color: gray;
  transition: 0.2s linear;
}

.start-team-btn {
  color: white;
  background: #8dc63f;
  border-width: 0px !important;
  margin: 0 8px;
  height: 100%;
  font-size: 16px;
  line-height: 17px;
}
.put-me-inline {
  display: inline-block;
}

.me-universal-btn[disabled],
.me-universal-btn[disabled]:hover {
  background: gray;
  color: white;
  border-color: gray;
}
.me-universal-btn[disabled] span {
  color: white !important;
}

.me-universal-btn:hover .me-btn-icon {
  color: white !important;
}
.me-circle-loader {
  padding: 10px;
  height: 70px;
  width: 70px;
  background: white;
  border-radius: 100%;
  border: dotted 10px black;
  border-top-color: #8dc343;
  border-right-color: #f57b34;
  border-bottom-color: #f57b34;
  border-left-color: #8dc343;
  animation: g-spin-circle 0.7s infinite;
}
.me-google-btn {
  /* border-width:0px; */
  background: #db3236 !important;
  border-color: #db3236 !important;
  color: white !important;
}
.me-google-btn:hover {
  background: white !important;
  color: #db3236 !important;

  border-color: #db3236 !important;
  border-top-color: #3cba54 !important;
  border-bottom-color: #f4c20d !important;
  border-right-color: #4885ed !important;
  transition: 0.2s ease-in-out;
}
.me-email-btn {
  /* border-width:0px; */
  background: #8dc343 !important;
  border-color: #8dc343 !important;
  color: white !important;
}
.me-email-btn:hover {
  background: white !important;
  color: #8dc343 !important;

  border-color: #8dc343 !important;
  border-top-color: #3cba54 !important;
  border-bottom-color: #f4c20d !important;
  border-right-color: #4885ed !important;
  transition: 0.2s ease-in-out;
}
.me-facebook-btn {
  /* border-width:0px; */
  background: #4885ed !important;
  border-color: #4885ed !important;
  color: white !important;
}

.me-delete-btn:hover {
  background: red !important;
  border-color: red !important;
  color: white !important;
  transition: 0.2s linear;
}
.me-facebook-btn:hover {
  background: white !important;
  color: #4885ed !important;
  transition: 0.2s ease-in-out;
}
.me-paragaph {
  margin: 8px 0px;
  /* color:#adabab; */
  color: #282828;
}
.me-undefault-btn {
  padding: 10px 20px;
}
.me-undefault-btn,
.me-undefault-btn:active,
.me-undefault-btn:focus {
  display: inline-block;
  outline: 0;

  background: white;
  border-color: white;
  border-style: solid;
  color: black;
}
.undo-dropdown-active:active,
.undo-dropdown-active:focus {
  background: white !important;
  color: black !important;
  border-color: white !important;
}
.me-universal-btn {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 55px;
  cursor: pointer;
  margin: 6px;
  /* background-position:center;
  transition: background 0.5s; */
}
.me-btn-green:hover {
  /* border:0px; */
  background: #8dc343;
  border-color: #8dc343;
  color: white;
  transition: 0.25s ease-in-out;
}
.me-btn-union:active,
.me-btn-union:focus {
  background: #8dc343;
  border-color: #8dc343;
  color: white;
}
.me-btn-union {
  background: #8dc343;
  color: white;
  border-color: #8dc343;
  border-width: 0px !important;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.me-btn-union span {
  color: white;
}
.me-btn-union:hover {
  background: #f57b34;
  border-color: #f57b34;
  color: white;
  transition: 0.25s ease-in-out;
}

.me-btn-accent:hover {
  background: #f57b34;
  border-color: #f57b34;
  color: white;
  transition: 0.25s ease-in-out;
}

.me-dropdown-theme {
  margin-top: 5px !important;
  border: solid 0px #f57b34 !important;
  border-radius: 0px;
  border-top-width: 5px !important;
}
.me-dropdown-theme-item {
  font-weight: bold;
}

.me-dropdown-theme-item:hover {
  color: white !important;
  background: #f57b34 !important;
  transition: 0.001s ease-in !important;
}
.collapse-team-btn {
  color: black;
  /* background: lightgrey; */
  background: #e2e2e2;
  border-width: 0px !important;
  padding: 6px 25px;
  margin: 0 8px;
  font-size: 16px;
  line-height: 17px;
}

.collapse-team-btn.bottom-right {
  position: relative;
  float: right;
  top: -35px;
  right: 20px;
}

.testimonials-image-desc {
  color: #8dc63f;
  font-weight: bold;
}
.testimonials-choose-img-btn-tweaks {
  padding: 8px 21px !important;
  display: inline-block;
}

.slight-lift {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 55.16), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 55.16),
    0 1px 4px 0 rgba(0, 0, 0, 0.12);
}
.round-only-right-side {
  border-top-right-radius: 55px !important;
  border-bottom-right-radius: 55px !important;
}
.round-only-left-side {
  border-top-left-radius: 55px !important;
  border-bottom-left-radius: 55px !important;
}
.comp-teams-btn {
  color: white;
  background: #f67b61;
  border-width: 0px !important;
  padding: 6px 25px;
  margin: 0 8px;
  height: 100%;
  font-size: 16px;
  line-height: 17px;
}

.join-team-btn {
  height: 40px;
  color: white;
  background: #8dc63f;
  border-width: 0px !important;
  padding: 4px 15px;
  font-size: 17px;
  font-style: italic;
}

.modal-force-full-width {
  width: 100% !important;
}
.leave-team-btn {
  height: 40px;
  color: white;
  background: darkgray;
  border-width: 0px !important;
  padding: 5px 10px;
  margin: 5px;
}

.contact-admin-btn-new {
  height: 40px;
  color: white;
  background: #f67b61;
  border-width: 0px !important;
  padding: 8px 12px;
  margin: 5px;
}

.sort-btns {
  cursor: pointer;
}

.sort-btns:hover {
  color: #8dc63f;
  transform: scale(1.03);
  transition: 0.4s ease-in-out;
}

.start-team-btn:hover {
  color: white;
  background: #517325;
  transition: 0.15s ease-in-out;
}
.collapse-team-btn:hover {
  color: black;
  background: darkgrey;
  transition: 0.15s ease-in-out;
}
.comp-teams-btn:hover {
  color: white;
  background: #b35846;
  transition: 0.15s ease-in-out;
}

.join-team-btn:hover {
  color: white;
  background: #517325;
  transition: 0.15s ease-in-out;
}
.contact-admin-btn-new:hover {
  color: white;
  background: #b35846;
  transition: 0.15s ease-in-out;
}
.leave-team-btn:hover {
  color: white;
  background: #616161;
  transition: 0.15s ease-in-out;
}
.contact-admin-btn:hover {
  color: white;
  background: #b35846;
  transition: 0.15s ease-in-out;
}

.team-img {
  height: 80px !important;
  width: 80px !important;
  object-fit: contain !important;
}

.impact-graph-heading {
  font-size: 1rem !important;
  margin-bottom: 30px;
}
.impact-graph-title {
  font-size: 1rem !important;
  color: "black";
}
.imp-desc-box {
  padding: 10px;
  border: solid 1px white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 35px;
}
.homepage-all-events-btn {
  background: #8dc63f;
  color: white;
  margin-top: 15px;
  padding: 10px 30px;
  text-align: center;
  border: solid 1px #8dc63f;
  /* width:70%; */
  outline: 0 !important;
}
.homepage-all-events-btn:hover {
  color: white;
  border-color: #f67b61;
  background-color: #f67b61;
  transition: 0.4s;
}
.c-tooltip-text-paper {
  position: relative;
  padding: 0px 0px;
  min-width: 200px;
  max-width: 360px;
  overflow-wrap: break-word;
  background: white;
  border: solid 1px grey;
  border-radius: 5px;
  z-index: 2;
  /* top: 50px; */
  left: -30px;
  /* bottom: -50px; */
  bottom: -16px;
  padding: 5px;
  line-height: 1;
}

.triangle {
  border: solid 15px red;
  width: 0px;
  border-left-color: white;
  border-right-color: white;
  border-bottom-color: white;
}

.c-tooltip-msg-container {
  padding-left: 10px;
  display: none;
  position: absolute;
}
.c-tooltip-text-paper:hover {
  display: none;
}
.c-tooltip-container:hover .c-tooltip-msg-container {
  display: block;
  transition: 0.4s ease-in-out;
}

.fake-show {
  opacity: 0 !important;
}
.team-btn-edit {
  padding: 0px 8px !important;
}
.make-me-dark {
  color: #373535 !important;
}

.make-me-dark p {
  color: #373535 !important;
}
.acc-item {
  color: #848484 !important;
  cursor: pointer;
}
.acc-item:hover {
  color: #8dc63f !important;
  transition: 0.4s ease-in-out;
}
.story-name {
  display: inline;
  margin-top: 140px;
  background: #8dc63f;
  padding: 3px 20px 3px;
  border-radius: 55px;
  font-size: 11px;
  font-weight: 800;
  color: white;
}
.fa-m-right {
  margin-right: 7px;
}
.okay-btn {
  background: white;
  border-radius: 55px;
  padding: 5px 15px;
  margin: 6px;
  color: black;
  font-weight: 600;
  cursor: pointer;
}
.okay-btn:hover {
  background: #f67b61;
  color: white;
  transition: 0.4s;
}

.ash-paper {
  margin-top: 11px;
  border: solid 0px rgb(141, 198, 63);
  border-top-width: 20px;

  text-align: left;
  width: 100%;
  padding: 20px 50px;
  min-height: 150px;
  background: #f1faf44a;
  border-radius: 10px;
}
.m-label {
  border-radius: 4px;
  font-weight: 900;
  padding: 3px 10px;
  background: #e8e6e6;
  margin: 4px 9px;
}
/* .td{
  position:absolute !important;
} */

/* .todo-correction{
  float:left;
} */

.header-logo {
  max-width: 150px;
  max-height: 100px;
  margin-left: 30px;
  margin: 10px;
}
/* me-file-chooser-position-correction */
.me-f-c-pos-correction {
  top: 40vh !important;
  left: -260px !important;
  width: 160% !important;
}
.scroll-fix::-webkit-scrollbar {
  width: 0px;
  background: white;
}
/* Teams Page Styles */
.teams-subheader {
  color: #c7c7c7;
  font-size: 1.65rem;
  margin-bottom: 10px;
  /* font-style: italic */
}

.data-explanation-dialog {
  padding: 20px;
  /* border: solid 4px green; */
  border-radius: 10px;
  margin-bottom: 35px;
}

.exp-title {
  color: green;
  font-weight: bold;
}

.try-guest-mode {
  /* margin:5px;  */
  width: 100%;
  background: var(--app-theme-green-darker);
  text-align: center;
  cursor: pointer;
  margin: 10px 0px;
}

.try-guest-mode p {
  margin: 0px;
  font-weight: bold;
  color: white;
  text-decoration: underline;
}

.guest-dialog-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.responsive-p {
  color: black;
}

.guest-dialog-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* height: 100%; */
}
.guest-congrats {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.email-helpers-div {
  flex-direction: row;
  display: flex;
}
.all-head-area {
  margin-top: -20px;
}
.me-subtitle {
  color: black;
}

.combo-btn-tweak {
  margin: 0px;
  height: auto;
}
.me-standard-img {
  background: rgb(251, 251, 251);
  object-fit: contain;
  border-radius: 10px;
  width: 100%;
}
.t-area-title {
  color: rgb(167, 167, 167);
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.rel-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
}

.other-t-area {
  display: flex;
  flex-wrap: wrap;
}

/* ========= PC EXCLUSIVE ===== */

@media only screen and (min-width: 768px) {
  .other-t-area {
    flex-direction: row;
  }
  .rel-action {
    padding: 10px 15px;
    width: 100%;
  }
  .related-area {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .me-standard-img {
    height: 400px;
  }
  .small-form-spacing {
    margin: 20px 0px;
    /* margin-bottom: 20px; */
  }
  .new-sign-in {
    padding: 10px 15px;
    font-size: 14px;
  }
  .guest-congrats {
    height: 50%;
  }
  .auth-textbox {
    height: 50px !important;
  }
  .login-form-content {
    padding: 55px;
  }
  .auth-info {
    font-size: medium;
  }
  .title-bar-close-btn {
    padding: 10px 15px;
  }
  .dynamic-width {
    width: var(--width);
  }
  .auth-btns {
    padding: 13px 30px;
  }
  .combo-btn-tweak {
    min-width: 160px;
    padding: 13px 10px !important;
  }
  .auth-title {
    font-size: 25px;
  }
  .auth-options-root {
    padding: 0px 50px 50px 50px;
  }
  .me-subtitle {
    font-size: large;
  }
  .flat-btn {
    padding: 15px 30px;
  }
  .me-flat-btn {
    padding: 13px 30px;
  }
  .edit-profile-content {
    padding: 30px;
  }
  .household-confirmation {
    min-height: 100%;
  }
  .g-f-bottom-footer {
    padding: 10px 20px;
  }
  .stepper-ball {
    padding: 20px;
    height: 40px;
    width: 40px;
  }
  .become-valid-from-guest {
    padding: 20px 30px;
  }
  .sandbox-ribbon {
    padding: 10px 20px;
  }
  .all-head-area {
    margin-bottom: 30px;
  }
  .me-auth-not {
    padding: 15px 20px;
  }
  .me-auth-not i {
    margin-right: 20px;
    font-size: 30px;
  }
  .cookie-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cookie-container .cookie-content {
    flex: 8;
  }
  .cookie-content {
    width: 70%;
    padding: 10px 15px;
  }
  .responsive-p {
    font-size: large;
  }
  .guest-dialog-container {
    padding: 0px 20px 10px;
  }
  .try-guest-mode {
    padding: 30px 40px;
  }
  .try-guest-mode p {
    font-size: 25px;
  }
  .responsive-bread-crumb {
    margin-bottom: 31px;
  }
  .new-me-testimonial-img {
    height: 242px;
  }
  .about-us-img {
    width: 100%;
    min-height: 300px;
    object-fit: contain;
    margin: 10px;
    height: auto;
  }
  .mob-f-text {
    color: black;
  }
  .register-form-content {
    padding: 46px;
    border-radius: 12px;
  }
  .email-helpers-div {
    padding: 15px 0px;
  }
  .temp-dn-fix {
    margin-right: 3%;
  }
  .temp-f-donate-area {
    margin: 0px 10%;
  }
  .temp-f-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    padding: 10px 10%;
  }

  .temp-f-container div {
    flex: 1;
  }
  .footer-column-mod {
    margin: 0px 40px;
  }
  .cameleon-correct {
    font-weight: bold;
    padding: 4px 15px !important;
    font-size: 0.9rem;
  }
  .scroll-fix {
    width: 200px;
    position: fixed;
    /* left: 120px; */
    background: white;
    z-index: 1;
  }
  .action-items-n-sidebar-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  .stats-q-box {
    cursor: pointer;
    padding: 10px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-prov-img {
    min-height: 200px;
    max-height: 200px;
    object-fit: contain;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .pref-height {
    min-height: 265px !important;
    max-height: 265px !important;
  }
  .com-select-auto-edits {
    margin-right: 20%;
    margin-left: 20%;
  }

  .SuccessNotification {
    left: 50%;
    right: auto;
    bottom: 24px;
    transform: translateX(-50%);
    z-index: 99;
    position: fixed;
    /* border: 1px solid black; */
    border-radius: 10px;
  }

  .SuccessNotification_Header {
    justify-content: space-between;
  }

  .submit-testimonial-button {
    background-color: green;
    color: white;
  }

  .submit-testimonial-button:hover {
    background-color: lightgreen;
  }

  .close-testimonial-modal-button {
    background-color: red;
    color: white;
  }

  .close-testimonial-modal-button:hover {
    background-color: lightcoral;
  }

  .hori-filter-container {
    text-align: center;
    padding: 3px 0px 0px;
    margin-bottom: 20px;
    border-radius: -22px;
    margin-top: -20px;
    border: solid 2px var(--app-theme-orange);
    border-left-width: 0px;
    border-right-width: 0px;
    white-space: nowrap;
    overflow-x: scroll;
    /* max-width: 100%; */
    overflow-y: hidden;
    height: auto;
  }
  .redo-hori-filter-container {
    height: 90vh !important;
    border-bottom-width: 0px;
    position: absolute;
    max-width: 1100px !important;
  }

  .snackbar-success .mob-modal-correction {
    margin-top: -5vh !important;
  }
  .me-modal-md {
    margin-top: -10vh;
    padding: 20px 7rem !important;
    min-width: 100vw;
  }

  .home-page-graph-tweak {
    margin-top: 20px !important;
  }
  .btn-envelope {
    display: flex;
    justify-content: center;
  }
  .more-hyperlink {
    font-size: 15px !important;
    font-weight: 700;
    margin: 3;
    cursor: pointer;
    color: #f67b61;
  }
  .more-hyperlink:hover {
    color: #8dc63f;
    transition: 0.4s;
  }

  .mob-homepage-chart-h {
    height: 250px !important;
  }
  .imp-chart-h {
    height: 250px !important;
  }

  /* .done-it-correction{
    float: right;
    margin-top: -21px !important;
    right: 184px !important;

  } */
  /* .done-it{
    position: absolute !important;
    right:170px !important; 
    top:0px !important;
    margin-bottom: 30px;
  } */
  .toast-bar {
    padding: 17px 30px;
    text-align: center;
    /* position: fixed; */
    bottom: 0px;
    width: 98%;
    z-index: 30;
    background: #8dc63f;
    left: 0px;
    margin: 10px;
    color: white;
    right: 190px;
    border-radius: 6px;
  }
  .pc-icon-div-container-fix {
    margin-top: -110px;
  }
  .filter-search-input {
    width: 120%;
    padding: 10px 25px !important;
    border: solid 1px #eafff3;
    border-radius: 55px;
    margin-left: -15px !important;
  }

  .mob-modal-p {
    padding-top: 25px;
    padding-right: 20px;
    text-align: justify;
    margin: 20px;
    max-height: 500px;
    overflow-y: scroll;
  }
  .pc-vanish {
    display: none !important;
  }
  .event-calendar-container {
    height: 500px;
  }
  .c-event-mark {
    padding: 5px !important;
  }
  .calendar-modal {
    flex-direction: row;
    height: 350px;
  }

  .calendar-modal img {
    width: 50%;
    height: 100%;
  }
  .calendar-modal * {
    flex: 1;
  }
  .event-view-togglers div {
    padding: 15px 25px;
    font-size: 15px;
  }
  .profile-pic-preview {
    width: 200px;
    height: 200px;
  }
  .me-light-footer {
    margin-top: 20px;
  }
  .vendor-pending-approval-banner {
    right: 10%;
    top: 40%;
  }
}
.profile-pic-preview {
  border-radius: 100%;
  object-fit: cover;
}
/* ------------------------------- TABLETS ----------------------------- */
@media (min-width: 768px) and (max-width: 1024px) {
  .tablet-vanish {
    display: none !important;
  }
  .redo-hori-filter-container {
    max-width: 96%;
  }
  .vendor-pending-approval-banner {
    right: 10%;
  }
  .testimonial-edit-btn-text {
    width: 100%;
  }
  .action-items-n-sidebar-container {
    grid-template-columns: 2fr 4fr;
  }
  .scroll-fix {
    width: 164px;
  }

  .ical-btn {
    width: 37% !important;
  }
  .google-calendar-btn {
    width: 60% !important;
  }
}

@media screen and (width: 768px) {
  .action-items-n-sidebar-container {
    display: block;
  }
  .scroll-fix {
    width: 154px;
    display: none;
  }
  .tablet-force-show {
    display: flex !important;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    color: white !important;
    background-color: var(--app-theme-green);
    justify-content: space-around;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .act-stats-item {
    display: flex;
    flex-direction: column;
  }

  .act-stats-item small {
    font-size: 0.9rem;
  }
}

/* ------------------------ MOBILE PHONE AREA ------------------------- */
@media only screen and (max-width: 767px) {
  .other-t-area {
    flex-direction: column;
  }
  .rel-action {
    padding: 4px 10px;
    width: 100%;
  }
  .rel-action img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 5px;
  }

  .rel-action h5 {
    font-size: 16px;
  }
  .related-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .t-area-title {
    font-size: large;
  }
  .me-standard-img {
    height: 230px;
  }
  .small-form-spacing {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .form-gen-inner-wrapper {
    padding: 0px 15px;
  }
  .new-sign-in {
    padding: 10px;
    font-size: 13px;
  }
  .login-form-content {
    padding: 0px;
  }
  .auth-info {
    font-size: 13px;
  }
  .force-no-elevation-on-mobile {
    box-shadow: 0 0 0 !important;
  }
  .auth-textbox {
    font-size: 13px;
    height: 47px;
    padding: 10px !important;
  }
  .title-bar-close-btn {
    padding: 6px 13px;
  }
  .auth-title-bar {
    position: absolute;
    width: 100%;
  }
  .dynamic-width {
    width: 90% !important;
  }
  .auth-btns {
    padding: 13px 18px;
    font-size: 14px;
    min-width: 101px;
  }
  .combo-btn-tweak {
    min-width: 120px !important;
    padding: 13px 9px;
  }
  .auth-title {
    font-size: 22px;
  }
  .auth-options-root {
    padding: 30px 20px 20px 20px;
  }
  .me-subtitle {
    font-size: medium;
  }
  .settings-wrapper h1 {
    font-size: x-large;
  }
  .settings-wrapper {
    padding: 10px 20px;
  }
  .me-light-footer {
    margin-top: 10px;
  }
  .profile-pic-preview {
    width: 120px;
    height: 120px;
  }
  .me-flat-btn {
    padding: 13px 23px !important;
    font-size: small !important;
  }
  .flat-btn {
    padding: 15px 20px;
    font-size: 14px;
  }
  .edit-profile-content {
    padding: 15px;
  }
  .household-confirmation {
    min-height: 230px;
  }
  .g-f-bottom-footer {
    padding: 10px;
  }
  .stepper-ball {
    width: 35px;
    height: 35px;
  }
  .become-valid-from-guest p {
    font-size: 15px;
  }
  .become-valid-from-guest {
    padding: 15px 20px;
  }
  .sandbox-ribbon {
    padding: 15px;
  }
  .all-head-area {
    margin-bottom: 20px;
  }
  .stick-mob-stats {
    background: white;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 100000;
    left: 0px;
    padding: 14px;
    margin-bottom: 0px;
  }
  .action-mob-stats-container {
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--app-theme-green);
    color: white !important;
  }
  .act-stats-item {
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .act-stats-item small {
    font-weight: bold;
  }

  .stats-eq {
    /* color: var(--app-theme-green-darker); */
  }
  .me-auth-not {
    font-size: 13px;
    padding: 10px;
  }
  .cookie-btn-area button {
    margin: 10px 0px;
    width: 100%;
  }
  .cookie-btn-area {
    width: 100%;
  }
  .cookie-content {
    width: 100%;
    padding: 0px 5px;
  }
  .stories-row {
    margin-top: 100px;
  }
  .guest-congrats {
    padding: 0px 10px;
    font-size: 14px;
    text-align: center;
    flex-direction: column;
    height: 100%;
  }
  .responsive-p {
    font-size: 15px;
  }
  .guest-dialog-container {
    padding: 30px 15px 0px 15px;
  }
  .try-guest-mode {
    padding: 20px 10px;
  }
  .try-guest-mode p {
    font-size: 14px;
  }
  .product-tab-box {
    padding: 0px 20px !important;
  }
  .mob-tab-box-fix {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    width: 100vw !important;
  }
  .mob-reset-padding {
    padding-right: 0px !important;
  }
  .responsive-bread-crumb {
    margin-bottom: 10px;
  }
  .new-me-testimonial-img {
    max-height: 220px;
  }
  .about-us-img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 10px;
    height: auto;
  }
  .solid-font {
    font-weight: bold;
    text-align: center;
  }
  .link-groups {
    width: 100%;
    justify-content: center;
  }
  .register-form-content {
    box-shadow: 0px 0px 0px 0px !important;
  }
  .col-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .col-link-btns button {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .mob-log-submit {
    padding: 8px 12px !important;
  }
  .btn-grouping {
    margin-top: 15px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  .mob-f-text {
    font-size: 16px;
    color: black;
  }
  .email-helpers-div {
    padding: 15px 0px;
    margin-bottom: 30px;
  }
  .email-helpers-div a {
    margin-top: 5px;
    font-size: 14px;
  }
  .temp-f-donate-area {
    padding: 15px;
  }
  .temp-f-container {
    padding: 15px;
  }
  .temp-mob-footer-fix {
    justify-content: center;
    text-align: center;
  }
  .event-view-togglers div {
    padding: 10px;
    font-size: 14px;
  }
  .c-m-info {
    height: 100%;
  }
  .calendar-modal img {
    width: 100%;
    height: 200px;
  }

  .calendar-modal {
    flex-direction: column;
    height: 500px;
  }
  .event-calendar-container {
    height: 300px;
  }
  .event-view-togglers {
    margin-top: 100px;
    justify-content: center;
  }
  .data-explanation-dialog {
    border: solid 1px green;
    margin: 5px;
    padding: 10px 10px;
  }
  .exp-title {
    font-size: 14px;
  }

  .data-explanation-dialog li {
    font-size: 14px;
  }
  .mob-padding-zero {
    padding: 0px;
  }
  .imp-desc-box {
    margin-top: 0px;
  }
  .imp-title {
    font-weight: bold;
    padding: 4px 27px;
    font-size: 16px;
  }
  .imp-phone-desc {
    padding: 15px;
    font-size: 16px;
  }
  .me-section-title {
    font-size: 16px !important;
    padding: 5px 13px;
    margin-bottom: 30px;
    line-height: 1.5;
    /* font-weight: bold; */
  }
  /* .home-d-fixes{ 
    margin-top:60
  } */
  .cam-btn-defaults {
    padding: 5px 13px !important;
  }
  .root-story-sheet {
    grid-template-columns: 1fr !important;
    padding: 10px 6px !important;
    height: var(--sheet-height-state-mobile) !important;
    box-shadow: 0 0 0 !important;
  }

  .sheet-text {
    font-size: 0.8rem !important;
  }
  .sensitive-photo {
    height: 120px !important;
  }
  .cameleon-correct {
    font-weight: bold;
    font-size: 0.8rem !important;
    padding: 4px 10px !important;
  }
  .phone-font-15 {
    font-size: 15px;
  }
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.2rem;
  }

  .teams-subheader {
    font-size: 1.1rem;
  }
  .team-contact-admin-card {
    height: 100vh;
    padding: 20px 10px;
  }
  .team-contact-admin-modal-mob {
    padding: 0px !important;
    top: 0px !important;
    margin-top: 0px !important;
  }
  .donate-page-space-fix {
    padding: 15px !important;
  }
  .scroll-fix {
    height: fit-content;
    overflow-y: hidden;
  }
  .mob-borderless {
    box-shadow: 0px 0px 0px !important;
    border: 0px !important;
  }
  .stats-q-box {
    cursor: pointer;
    padding: 2px;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-prov-img {
    min-height: 70px;
    max-height: 90px;
    object-fit: contain;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .pref-height {
    min-height: 125px !important;
    max-height: 265px !important;
  }
  .close-mob-padding {
    padding: 10px 14px !important;
  }
  .bubble-communities {
    font-size: 13px;
  }
  .me-tag-line {
    font-size: 25px;
  }
  .com-select-auto-edits {
    margin-right: 5%;
    margin-left: 5%;
  }
  .phone-marg-top-90 {
    margin-top: 20px !important;
  }
  .phone-marg-top {
    margin-top: 50px;
  }
  .hori-filter-container {
    position: absolute;
    z-index: 4;
    height: 100vh;
    /* text-align: center; */
    padding: 3px 0px 0px;
    margin-bottom: 16px;
    border-radius: -22px;
    margin-top: -20px;
    border: solid 0px #f1f1f1;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
  }

  .me-light-drop-fine-tune {
    padding: 9px 0px !important;
  }
  .h-f-label {
    /* color: #696969; */
    color: black;
    font-weight: 600;
    font-size: 13px;
  }
  .mob-check-fix {
    max-width: 100% !important;
  }

  .phone-action-btns-mode {
    font-size: 10px !important;
    min-width: 60px !important;
    padding: 7px 20px !important;
  }
  .me-f-c-pos-correction {
    top: 90vh !important;
    left: -20px !important;
    width: 110% !important;
  }
  .mob-modal-correction {
    margin-top: -7vh;
  }
  .page-text-phone-mode {
    font-size: 12px !important;
  }
  .me-btn-phone-mode {
    font-size: 12px;
    padding: 5px 13px;
  }

  .snackbar-main-container {
    width: 100%;
    position: absolute;
    bottom: 20px;
    padding: 10px 2%;
  }
  .mob-snackbar-text {
    font-size: 0.8rem !important;
  }
  .i-need-my-space {
    display: block !important;
    width: 100%;
  }
  .me-modal-sm {
    padding: 13px !important;
  }
  .me-modal-md {
    max-width: 100vw !important;
    padding: 13px;
  }
  .home-page-graph-tweak {
    margin: 10px !important;
  }
  .btn-envelope {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .phone-big-title {
    font-size: 18px !important;
  }
  .phone-medium-title {
    font-size: 1rem !important;
  }
  .mob-team-title {
    font-size: 16px !important;
    font-weight: 600;
  }

  .mob-team-text {
    font-size: 15px !important;
  }
  .more-hyperlink {
    font-size: 17px !important;
    font-weight: 700;
    margin: 3;
    cursor: pointer;
    color: #f67b61;
  }
  .more-hyperlink:hover {
    color: #8dc63f;
    transition: 0.4s;
  }
  .testimonial-edit-btn-text {
    width: 100% !important;
    margin-bottom: 10px;
  }

  /* .green-done-it-correction{
    position: absolute !important; 
    right:30% !important;

  } */
  /* .done-it{
    position: absolute !important;
    right:80px !important; 
    top:0px !important;
    margin-bottom: 30px;
  } */

  /* .done-it-correction{
    float: right;
    margin-top: -22px !important;
    right: 30% !important;
  } */
  .ical-btn {
    width: 35% !important;
  }
  .google-calendar-btn {
    width: 61% !important;
  }
  .mob-line-break {
    display: block;
  }
  .mob-cancel-title-white {
    margin-bottom: 10px !important;
  }
  .mob-font-lg {
    font-size: 1rem !important;
  }
  .mob-helper {
    /* margin-top:67px; */
    margin-top: 7px !important;
  }
  .toast-bar {
    padding: 17px 30px;
    text-align: center;
    /* position: fixed; */
    bottom: 0px;
    width: 98%;
    z-index: 30;
    background: #8dc63f;
    left: -7px;
    margin: 10px;
    color: white;
    right: 190px;
    border-radius: 6px;
  }
  .pt-3 h1 {
    font-size: 21px !important;
  }

  .mob-story-form-tweak {
    padding: 31px 7px !important;
  }
  .mob-event-cards-fix {
    padding-right: 0px !important;
  }
  .mob-event-white-cleaner {
    margin-top: -65px !important;
  }
  .mob-contact-white-cleaner {
    margin-top: -45px;
  }
  .mob-contact-form-tweak {
    padding: 19px !important;
  }

  .mob-zero-padding {
    padding: 0px !important;
  }
  .mob-zero-margin {
    margin: 0px !important;
  }
  .filter-search-input {
    width: 110%;
    padding: 10px 25px !important;
    border: solid 1px #eafff3;
    border-radius: 55px;
    margin-left: -15px !important;
  }

  .mob-modal-pic-tweak {
    height: 48px !important;
    width: 50px !important;
    top: -68px !important;
    left: 2px !important;
  }
  .mob-modal-close-x {
    background-color: white;
    top: -20px !important;
    right: 8px !important;
  }
  .mob-modal-title {
    font-size: 16px;
  }
  .mob-modal-p {
    padding-top: 30px;
    padding-right: 20px;
    font-size: 13px;
    text-align: justify;
    max-height: 420px;
    overflow-y: scroll;
  }
  .mob-modal-card {
    top: 16% !important;
    width: 97% !important;
    left: 15% !important;
    height: 550px !important;
  }
  .card1 {
    left: 20px !important;
  }
  .card2 {
    left: 30px !important;
  }
  .mob-profile-card-fix {
    position: absolute !important;
    height: 170px !important;
    padding: 1px 42px !important;
  }

  .mob-icon-fix {
    margin-bottom: -13px !important;
    margin-top: 10px !important;
    margin-left: 10px !important;
  }

  .h6-card-fix {
    font-size: 0.7rem !important;
  }
  .mob-login-white-cleaner {
    margin-top: -20px !important;
  }
  .mob-title-fix {
    font-weight: bold !important;
    font-size: 1.45rem !important;
    margin-bottom: 10px;
  }
  .mob-sweet-b-10 {
    margin-bottom: 10px !important;
  }
  .mob-login-card-fix {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding: 30px 5px !important;
  }
  .mob-vendor-white-cleaner {
    margin-top: -100px !important;
  }

  .mob-icon-white-fix {
    margin-top: -107px !important;
    margin-bottom: -70px !important;
  }
  .phone-vanish {
    display: none !important;
  }
  .action-pic-fix {
    margin-top: 10px !important;
  }
  .mob-btn-left-fix {
    position: absolute !important;
    margin-top: 7px;
    left: 48%;
  }

  .mob-pad-absolute-0 {
    padding: 0px !important;
  }
  .mob-margin-absolute-0 {
    margin: 0px !important;
  }

  .mob-our-impact-div {
    height: 30px !important;
    margin: 0px !important;
  }
  .mob-event-section {
    padding-top: 10px !important;
  }
  .mob-homepage-chart-h {
    height: 280px !important;
  }
  .mob-pad-0 {
    padding: 0px !important;
  }
  .mob-margin-0 {
    margin: 0px !important;
  }
  .mob-impact-pad-fix {
    padding: 35px !important;
  }
  .mob-actions-page-padding-remove {
    padding-right: 0px !important;
  }
  .mob-testy-card-fix {
    left: -17px;
  }
  .mob-appear {
    display: block !important;
  }

  .land-textbox {
    margin: 0px !important;
    margin-bottom: 15px !important;
  }

  .vendor-pending-approval-banner {
    right: 50% !important;
  }

  /* temporary remove while we decide best actions scrolling
  .fix-filter{
    max-height: 70vh;
    overflow-y: overlay;
  } */
}
/*  ====== END END END END ========== */

.mob-appear {
  display: none;
}
.mob-actions-page-padding-remove {
  padding-right: 40px;
}

.land-textbox {
}
.mob-impact-pad-fix {
}
.mob-pad-0 {
}
.mob-testy-card-fix {
}
.mob-event-section {
}
.mob-btn-left-fix {
}
.mob-pad-absolute-0 {
}
.mob-margin-absolute-0 {
}
.mob-our-impact-div {
}
.testi-green-monster {
  height: 70px;
  z-index: 0;
  object-fit: contain;
  display: inline-block;
  border: solid 2px #beda9f;
  width: 70px;
  border-radius: 100%;
  padding: 0px;
  position: absolute;
  top: -62px;
  object-fit: cover;
  left: -3px;
  background: white;
}
.testi-modal-pic {
  height: 90px;
  z-index: 0;
  object-fit: cover;
  display: inline-block;
  border: solid 2px #beda9f;
  width: 90px;
  border-radius: 100%;
  padding: 0px;
  position: absolute;
  top: -65px;
  left: 5px;
  background: white;
}

.energize-link {
  text-decoration: none;
  color: #8dc343;
}
.energize-link:hover {
  color: #dd4b39;
  transition: 0.4s ease-in-out;
}
/* one - #c63f3f 
   two - #dc3545
   three - #a0337e
   
*/
.testi-card {
  border: soild 1px lightgray;
  padding: 15px;
  margin: 5px;
  margin-bottom: 170px;
  margin-right: -63px !important;
}
.testi-anchor {
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: 7px;
  color: #c63f3f;
}

.testi-more {
  width: 100%;
  cursor: pointer;
  margin-right: 10px;
  /* float: right;  */
  clear: both;
  font-size: 12px;
  border: solid 1px #f6c7cb;
  border-radius: 55px;
  padding: 11px 50px;
  outline: 0 !important;
}
.testi-more:hover {
  background: green;
  color: white;
  border-color: green;
  transition: 0.5s ease-in-out;
}
.testi-para {
  width: 100%;
  position: absolute;
  z-index: 2;
  min-height: 276px;
  background: white;
  padding: 20px;
  margin-top: -87px;
  border-radius: 9px;
}
.testi-para:hover {
  transform: scale(1.05);
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.testi-img {
  height: 220px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  z-index: 0;
}
.g-back {
  padding: 10px 25px;
  border: solid 1px lightgray;
  border-radius: 55px;
  margin-bottom: 10px;
}

.g-back:hover {
  background: "green";
  color: "white";
  border-color: green;
  transition: 0.4s ease-in-out;
}
.sm-tag-hover:hover {
  background: darkred;
  color: white !important;
  border-color: darkred !important;
  transition: 0.4s ease-in-out;
}
.d-one {
  background: #c63f3f;
}
.d-two {
  background: #dc3545;
}
.d-three {
  background: crimson;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: white;
}

.indiv-done-it-orange {
  background: #ff7600 !important;
  color: white !important;
  cursor: pointer;
}
.indiv-done-it {
  background: gray !important;
  color: white !important;
  cursor: pointer;
}
.indiv-done-it-orange:hover {
  background: gray !important;
  transition: 0.1s ease-in-out;
}
/* .indiv-done-it:hover{
  background:#ff7600 !important; 
  transition: .1s ease-in-out;
} */

.vendor-hover:hover {
  -webkit-box-shadow: 0 0px 22px 0 rgba(0, 0, 0, 0.24),
    0 1px 11px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 0px 22px 0 rgba(0, 0, 0, 0.24), 0 1px 11px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s ease-in-out;
}

::-webkit-scrollbar-track {
  background: white;
  -webkit-box-shadow: 0 0 0;
  border-radius: 10px;
  opacity: 0.2;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background: white;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
}
.force-height-event {
  height: 190px !important;
}
.m-form-outer {
  padding: 25px;
  border: solid 1px lightgray;
  border-radius: 10px;
  min-height: 500px;
  max-height: 500px;
  margin-bottom: 30px;
}
.m-btn {
  padding: 10px 30px;
  border-radius: 55px;
  margin: 9px;
}

.m-textbox {
  padding: 20px;
  font-size: 16px;
  margin: 6px 0px;
}

.loc-banner {
  padding: 10px 10px !important;
  background-color: #8dc443 !important;
}

.home-events-img {
  width: 100%;
  height: 260px !important;
  object-fit: cover;
}
.home-event-hover:hover {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition: 0.4s ease-in-out;
}

.zero-margin-btm {
  margin-bottom: 0px !important;
}
.cursor {
  cursor: pointer !important;
}
.font-textbox {
  padding: 30px 40px;
  font-size: 18px;
  margin: 30px;
}
.whitish {
  color: "white !important";
}
.raise-my-text {
  text-shadow: -1px 1px 1px #a5a5a5;
}
.com-domain-link {
  color: #17865d !important;
  border: solid 1px #f1efef;
  padding: 6px 25px;
  margin: 0px;
  background: white;
  border-radius: 100px;
  cursor: pointer;
  text-transform: capitalize;
}
.com-domain-link:hover {
  border-color: White;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s ease-in-out;
}
.mass-domain-link {
  color: white;
  border: solid 1px #ed5a14;
  padding: 10px 25px;
  margin: 0px;
  background: #ed5a14;
  border-radius: 100px;
  cursor: pointer;
}
.mass-domain-link:hover {
  color: white;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s ease-in-out;
}
.hover-service-item {
  -webkit-box-shadow: 0 2px 0px 0 rgba(0, 0, 0, -0.84),
    0 2px 0px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, -0.84), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.toggle-filter-button {
  border: solid 1px greenyellow;
  padding: 12px;
  margin: 10px 0px;
  color: black;
  font-size: 1rem;
}

.m-service-title {
  color: #383838 !important;
}
.toggle-filter-button:hover {
  cursor: pointer;
  background: greenyellow;
  color: black;
  transition: 0.4s;
}

.cool-font {
  font-family: "Google Sans", sans-serif !important;
}

.m-action-item {
  border-radius: 12px;
  cursor: pointer;
  border-color: #f3f3f3 !important;
}
.cart-t-bar {
  background: #8dc63f !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.line-me {
  display: inline-block;
}
.m-action-item:hover {
  /* -webkit-box-shadow: 0 2px 0px 0 rgba(0, 0, 0, -0.84),
    0 2px 0px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, -0.84), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}
.action-btns {
  padding: 8px 19px;
  border-radius: 100px !important;
  margin: 6px;
}
.new-sign-in {
  border-radius: 200px;
  border: solid 2px #8dc63f;

  cursor: pointer;
  color: white;
  background: #8dc63f;
  margin: 18px;
  outline: 0 !important;
}
.m-cart-header {
  padding: 11px;
  background: #8dc63f !important;
  color: white !important;
  font-size: 19px;
  font-weight: 600;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.m-footer-color {
  background: #232f3e !important;
}
.b-footer-color {
  background: #282828 !important;
}
.print-cancel-style {
  padding: 10px 84px !important;
  border-color: crimson !important;
  border-radius: 155px !important;
}
.combo-box-wrapper {
  border-radius: 6px;
  padding: 7px;
  background: rgb(245, 255, 222) !important;
  margin: 4px;
}
.select-undefault {
  border-width: 0px !important;
  background: transparent !important;
}
.count-cardy {
  /* border:solid 1px lightgray !important;  */
  border-radius: 11px;
  margin: 2px 5px !important;
}
.summary-finish {
  border: solid 1px #e8730f;
  background: #e8730f;
  outline: none;
  color: white;
  border-radius: 100px !important;
  padding: 15px 37px !important;
}

.summary-finish:hover {
  background: white;
  color: #e8730f;
  border-color: #e8730f;
  transition: 0.4s ease-in-out;
}
.btn-finishing {
  border-radius: 100px !important;
  padding: 10px 37px !important;
}
.new-sign-in:hover {
  background: gray;
  border-color: gray;
  color: white;
  transition: 0.4s;
}
.desc-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  z-index: 101;
}

.modal-box {
  position: fixed;
  top: 30%;
  left: 22%;
  margin-top: -50px;
  margin-left: -50px;
  height: 50%;
  width: 60%;
  background: white;
  z-index: 101;
  padding: 25px;
  border-radius: 10px;
}

.mass-engergize-about {
  padding: 25px;
}
.community-about-text {
  /* padding:25px; */
}
.modal-close-x {
  position: absolute;
  right: 35px;
  cursor: pointer;
  border: solid 1px #298b00;
  padding: 4px 9px;
  color: #585858;
}

.round-me {
  border-radius: 100px !important;
}

.push-to-right {
  right: -140px;
}
.modal-close-x:hover {
  background: #ff7600;
  border-color: #ff7600;
  color: white;
  transition: 0.4s;
}

.contact-admin-btn {
  font-size: 13px;
  font-weight: 700;
  background: #73626f;
  border-radius: 5px;
  padding: 7px 11px;
  cursor: pointer;
  color: white;
}
.contact-admin-btn:hover {
  background: #8dc63f;
  color: white;
  transition: 0.4s ease-in-out;
}

.raise-2 {
  box-shadow: 0px 1px 1px #9e9e9e;
}
.raise {
  box-shadow: 0px 1px 2px #9e9e9e;
}

.z-depth-sticker {
  box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 4px 0 rgb(0 0 0 / 3%);
}
.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.z-depth-2 {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-3 {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-4 {
  -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.z-depth-5 {
  -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22) !important;
}
.z-depth-float {
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 0px 0 rgba(0, 0, 0, -1.84),
    0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.z-depth-float-half {
  box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%),
    0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
.put-me-block {
  margin-bottom: 15px;
}
.m-content-wrapper {
  max-height: 80vh;
  overflow-y: auto;
}
.me-modal-header {
  background-color: var(--app-theme-green);
  color: white;
  padding: 15px 20px;
  z-index: 1000;
  width: var(--modal-width-size);
}
.me-modal-title {
  font-weight: bold;
  text-align: left !important;
}

.edit-badge {
  padding: 2px 13px;
  color: var(--app-theme-green);
  font-weight: bold;
  margin-left: 11px;
  font-size: 11px;
  border: solid 1px var(--app-theme-green-darker);
  border-radius: 55px;
  cursor: pointer;
}

.footer-wrapper {
  z-index: 10;
}

.testimonial-edit-btn-text {
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 20px;
  background-color: var(--app-theme-green);
  font-size: 0.8rem;
  border-radius: 5px;
  width: 48%;
  text-align: center;
  border: 1px solid var(--app-theme-green);
}
.testimonial-edit-btn-text:hover {
  background-color: white;
  color: var(--app-theme-green);
}
.testimonial-add-btn-text {
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 20px;
  background-color: var(--app-theme-orange);
  font-size: 0.8rem;
  border-radius: 55px;
  width: 48%;
  text-align: center;
  border: 1px solid var(--app-theme-orange);
}
.testimonial-add-btn-text:hover {
  background-color: white;
  color: var(--app-theme-orange);
}

.pending-approval {
  color: var(--app-theme-orange);
  cursor: pointer;
  padding: 2px 10px;
  border: 1px solid var(--app-theme-orange);
  font-size: 0.8rem;
  border-radius: 6px;
  position: absolute;
  background-color: white;
}

/* ,......... */

.ribbon-container {
  --d: 6px; /* folded part */
  --c: #f57b34; /* color */
  --f: 0.8rem; /* ribbon font-size */
  z-index: 1000;
}

.ribbon-container::before {
  content: attr(data-ribbon);
  position: absolute;
  font-size: var(--f);
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  color: #fff;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
    no-repeat var(--c);
  background-clip: padding-box;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  -webkit-mask: linear-gradient(
        135deg,
        transparent calc(50% - var(--d) * 0.707),
        #fff 0
      )
      bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)
      bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  z-index: 10;
}

.cal-btn {
  background-color: grey;
  color: white;
  border: 1px solid grey !important;
  font-size: 0.7rem !important;
}
.cal-btn:hover {
  background-color: white !important ;
  color: grey;
}
.ical-btn {
  width: 43%;
}
.google-calendar-btn {
  width: 55%;
}
