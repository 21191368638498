/* VimeoPlayer - v2.54.0 - 2017-03-30 */

@charset "UTF-8";
.player,
.player::after {
    position: relative;
    overflow: hidden
}

.player .invisible,
.player.loading .controls-wrapper,
.player.loading .video-wrapper {
    opacity: 0
}

.player .cloaked,
.player::after {
    visibility: hidden
}

.player,
.player .captions>span {
    text-rendering: optimizeLegibility;
    color: #fff
}

.player .controls.trailer,
.player .menu,
.player .menu .item-container,
.player .outro .videos header h1,
.player .overlay-wrapper[data-name=email-capture] form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

@-webkit-keyframes buffer {
    100% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }
}

@keyframes buffer {
    100% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }
}

@-moz-keyframes bufferLeft {
    0% {
        left: 0
    }
    100% {
        left: -10px
    }
}

@-webkit-keyframes throb {
    0%,
    100% {
        background-color: #555
    }
    50% {
        background-color: #444
    }
}

@keyframes throb {
    0%,
    100% {
        background-color: #555
    }
    50% {
        background-color: #444
    }
}

@-webkit-keyframes wiggle {
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
    20% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    40%,
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px)
    }
    60% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes wiggle {
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
    20% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    40%,
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px)
    }
    60% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes pulse {
    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@keyframes pulse {
    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

:-webkit-full-screen-ancestor>:not(:-webkit-full-screen-ancestor):not(:-webkit-full-screen) {
    display: none!important
}

:-moz-full-screen-ancestor>:not(:-moz-full-screen-ancestor):not(:-moz-full-screen) {
    display: none!important
}

:-ms-fullscreen-ancestor>:not(:-ms-fullscreen-ancestor):not(:-ms-fullscreen) {
    display: none!important
}

:fullscreen-ancestor>:not(:fullscreen-ancestor):not(:fullscreen) {
    display: none!important
}

body:not(.showfocus) .player button,
body:not(.showfocus) .player li,
body:not(.showfocus) .player span,
body:not(.showfocus) .player svg {
    outline: 0!important
}

.player .menu li:active,
.player .menu li:not(:focus),
.player .menu span:active,
.player .menu span:not(:focus),
.player .outro .videos a:focus,
.player a:active,
.player button:active,
.player button:not(:focus) {
    outline: 0
}

.player {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: auto;
    line-height: normal;
    border-collapse: separate;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.player,
.player h1,
.player h2 {
    font-family: "Helvetica Neue", Helvetica, Arial!important;
    font-size: 10px
}

.player,
.player * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent
}

.player::after {
    content: "normal";
    display: block;
    height: 0;
    width: 0
}

.player .hidden,
.player .only-in-fullscreen {
    display: none!important
}

.player .player-alert,
.player .target {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.player.scrubbing * {
    cursor: -webkit-grabbing!important;
    cursor: grabbing!important
}

.player button {
    cursor: pointer;
    font-size: 1em
}

.player button,
.player input,
.player textarea {
    border: 0;
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial
}

.player img {
    border: 0
}

.player a {
    text-decoration: none
}

.player .rounded-box {
    background: rgba(23, 35, 34, .75);
    border-radius: .5em
}

.player .fill {
    fill: #fff
}

.player .toggle.off .fill {
    fill: #666
}

.player .toggle.off .stroke {
    stroke: #666
}

.player .stroke {
    stroke: #fff;
    fill: none
}

.js-player-fullscreen:-webkit-full-screen .only-in-fullscreen {
    display: block!important
}

.js-player-fullscreen:-moz-full-screen .only-in-fullscreen {
    display: block!important
}

.js-player-fullscreen:-ms-fullscreen .only-in-fullscreen {
    display: block!important
}

.js-player-fullscreen:fullscreen .only-in-fullscreen {
    display: block!important
}

.player .target {
    height: 100%;
    z-index: 3
}

.player .controls-wrapper,
.player .video-wrapper {
    opacity: 1;
    -webkit-transition: opacity 125ms ease-out;
    transition: opacity 125ms ease-out
}

.player.player-cursor-hide {
    cursor: none
}

.player.grabbable {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.player.grabbing {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: grabbing
}

.player .player-alert {
    background: rgba(23, 35, 34, .75);
    text-align: center;
    z-index: 17;
    padding: 0 50px;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    -webkit-transition: opacity .1s, -webkit-transform .1s linear;
    transition: opacity .1s, -webkit-transform .1s linear;
    transition: transform .1s linear, opacity .1s;
    transition: transform .1s linear, opacity .1s, -webkit-transform .1s linear;
    min-height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.player .player-alert h3 {
    margin: 0;
    color: #fff;
    font-size: 1.2em
}

.player .player-alert.in {
    opacity: 1;
    -webkit-transition: opacity .1s, -webkit-transform .1s cubic-bezier(0, 1.14, .57, 1.21);
    transition: opacity .1s, -webkit-transform .1s cubic-bezier(0, 1.14, .57, 1.21);
    transition: transform .1s cubic-bezier(0, 1.14, .57, 1.21), opacity .1s;
    transition: transform .1s cubic-bezier(0, 1.14, .57, 1.21), opacity .1s, -webkit-transform .1s cubic-bezier(0, 1.14, .57, 1.21);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.player .player-alert.leaving {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
    -webkit-transition: opacity .2s linear, -webkit-transform .2s linear;
    transition: opacity .2s linear, -webkit-transform .2s linear;
    transition: transform .2s linear, opacity .2s linear;
    transition: transform .2s linear, opacity .2s linear, -webkit-transform .2s linear
}

.player .player-alert .close {
    position: absolute;
    right: 0;
    background: 0 0;
    margin: 0;
    padding: 0;
    top: 0;
    height: 100%
}

.player .player-alert .close .icon-close {
    width: 1.125em;
    height: 1.125em;
    margin: .9375em
}

.player .player-alert .close:active {
    -webkit-transform: translateY(1px);
    transform: translateY(1px)
}

.player .player-alert .warning-icon {
    fill: #e53e4f;
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
    margin-right: 2px
}

.player .player-alert-button-link {
    padding: 5px 0;
    background: 0 0;
    font-weight: 700
}

.player .player-alert-round {
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 10px
}

.player .player-alert-round.in {
    visibility: visible;
    opacity: 1
}

.player .player-alert-round.leaving {
    opacity: 0
}

.player .player-alert-round--threesixty {
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out
}

.player .player-alert-round--top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.player .player-alert-round--top.player-alert-bumpdown {
    top: 45px
}

.player .player-alert-round--mid {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.player .player-alert-mid-inner {
    color: #fff;
    font-size: 14px;
    z-index: 2;
    background: rgba(23, 35, 34, .75);
    padding: .75em 2em;
    border-radius: 3px
}

.player .compass-wrapper {
    opacity: 0;
    display: block;
    width: 40px;
    height: 40px;
    z-index: 7;
    cursor: pointer;
    -webkit-transition: opacity .2s ease-in-out, fill .2s ease-in-out;
    transition: opacity .2s ease-in-out, fill .2s ease-in-out;
    position: absolute;
    top: 49%;
    right: 1em
}

.player .controls,
.player .intro-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.player .compass-wrapper .compass-background {
    fill: rgba(23, 35, 34, .75)
}

.player .compass-wrapper .compass-ring {
    stroke: #fff
}

.player .compass-wrapper .compass-centercircle,
.player .compass-wrapper .compass-dimple,
.player .compass-wrapper .compass-slice {
    fill: #fff
}

.player .compass-wrapper .compass-line {
    stroke: #ccc;
    stroke-width: 1;
    fill: none
}

.player .compass-wrapper.in {
    opacity: 1
}

.player .compass-wrapper.fade {
    opacity: .35
}

.player .compass-wrapper.leaving {
    opacity: 0
}

.player .compass-wrapper:hover {
    opacity: 1!important
}

.player .compass-wrapper:hover .compass-background {
    fill: rgba(0, 0, 0, .8)
}

.player.no-fullscreen-api-support.no-playbar .controls .play-bar button:not(.toggle):hover .fill,
.player.no-fullscreen-support.no-playbar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.player .compass-wrapper.align-bottom {
    top: auto;
    bottom: 50px
}

.player .intro-wrap {
    background: rgba(23, 35, 34, .75);
    border-radius: 5px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    max-width: 250px
}

.player .intro-wrap.text-only {
    border-radius: 3px;
    font-size: 14px;
    padding: .8571428571em .9285714286em
}

.player .intro-wrap .key-wrapper {
    text-align: center;
    margin-bottom: .5em
}

.player .intro-wrap .key-row {
    text-align: center
}

.player .intro-wrap .key {
    border: 1px solid #fff;
    border-radius: 2px;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    margin: .2em;
    position: relative
}

.player .intro-wrap .arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.player .intro-wrap .arrow::before {
    box-sizing: border-box;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    border-color: #fff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: relative
}

.player .intro-wrap .arrow.arrow-top::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 2px
}

.player .intro-wrap .arrow.arrow-right::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -2px
}

.player .intro-wrap .arrow.arrow-down::before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -2px
}

.player .intro-wrap .arrow.arrow-left::before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    left: 2px
}

.player.no-fullscreen-api-support.no-playbar .captions.with-controls,
.player.no-fullscreen-support.no-playbar .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .captions.with-controls,
.player:not(:-webkit-full-screen).no-playbar .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .captions.with-controls,
.player:not(:-moz-full-screen).no-playbar .captions.with-controls {
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .captions.with-controls,
.player:not(:-ms-fullscreen).no-playbar .captions.with-controls {
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .captions.with-controls,
.player:not(:fullscreen).no-playbar .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.player .captions {
    text-align: center;
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 10px 10px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 250ms ease-out .2s;
    transition: -webkit-transform 250ms ease-out .2s;
    transition: transform 250ms ease-out .2s;
    transition: transform 250ms ease-out .2s, -webkit-transform 250ms ease-out .2s
}

.player .overlay-wrapper .window-wrapper.error,
.player .stats-debug {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text
}

.player .captions.with-controls {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transition: -webkit-transform 150ms ease;
    transition: -webkit-transform 150ms ease;
    transition: transform 150ms ease;
    transition: transform 150ms ease, -webkit-transform 150ms ease
}

.player .captions>span {
    display: inline-block;
    background: rgba(23, 35, 34, .75);
    border-radius: 5px;
    padding: .4em .5em .5em;
    margin: 10px 0 0;
    font-weight: 700;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased
}

.player .captions .bar {
    font-style: normal
}

.js-player-fullscreen:-webkit-full-screen .captions {
    padding: 1.5%;
    font-size: 10px!important
}

.js-player-fullscreen:-moz-full-screen .captions {
    padding: 1.5%;
    font-size: 10px!important
}

.js-player-fullscreen:-ms-fullscreen .captions {
    padding: 1.5%;
    font-size: 10px!important
}

.js-player-fullscreen:fullscreen .captions {
    padding: 1.5%;
    font-size: 10px!important
}

@media (min-height:223px) {
    .js-player-fullscreen:-webkit-full-screen .captions {
        font-size: 4.5vh!important
    }
    .js-player-fullscreen:-moz-full-screen .captions {
        font-size: 4.5vh!important
    }
    .js-player-fullscreen:-ms-fullscreen .captions {
        font-size: 4.5vh!important
    }
    .js-player-fullscreen:fullscreen .captions {
        font-size: 4.5vh!important
    }
}

.player.no-fullscreen-api-support.mobile.trailer .play,
.player.no-fullscreen-support.mobile.trailer .play {
    font-size: .8em
}

.player .focus-dummy {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0
}

.player.no-fullscreen-api-support.no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.player.no-fullscreen-api-support.no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.player.no-fullscreen-api-support.no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.player.no-fullscreen-api-support.no-playbar .controls .airplay,
.player.no-fullscreen-api-support.no-playbar .controls .effect,
.player.no-fullscreen-api-support.no-playbar .controls .hd,
.player.no-fullscreen-api-support.no-playbar .controls .logo,
.player.no-fullscreen-api-support.no-playbar .controls .progress,
.player.no-fullscreen-api-support.no-playbar .controls .stereoscopic,
.player.no-fullscreen-api-support.no-playbar .controls .volume {
    display: none
}

.player.no-fullscreen-api-support.mobile.trailer {
    margin-bottom: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls .play,
.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.player.no-fullscreen-api-support.no-playbar:not(.mobile) .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.player.no-fullscreen-support.no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.player.no-fullscreen-support.no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.player.no-fullscreen-support.no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.player.no-fullscreen-support.no-playbar .controls .airplay,
.player.no-fullscreen-support.no-playbar .controls .effect,
.player.no-fullscreen-support.no-playbar .controls .hd,
.player.no-fullscreen-support.no-playbar .controls .logo,
.player.no-fullscreen-support.no-playbar .controls .progress,
.player.no-fullscreen-support.no-playbar .controls .stereoscopic,
.player.no-fullscreen-support.no-playbar .controls .volume {
    display: none
}

.player.no-fullscreen-support.mobile.trailer {
    margin-bottom: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.player.no-fullscreen-support.no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.player.no-fullscreen-support.no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.player.no-fullscreen-support.no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.player.no-fullscreen-support.no-playbar:not(.mobile) .controls .play,
.player.no-fullscreen-support.no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.player.no-fullscreen-support.no-playbar:not(.mobile) .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .play-bar,
.player:not(:-webkit-full-screen).no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .play-bar,
.player:not(:-moz-full-screen).no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    align-items: flex-end
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .play-bar,
.player:not(:-ms-fullscreen).no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -ms-flex-align: end;
    align-items: flex-end
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .play-bar,
.player:not(:fullscreen).no-playbar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .play-bar button,
.player:not(:-webkit-full-screen).no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .play-bar button,
.player:not(:-moz-full-screen).no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .play-bar button,
.player:not(:-ms-fullscreen).no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .play-bar button,
.player:not(:fullscreen).no-playbar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-webkit-full-screen).no-playbar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-moz-full-screen).no-playbar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-ms-fullscreen).no-playbar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:fullscreen).no-playbar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .play-bar .cc,
.player:not(:-webkit-full-screen).no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .play-bar .cc,
.player:not(:-moz-full-screen).no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .play-bar .cc,
.player:not(:-ms-fullscreen).no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .play-bar .cc,
.player:not(:fullscreen).no-playbar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .airplay,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .effect,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .hd,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .logo,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .progress,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .stereoscopic,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar .controls .volume,
.player:not(:-webkit-full-screen).no-playbar .controls .airplay,
.player:not(:-webkit-full-screen).no-playbar .controls .effect,
.player:not(:-webkit-full-screen).no-playbar .controls .hd,
.player:not(:-webkit-full-screen).no-playbar .controls .logo,
.player:not(:-webkit-full-screen).no-playbar .controls .progress,
.player:not(:-webkit-full-screen).no-playbar .controls .stereoscopic,
.player:not(:-webkit-full-screen).no-playbar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .airplay,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .effect,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .hd,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .logo,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .progress,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .stereoscopic,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar .controls .volume,
.player:not(:-moz-full-screen).no-playbar .controls .airplay,
.player:not(:-moz-full-screen).no-playbar .controls .effect,
.player:not(:-moz-full-screen).no-playbar .controls .hd,
.player:not(:-moz-full-screen).no-playbar .controls .logo,
.player:not(:-moz-full-screen).no-playbar .controls .progress,
.player:not(:-moz-full-screen).no-playbar .controls .stereoscopic,
.player:not(:-moz-full-screen).no-playbar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .airplay,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .effect,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .hd,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .logo,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .progress,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .stereoscopic,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar .controls .volume,
.player:not(:-ms-fullscreen).no-playbar .controls .airplay,
.player:not(:-ms-fullscreen).no-playbar .controls .effect,
.player:not(:-ms-fullscreen).no-playbar .controls .hd,
.player:not(:-ms-fullscreen).no-playbar .controls .logo,
.player:not(:-ms-fullscreen).no-playbar .controls .progress,
.player:not(:-ms-fullscreen).no-playbar .controls .stereoscopic,
.player:not(:-ms-fullscreen).no-playbar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .airplay,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .effect,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .hd,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .logo,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .progress,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .stereoscopic,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar .controls .volume,
.player:not(:fullscreen).no-playbar .controls .airplay,
.player:not(:fullscreen).no-playbar .controls .effect,
.player:not(:fullscreen).no-playbar .controls .hd,
.player:not(:fullscreen).no-playbar .controls .logo,
.player:not(:fullscreen).no-playbar .controls .progress,
.player:not(:fullscreen).no-playbar .controls .stereoscopic,
.player:not(:fullscreen).no-playbar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.mobile.trailer,
.player:not(:-webkit-full-screen).mobile.trailer {
    margin-bottom: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start
}

.js-player-fullscreen:not(:-moz-full-screen) .player.mobile.trailer,
.player:not(:-moz-full-screen).mobile.trailer {
    margin-bottom: 0;
    justify-content: flex-start
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.mobile.trailer,
.player:not(:-ms-fullscreen).mobile.trailer {
    margin-bottom: 0;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.js-player-fullscreen:not(:fullscreen) .player.mobile.trailer,
.player:not(:fullscreen).mobile.trailer {
    margin-bottom: 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.mobile.trailer .play,
.player:not(:-webkit-full-screen).mobile.trailer .play {
    font-size: .8em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.mobile.trailer .play,
.player:not(:-moz-full-screen).mobile.trailer .play {
    font-size: .8em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.mobile.trailer .play,
.player:not(:-ms-fullscreen).mobile.trailer .play {
    font-size: .8em
}

.js-player-fullscreen:not(:fullscreen) .player.mobile.trailer .play,
.player:not(:fullscreen).mobile.trailer .play {
    font-size: .8em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls {
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls .play,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls .play,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls .play,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls .play,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls .play .tiny-bars,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls .play .tiny-bars,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls .play .tiny-bars,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls .play .tiny-bars,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls .play,
.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls .play,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls .play,
.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls .play,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls .play,
.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls .play,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls .play,
.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls .play,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-webkit-full-screen).no-playbar:not(.mobile) .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-moz-full-screen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-moz-full-screen).no-playbar:not(.mobile) .controls .play-bar {
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:-ms-fullscreen).no-playbar:not(.mobile) .controls .play-bar {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:fullscreen) .player.no-playbar:not(.mobile) .controls .play-bar,
.player:not(:fullscreen).no-playbar:not(.mobile) .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.player.background-mode {
    pointer-events: none!important
}

.player.background-mode .controls-wrapper {
    display: none!important
}

.player.background-mode video::-webkit-media-controls-start-playback-button {
    display: none
}

.player.with-sticky-custom-logo .controls.hidden {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
}

.player.with-sticky-custom-logo .controls.hidden .play,
.player.with-sticky-custom-logo .controls.hidden .play-bar {
    visibility: hidden
}

.player .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    opacity: 1;
    z-index: 9;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.player .controls>* {
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out
}

.player .controls.invisible>* {
    opacity: 0
}

.player .controls.trailer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 7;
    height: auto!important
}

@media screen and (min-width:960px) {
    .player .controls.trailer {
        font-size: 1.2em
    }
}

.player .controls.trailer .play {
    margin-right: 0;
    margin-bottom: 2em
}

.player .controls.trailer .logo {
    margin: -1.2em 0 0;
    z-index: 2
}

.player .controls.trailer .logo svg {
    width: 5.8em;
    height: 1.8em;
    -webkit-backface-visibility: hidden;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, .25));
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, .25))
}

.player .controls.trailer .custom-logo {
    position: absolute;
    bottom: 1em;
    right: 1em
}

.player .controls .play {
    width: 6.5em;
    height: 4em;
    margin-right: .9em;
    color: #fff;
    position: relative;
    -webkit-transition: opacity 250ms ease-out, background-color 40ms, color 40ms;
    transition: opacity 250ms ease-out, background-color 40ms, color 40ms
}

.player .controls .play.state-paused .pause-icon {
    display: none
}

.player .controls .play.state-paused .play-icon,
.player .controls .play.state-playing .pause-icon {
    display: block
}

.player .controls .play.state-playing .play-icon,
.player .controls .play:hover .tiny-bars {
    display: none
}

.player .controls .play.trailer {
    font-weight: 700;
    width: auto;
    padding: 0 1em
}

.player .controls .play.trailer div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.player .controls .play.trailer .play-icon {
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.player .controls .play.trailer svg {
    width: 1.4em;
    height: 1.4em
}

.player .controls .play.trailer p {
    font-size: 1.4em;
    margin: 0 0 0 .5714285714em;
    padding: 0
}

.player .controls .play .tiny-bars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    border-radius: .5em;
    background-repeat: none
}

.player .controls .play .tiny-bars .buffer {
    -webkit-animation: buffer 250ms linear infinite;
    animation: buffer 250ms linear infinite
}

.player .controls .pause-icon,
.player .controls .play-icon {
    margin: 0 auto;
    padding: .2em 0 0 .2em
}

.player .controls .pause-icon svg,
.player .controls .play-icon svg {
    width: 2em;
    height: 2em
}

.player .controls .play-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
    height: 3.2em;
    padding: 1em;
    border-radius: 2px
}

.player .controls .play-bar svg {
    display: block
}

.player .controls .play-bar button {
    background: 0 0
}

.player .controls .play-bar button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.player .controls .ghost-timecode,
.player .controls .timecode {
    position: absolute;
    left: 0;
    bottom: 1.4em;
    margin: -2em 0 0 -1.2em
}

.player .controls .ghost-timecode .box,
.player .controls .timecode .box {
    padding: .2em .4em;
    font: 1em Verdana, sans-serif;
    position: relative;
    left: -50%
}

.player .controls .ghost-timecode .box::after,
.player .controls .ghost-timecode .box::before,
.player .controls .timecode .box::after,
.player .controls .timecode .box::before {
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid #fff;
    bottom: -.3em;
    content: "";
    left: 50%;
    margin-left: -.3em;
    position: absolute
}

.player .controls .ghost-timecode .box::before,
.player .controls .timecode .box::before {
    border-top-color: rgba(0, 0, 0, .7);
    margin-left: -.2em;
    bottom: -.4em
}

.player .controls .thumb-preview {
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out;
    position: absolute;
    display: inline-block;
    bottom: 3.3em;
    border-radius: .2em;
    padding: .4em;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(23, 35, 34, .75)
}

.player .controls .thumb-preview::after {
    content: "";
    width: 100%;
    height: .3em;
    position: absolute;
    display: block;
    bottom: -.3em
}

.player .controls .thumb-preview .thumb {
    position: relative
}

.player .controls .timecode .box {
    box-shadow: .1em .1em 0 rgba(0, 0, 0, .7);
    background: #fff;
    color: #000;
    display: inline-block;
    margin-left: 2.3em;
    cursor: -webkit-grab;
    cursor: grab
}

.player .controls .ghost-timecode {
    margin-left: 0;
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out
}

.player .controls .ghost-timecode .box {
    background: rgba(23, 35, 34, .75);
    color: #fff
}

.player .controls .ghost-timecode .box::before {
    display: none
}

.player .controls .ghost-timecode .box::after {
    border-top-color: rgba(23, 35, 34, .75)
}

.player .controls .progress {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: .1em solid #666;
    cursor: pointer;
    position: relative
}

.player .controls .progress .buffer,
.player .controls .progress .buffer canvas,
.player .controls .progress .buffer svg,
.player .controls .progress .cuepoints,
.player .controls .progress .loaded,
.player .controls .progress .played {
    position: absolute;
    border: .1em solid transparent;
    height: 100%;
    background-clip: padding-box!important
}

.player .controls .progress .loaded {
    background-color: #666;
    -webkit-transition: width 175ms cubic-bezier(.18, 0, .07, 1);
    transition: width 175ms cubic-bezier(.18, 0, .07, 1)
}

.player .controls .progress .loaded.short-video {
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.player .controls .progress .buffer {
    width: 100%;
    overflow: hidden;
    border-top: 0;
    border-bottom: 0
}

.player .controls .progress .buffer svg {
    -webkit-animation: buffer 250ms linear infinite;
    animation: buffer 250ms linear infinite
}

.player .controls .progress .cuepoints {
    width: 100%
}

.player .controls .progress .cuepoint {
    height: 100%;
    margin: 0;
    padding: 0 2px;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 150ms ease-out, -webkit-transform 150ms ease-in-out;
    transition: opacity 150ms ease-out, -webkit-transform 150ms ease-in-out;
    transition: transform 150ms ease-in-out, opacity 150ms ease-out;
    transition: transform 150ms ease-in-out, opacity 150ms ease-out, -webkit-transform 150ms ease-in-out;
    opacity: .5;
    -webkit-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1)
}

.player .controls .progress .cuepoint.active {
    opacity: 1;
    -webkit-transform: translateX(-50%) scale(1.5);
    transform: translateX(-50%) scale(1.5)
}

.player .controls .progress .cuepoint.out {
    -webkit-transform: translateX(-50%) scale(.75);
    transform: translateX(-50%) scale(.75)
}

.player .controls .progress .cuepoint>div {
    background-color: #fff;
    height: 100%;
    width: 2px
}

@media (-webkit-min-device-pixel-ratio:2),
(min-resolution:2dppx) {
    .player .controls .progress .cuepoint>div {
        width: 1.5px
    }
}

.player .controls .volume {
    width: 2.4em;
    height: 100%;
    margin-left: 1.1em;
    position: relative;
    cursor: pointer
}

.player .controls .volume>div {
    width: .3em;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0
}

.player .controls .volume>div.animate {
    -webkit-transition: height 250ms ease-in;
    transition: height 250ms ease-in
}

.player .controls .volume>div.fill0 {
    background-color: #666
}

.player .controls .volume>div.fill1 {
    box-shadow: inset -.2em 0 0 #666
}

.player .controls .volume>div.fill2 {
    box-shadow: inset -.1em 0 0 #666
}

.player .controls .volume>div:nth-child(1) {
    height: .42em;
    left: 0
}

.player .controls .volume>div:nth-child(1).hover,
.player .controls .volume>div:nth-child(1):hover {
    height: .72em
}

.player .controls .volume>div:nth-child(2) {
    height: .615em;
    left: .5em
}

.player .controls .volume>div:nth-child(2).hover,
.player .controls .volume>div:nth-child(2):hover {
    height: .915em
}

.player .controls .volume>div:nth-child(3) {
    height: .81em;
    left: 1em
}

.player .controls .volume>div:nth-child(3).hover,
.player .controls .volume>div:nth-child(3):hover {
    height: 1.11em
}

.player .controls .volume>div:nth-child(4) {
    height: 1.005em;
    left: 1.5em
}

.player .controls .volume>div:nth-child(4).hover,
.player .controls .volume>div:nth-child(4):hover {
    height: 1.305em
}

.player .controls .volume>div:nth-child(5) {
    height: 1.2em;
    left: 2em
}

.player .controls .volume>div:nth-child(5).hover,
.player .controls .volume>div:nth-child(5):hover {
    height: 1.5em
}

.player .controls .cc {
    width: 2em;
    height: 1.4em;
    margin-left: 1em;
    margin-top: -.1em
}

.player .controls .hd {
    width: 2.6em;
    height: 100%;
    margin-left: .9em;
    margin-right: -.1em
}

.player .controls .effect {
    width: 1.2em;
    height: 100%;
    margin-left: 1em
}

.player .controls .effect circle {
    opacity: .85;
    mix-blend-mode: lighten
}

.player .controls .effect .blue {
    opacity: 1
}

.player .controls .effect.off circle {
    opacity: .75
}

.player .controls .effect.off .red {
    fill: grey
}

.player .controls .effect.off .green {
    fill: #a9a9a9
}

.player .controls .effect.off .blue {
    fill: #ccc
}

.player .controls .airplay,
.player .controls .stereoscopic {
    width: 1.5em;
    height: 100%;
    margin-left: 1em
}

.player .controls .pip {
    width: 1.6em;
    height: 100%;
    margin-left: 1em
}

.player .controls .pip .pip-icon .return,
.player .controls .pip.return .pip-icon .enter {
    display: none
}

.player .controls .pip.return .pip-icon .return {
    display: block
}

.player .controls .fullscreen {
    width: 1.2em;
    height: 100%;
    margin-left: 1em
}

.player .controls .fullscreen .fullscreen-icon,
.player .controls .fullscreen .unfullscreen-icon {
    height: 100%
}

.player .controls .logo {
    width: 5.8em;
    height: 1.8em;
    margin: -.5em .2em 0 .8em
}

@media (min-width:300px) and (max-width:414px) {
    .player .controls .logo {
        overflow: hidden;
        width: 1.5em
    }
    .player .controls .logo svg {
        width: 6.4em;
        height: 2em;
        margin-top: -.2em
    }
    .player .controls .logo path:not(.logo-v) {
        display: none
    }
    .player .controls .logo canvas {
        width: 6.4em;
        height: 1.9em
    }
}

.player .controls .custom-logo {
    margin-left: 1em
}

.player .controls .custom-logo.sticky {
    opacity: 1!important;
    visibility: visible!important
}

.player .controls .custom-logo a {
    display: block
}

.player .controls .custom-logo img {
    max-width: 100%;
    max-height: 100%
}

.player .controls .fill {
    -webkit-transition: fill 40ms;
    transition: fill 40ms
}

.player .unfullscreen-icon {
    display: none
}

.player.no-fullscreen-support .fullscreen {
    display: none!important
}

@media screen and (min-width:1061px) and (min-width:961px) {
    .js-player-fullscreen:-webkit-full-screen .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:-moz-full-screen .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:-ms-fullscreen .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:fullscreen .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
}

@media screen and (min-width:1061px) {
    .js-player-fullscreen:-webkit-full-screen .custom-logo {
        position: fixed;
        bottom: 1em;
        right: 1em
    }
    .js-player-fullscreen:-moz-full-screen .custom-logo {
        position: fixed;
        bottom: 1em;
        right: 1em
    }
    .js-player-fullscreen:-ms-fullscreen .custom-logo {
        position: fixed;
        bottom: 1em;
        right: 1em
    }
    .js-player-fullscreen:fullscreen .custom-logo {
        position: fixed;
        bottom: 1em;
        right: 1em
    }
}

@media (min-width:961px) {
    .js-player-fullscreen:-webkit-full-screen .player:not(.with-custom-logo) .controls,
    .js-player-fullscreen:-webkit-full-screen:not(.with-custom-logo) .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:-moz-full-screen .player:not(.with-custom-logo) .controls,
    .js-player-fullscreen:-moz-full-screen:not(.with-custom-logo) .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:-ms-fullscreen .player:not(.with-custom-logo) .controls,
    .js-player-fullscreen:-ms-fullscreen:not(.with-custom-logo) .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
    .js-player-fullscreen:fullscreen .player:not(.with-custom-logo) .controls,
    .js-player-fullscreen:fullscreen:not(.with-custom-logo) .controls {
        margin: auto;
        width: 60%;
        min-width: 960px;
        max-width: 1024px
    }
}

.js-player-fullscreen:-webkit-full-screen .player:not(.with-custom-logo) .custom-logo,
.js-player-fullscreen:-webkit-full-screen:not(.with-custom-logo) .custom-logo {
    position: fixed;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:-moz-full-screen .player:not(.with-custom-logo) .custom-logo,
.js-player-fullscreen:-moz-full-screen:not(.with-custom-logo) .custom-logo {
    position: fixed;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:-ms-fullscreen .player:not(.with-custom-logo) .custom-logo,
.js-player-fullscreen:-ms-fullscreen:not(.with-custom-logo) .custom-logo {
    position: fixed;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:fullscreen .player:not(.with-custom-logo) .custom-logo,
.js-player-fullscreen:fullscreen:not(.with-custom-logo) .custom-logo {
    position: fixed;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:-webkit-full-screen .play-bar>button:hover {
    background: 0 0!important
}

.js-player-fullscreen:-moz-full-screen .play-bar>button:hover {
    background: 0 0!important
}

.js-player-fullscreen:-ms-fullscreen .play-bar>button:hover {
    background: 0 0!important
}

.js-player-fullscreen:fullscreen .play-bar>button:hover {
    background: 0 0!important
}

.js-player-fullscreen:-webkit-full-screen .fullscreen-icon {
    display: none
}

.js-player-fullscreen:-moz-full-screen .fullscreen-icon {
    display: none
}

.js-player-fullscreen:-ms-fullscreen .fullscreen-icon {
    display: none
}

.js-player-fullscreen:fullscreen .fullscreen-icon {
    display: none
}

.js-player-fullscreen:-webkit-full-screen .unfullscreen-icon {
    display: block
}

.js-player-fullscreen:-moz-full-screen .unfullscreen-icon {
    display: block
}

.js-player-fullscreen:-ms-fullscreen .unfullscreen-icon {
    display: block
}

.js-player-fullscreen:fullscreen .unfullscreen-icon {
    display: block
}

.player[data-filter] .thumb::after,
.player[data-filter] .video::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 1;
    background: -webkit-linear-gradient(left, transparent, transparent), -webkit-radial-gradient(circle, transparent, transparent);
    background: linear-gradient(to right, transparent, transparent), radial-gradient(circle, transparent, transparent)
}

.player[data-filter=aden] .thumb,
.player[data-filter=aden] .video {
    -webkit-filter: brightness(1.2) contrast(.9) grayscale(0) hue-rotate(-20deg) saturate(.85) sepia(0);
    filter: brightness(1.2) contrast(.9) grayscale(0) hue-rotate(-20deg) saturate(.85) sepia(0)
}

.player[data-filter=aden] .thumb::after,
.player[data-filter=aden] .video::after {
    background: -webkit-linear-gradient(left, rgba(66, 10, 14, .2), transparent), -webkit-radial-gradient(circle, transparent, transparent);
    background: linear-gradient(to right, rgba(66, 10, 14, .2), transparent), radial-gradient(circle, transparent, transparent);
    mix-blend-mode: darken
}

.player[data-filter=earlybird] .thumb,
.player[data-filter=earlybird] .video {
    -webkit-filter: brightness(1) contrast(.9) grayscale(0) hue-rotate(0) saturate(1) sepia(.2);
    filter: brightness(1) contrast(.9) grayscale(0) hue-rotate(0) saturate(1) sepia(.2)
}

.player[data-filter=earlybird] .thumb::after,
.player[data-filter=earlybird] .video::after {
    background: -webkit-linear-gradient(left, transparent, transparent), -webkit-radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
    background: linear-gradient(to right, transparent, transparent), radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
    mix-blend-mode: overlay
}

.player[data-filter=hudson] .thumb,
.player[data-filter=hudson] .video {
    -webkit-filter: brightness(1.2) contrast(.9) grayscale(0) hue-rotate(0) saturate(1.1) sepia(0);
    filter: brightness(1.2) contrast(.9) grayscale(0) hue-rotate(0) saturate(1.1) sepia(0)
}

.player[data-filter=hudson] .thumb::after,
.player[data-filter=hudson] .video::after {
    background: -webkit-linear-gradient(left, transparent, transparent), -webkit-radial-gradient(circle, #a6b1ff 50%, #342134);
    background: linear-gradient(to right, transparent, transparent), radial-gradient(circle, #a6b1ff 50%, #342134);
    mix-blend-mode: multiply;
    opacity: .5
}

.player[data-filter=inkwell] .thumb,
.player[data-filter=inkwell] .video {
    -webkit-filter: brightness(1.1) contrast(1.1) grayscale(1) hue-rotate(0) saturate(1) sepia(0);
    filter: brightness(1.1) contrast(1.1) grayscale(1) hue-rotate(0) saturate(1) sepia(0)
}

.player[data-filter=inkwell] .thumb::after,
.player[data-filter=inkwell] .video::after {
    opacity: 0
}

.player[data-filter=mayfair] .thumb,
.player[data-filter=mayfair] .video {
    -webkit-filter: brightness(1) contrast(1.1) grayscale(0) hue-rotate(0) saturate(1.1) sepia(0);
    filter: brightness(1) contrast(1.1) grayscale(0) hue-rotate(0) saturate(1.1) sepia(0)
}

.player[data-filter=mayfair] .thumb::after,
.player[data-filter=mayfair] .video::after {
    background: -webkit-linear-gradient(left, transparent, transparent), -webkit-radial-gradient(40% 40%, circle, rgba(255, 255, 255, .8), rgba(255, 200, 200, .6), #111 60%);
    background: linear-gradient(to right, transparent, transparent), radial-gradient(circle at 40% 40%, rgba(255, 255, 255, .8), rgba(255, 200, 200, .6), #111 60%);
    mix-blend-mode: overlay;
    opacity: .4
}

.player .outro .videos a:focus header,
.player .outro .videos li:hover header {
    opacity: 1
}

.player[data-filter=toaster] .thumb,
.player[data-filter=toaster] .video {
    -webkit-filter: brightness(.9) contrast(1.5) grayscale(0) hue-rotate(0) saturate(1) sepia(0);
    filter: brightness(.9) contrast(1.5) grayscale(0) hue-rotate(0) saturate(1) sepia(0)
}

.player[data-filter=toaster] .thumb::after,
.player[data-filter=toaster] .video::after {
    background: -webkit-linear-gradient(left, transparent, transparent), -webkit-radial-gradient(circle, #804e0f, #3b003b);
    background: linear-gradient(to right, transparent, transparent), radial-gradient(circle, #804e0f, #3b003b);
    mix-blend-mode: screen
}

.player[data-filter=ascii] .thumb {
    -webkit-filter: brightness(1) contrast(2) grayscale(0) hue-rotate(0) saturate(1) sepia(0);
    filter: brightness(1) contrast(2) grayscale(0) hue-rotate(0) saturate(1) sepia(0)
}

.player[data-filter=ascii] .thumb::after {
    background-color: #0f0;
    mix-blend-mode: darken
}

.player[data-filter=ascii] .captions {
    -webkit-transition: none;
    transition: none
}

.player[data-filter=ascii] .captions>span {
    font-family: monospace;
    color: #0f0;
    border-radius: 0;
    background: #000;
    padding: 0 .5em
}

.player .menu {
    position: absolute;
    padding: .5em 0;
    margin: 0 0 .7em;
    z-index: 13;
    -webkit-transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: opacity 150ms ease-out, transform 150ms ease-out;
    transition: opacity 150ms ease-out, transform 150ms ease-out, -webkit-transform 150ms ease-out;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    background: rgba(23, 35, 34, .9);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

.player .menu.open {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.player .menu::after {
    position: absolute;
    bottom: -.5em;
    left: 50%;
    margin-left: -.5em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-top: .5em solid rgba(23, 35, 34, .9);
    content: ""
}

.player .menu ul {
    padding: 0;
    margin: 0
}

.player .menu li {
    list-style-type: none;
    padding: 0 .5em;
    margin: .25em .5833333333em .25em .4166666667em;
    font-size: 1.2em;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap
}

.player .menu .scroll-end .sticky-items li,
.player .menu .scroll-off .sticky-items li {
    margin-top: 0
}

.player .menu li::before {
    display: inline-block;
    width: .5em;
    height: .5em;
    border-radius: 6px;
    border: 1px solid #666;
    content: ""
}

.player .menu li svg {
    display: inline-block!important;
    margin-left: .3333333333em;
    width: 1em;
    height: 1em
}

.player .menu li svg .fill {
    fill: #898f8f
}

.player .overlay-wrapper nav .fill,
.player .sidedock .box[data-vod-expiring="1"] .vod-icon .fill,
.player.no-fullscreen-api-support.in-minibar .controls .play-bar button:not(.toggle):hover .fill,
.player.no-fullscreen-support.in-minibar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.player .menu span {
    padding-left: 5px
}

.player .menu .item-container {
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

.player .menu .scroll-off .scrollable-items {
    overflow-y: hidden
}

.player .menu .scroll-off .sticky-items {
    -webkit-transition: box-shadow 0s, border-top 0s;
    transition: box-shadow 0s, border-top 0s;
    box-shadow: 0 0 0 0 transparent;
    border-top: 0 solid transparent
}

.player .menu .scroll-off .top-shadow {
    -webkit-transition: box-shadow 0s, border-top 0s;
    transition: box-shadow 0s, border-top 0s;
    box-shadow: 0 0 0 0 transparent
}

.player .menu .scrollable-items {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow-y: scroll;
    overflow-x: hidden
}

.player .menu .sticky-items {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 0;
    box-shadow: 5px -10px 15px 0 rgba(23, 35, 34, .75);
    border-top: 1px solid #444;
    -webkit-transition: box-shadow .1s, border-top .1s;
    transition: box-shadow .1s, border-top .1s
}

.player .menu .top-shadow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 1px;
    box-shadow: 5px 0 15px 10px rgba(23, 35, 34, .75);
    -webkit-transition: box-shadow .1s;
    transition: box-shadow .1s
}

.player .menu .scroll-start .top-shadow {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow .2s, border-top .2s;
    transition: box-shadow .2s, border-top .2s
}

.player .menu .scroll-end .sticky-items {
    box-shadow: 0 0 0 0 transparent;
    border-top: 0 solid rgba(68, 68, 68, 0);
    -webkit-transition: box-shadow .2s, border-top .2s;
    transition: box-shadow .2s, border-top .2s
}

.player .notification-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: table;
    text-align: center
}

.player .notification-wrapper.animate {
    -webkit-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out
}

.player .notification-wrapper svg {
    width: 100%;
    height: 100%
}

.player .notification-wrapper .watch-later-icon .stroke {
    stroke-width: 1.65
}

.player .notification-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%
}

.player .notification {
    z-index: 16;
    margin: 0 auto;
    -webkit-backface-visibility: hidden;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, .25));
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, .25))
}

.player .notification.animate .hour-hand,
.player .notification.animate .minute-hand {
    -webkit-transition: -webkit-transform .9s linear .1s;
    transition: -webkit-transform .9s linear .1s;
    transition: transform .9s linear .1s;
    transition: transform .9s linear .1s, -webkit-transform .9s linear .1s
}

.player .stats-debug {
    position: absolute;
    z-index: 18;
    width: 300px;
    padding: 10px;
    margin: 10px 0 0 10px;
    user-select: text;
    font-size: 1.2em;
    line-height: 1
}

.player .stats-debug.overflow {
    overflow: scroll;
    max-height: 100%
}

.player .stats-debug p {
    margin: .1em 0
}

.player .stats-debug p:first-child {
    margin-top: 0
}

.player .stats-debug-label {
    margin-right: 3px
}

.player .stats-debug-value {
    font-weight: 700;
    letter-spacing: .02em;
    color: #fff;
    position: relative
}

.player .stats-debug-bandwidth-minmax {
    font-size: .8em;
    display: block;
    font-weight: 400;
    margin-top: 2px
}

.player .stats-debug-bandwidth-max,
.player .stats-debug-bandwidth-min {
    padding-left: 10px
}

.player .stats-debug-bandwidth-max::before,
.player .stats-debug-bandwidth-min::before {
    display: block;
    position: absolute;
    left: 2px;
    font-size: .8em;
    top: 3px
}

.player .stats-debug-bandwidth-min::before {
    content: "▼"
}

.player .stats-debug-bandwidth-max::before {
    content: "▲"
}

.player .stats-debug-copy {
    background: #00adef;
    color: #fff!important;
    padding: 3px;
    border-radius: 3px;
    margin-top: 5px;
    display: inline-block
}

.player .stats-debug-copy:hover {
    color: #fff
}

.player .stats-debug-code {
    position: absolute;
    left: -999px
}

.player .stats-debug-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: 0 0
}

.player .stats-debug-close svg {
    width: 1.2em;
    height: 1.2em
}

.player .outro-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    -webkit-transition: background .2s;
    transition: background .2s
}

.player .outro-wrapper.in {
    background: rgba(0, 0, 0, .8)
}

.player .outro-wrapper.in .outro {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1
}

.player .outro-wrapper.in .outro .video-section.promoted {
    background-color: rgba(24, 24, 24, .75)
}

.player .outro {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, -webkit-transform .2s;
    transition: transform .2s, opacity .2s;
    transition: transform .2s, opacity .2s, -webkit-transform .2s;
    -webkit-transform: scale(.9, .9);
    transform: scale(.9, .9)
}

.player .outro[data-type=videos] {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.player .outro[data-type=image]>div {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center
}

.player .outro a[role=button] {
    display: inline-block;
    color: #fff;
    font-weight: 700;
    font-size: 1.6em;
    padding: .625em 1.25em;
    margin: 0 .3125em;
    border-radius: 5px;
    cursor: pointer
}

.player .outro h1 {
    color: #fff;
    font-size: 2em;
    margin: 0 3.1em .5em
}

@media screen and (max-width:450px) {
    .player .outro h1 {
        font-size: 1.6em
    }
}

@media screen and (min-width:550px) {
    .player .outro h1 {
        font-size: 2.4em
    }
}

@media screen and (min-width:768px) {
    .player .outro h1 {
        font-size: 3em
    }
}

.player .outro>p {
    font-size: 1.5em
}

.player .outro .outro-image {
    max-width: 65%
}

.player .outro .text-wrapper {
    text-align: left;
    padding: 6em;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.player .outro .text-wrapper .text {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 1.6em;
    line-height: 1.4
}

@media (max-width:500px) {
    .player .outro .text-wrapper .text {
        font-size: 1.4em
    }
}

@media (min-width:780px) {
    .player .outro .text-wrapper .text {
        width: 640px;
        margin: 0 auto
    }
}

.player .outro .text-wrapper img {
    max-width: 100%
}

.player .outro .text-wrapper a {
    font-weight: 700
}

.player .outro .text-wrapper ul {
    padding-left: 1.8em
}

.player .outro .video-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 4em 0 6em;
    text-align: center
}

.player .outro .video-section:first-child {
    padding-left: 5.6em
}

.player .outro .video-section:last-child {
    padding-right: 5.6em
}

.player .outro .video-section[data-videos="2"] {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2
}

.player .outro .video-section[data-videos="3"] {
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3
}

.player .outro .video-section.promoted {
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    background-color: transparent
}

.player .outro .video-section>div {
    width: 100%
}

.player .outro .video-section>div>h1 {
    margin: 0 0 .8em;
    color: #898f8f;
    font-size: 1em;
    line-height: normal
}

.player .outro .video-section>div>h1 a {
    color: #fff;
    display: block;
    font-size: 1.3846153846em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

@media screen and (min-width:500px) {
    .player .outro .video-section>div>h1 {
        font-size: 1.2em
    }
}

@media screen and (min-width:640px) {
    .player .outro .video-section>div>h1 {
        font-size: 1.3em
    }
}

@media screen and (min-width:900px) {
    .player .outro .video-section>div>h1 {
        font-size: 1.5em
    }
}

.player .outro .videos {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0
}

.player .outro .videos li {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    list-style-type: none;
    padding: 0;
    margin: .4em;
    max-width: 313px;
    overflow: hidden
}

.player .outro .videos a {
    display: block;
    position: relative;
    width: 100%
}

.player .outro .videos .img-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0
}

.player .outro .videos img {
    -webkit-transition: border 50ms, background 50ms;
    transition: border 50ms, background 50ms;
    padding: .2em;
    border: 1px solid #444;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@media screen and (min-width:500px) {
    .player .outro .videos img {
        padding: .3em
    }
}

@media screen and (min-width:640px) {
    .player .outro .videos li {
        margin: 0 .8em
    }
    .player .outro .videos img {
        padding: .4em
    }
}

.player .outro .videos .header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: .9em .9em 1.1em
}

@media screen and (max-width:639px) {
    .player .outro .videos .header-wrapper {
        padding: .6em .6em .8em
    }
}

.player .outro .videos header {
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 50ms;
    transition: opacity 50ms;
    text-align: left;
    overflow: hidden
}

@media screen and (max-width:499px) {
    .player .outro .videos header {
        display: none!important
    }
}

.player .outro .videos header h1,
.player .outro .videos header h2 {
    background: rgba(23, 35, 34, .75);
    display: inline-block
}

.player .outro .videos header h1 {
    font-size: 1.3em;
    font-weight: 700;
    padding: .2em .4em;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-line-clamp: 2;
    max-height: 2.8em;
    text-overflow: -o-ellipsis-lastline
}

@media screen and (max-width:640px) {
    .player .outro .videos header h1 {
        font-size: 1.2em;
        padding: .1em .3em
    }
}

@media screen and (min-width:900px) {
    .player .outro .videos header h1 {
        font-size: 1.7em
    }
}

.player .outro .videos header h2 {
    font-size: 1.1em;
    margin: .1em 0;
    padding: .2em .5em;
    clear: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.player .outro .videos header h2 span {
    color: #fff
}

@media screen and (max-width:639px) {
    .player .outro .videos header h2 {
        font-size: 1em;
        padding: .1em .3em
    }
}

@media screen and (min-width:960px) {
    .player .outro .videos header h2 {
        font-size: 1.2em
    }
}

.player .outro .vod-header {
    font-size: 3.2em
}

.player .outro .vod-header a {
    color: #fff
}

@media screen and (max-width:639px) {
    .player .outro .vod-header {
        font-size: 2.8em
    }
}

@media screen and (max-width:499px) {
    .player .outro .vod-header {
        font-size: 2.4em
    }
}

@media screen and (max-height:200px) {
    .player .outro .vod-header {
        font-size: 2.4em
    }
}

.player .outro .vod {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 10px
}

.player .outro .vod li {
    display: inline-block;
    list-style-type: none;
    margin: 0 .5em;
    border-radius: 5px
}

.player .outro .vod a[role=button] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
    padding: .6em .8em;
    margin: 0;
    background-color: transparent
}

.player .outro .vod a[role=button]:active {
    background-color: rgba(0, 0, 0, .15)
}

@media screen and (max-height:200px) {
    .player .outro .vod a[role=button] {
        font-size: 9px
    }
}

.player .outro .vod .icon {
    margin-right: .8em
}

.player .outro .vod svg {
    margin-top: .125em;
    width: 1.375em;
    height: 1.375em
}

.player .outro .vod p {
    margin: 0;
    padding: 0
}

.player .outro .vod-wrapper {
    text-align: center
}

.player .outro .vod-wrapper>p {
    margin-top: 1.4em;
    font-size: 1.4em
}

.player .overlay-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%
}

.player .overlay-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    display: table;
    text-align: center;
    -webkit-transition: background .2s;
    transition: background .2s
}

.player .overlay-wrapper.modal nav {
    display: none
}

.player .overlay-wrapper.in {
    background: rgba(0, 0, 0, .8)
}

.player .overlay-wrapper.in nav {
    opacity: 1
}

.player .overlay-wrapper.out nav {
    opacity: 0
}

.player .overlay-wrapper h1 {
    font-size: 20px;
    color: #fff;
    margin: 0 auto
}

@media (min-width:380px) and (min-height:214px) and (min-aspect-ratio:800 / 450) {
    .player .overlay-wrapper h1 {
        font-size: 9.3333333333vh
    }
}

@media (min-width:380px) and (min-height:214px) and (max-aspect-ratio:800 / 450) {
    .player .overlay-wrapper h1 {
        font-size: 5.25vw
    }
}

@media (min-width:800px) and (min-height:450px) {
    .player .overlay-wrapper h1 {
        font-size: 42px
    }
}

.player .overlay-wrapper p {
    font-size: 12px;
    color: #c9c9c9;
    text-shadow: 1px 1px 2px rgba(23, 35, 34, .75);
    margin: 0
}

@media (min-width:480px) and (min-height:270px) and (min-aspect-ratio:800 / 450) {
    .player .overlay-wrapper p {
        font-size: 4.4444444444vh
    }
}

@media (min-width:480px) and (min-height:270px) and (max-aspect-ratio:800 / 450) {
    .player .overlay-wrapper p {
        font-size: 2.5vw
    }
}

@media (min-width:800px) and (min-height:450px) {
    .player .overlay-wrapper p {
        font-size: 20px
    }
}

.player .overlay-wrapper p.subtitle {
    margin: .25em 0 .9375em
}

.player .overlay-wrapper p.account {
    font-size: 1.3em;
    margin-top: .7142857143em
}

@media (max-width:414px),
(max-height:168px) {
    .player .overlay-wrapper p.account {
        display: none
    }
}

.player .overlay-wrapper .window-wrapper {
    padding: 0 4em;
    display: inline-block
}

.player .overlay-wrapper .window-wrapper.unlocked h1 {
    max-width: 800px
}

.player .overlay-wrapper .window-wrapper.error {
    user-select: text
}

.player .overlay-wrapper a[role=button],
.player .overlay-wrapper button,
.player .overlay-wrapper input {
    font-size: 12px;
    display: inline-block;
    color: #fff;
    font-weight: 700;
    padding: .625em 1.25em;
    margin: 0 .3125em;
    border-radius: 5px
}

@media (min-width:480px) and (min-height:270px) and (min-aspect-ratio:800 / 450) {
    .player .overlay-wrapper a[role=button],
    .player .overlay-wrapper button,
    .player .overlay-wrapper input {
        font-size: 4.4444444444vh
    }
}

@media (min-width:480px) and (min-height:270px) and (max-aspect-ratio:800 / 450) {
    .player .overlay-wrapper a[role=button],
    .player .overlay-wrapper button,
    .player .overlay-wrapper input {
        font-size: 2.5vw
    }
}

@media (min-width:800px) and (min-height:450px) {
    .player .overlay-wrapper a[role=button],
    .player .overlay-wrapper button,
    .player .overlay-wrapper input {
        font-size: 20px
    }
}

.player .overlay-wrapper a[role=button],
.player .overlay-wrapper button,
.player .overlay-wrapper input[type=submit] {
    cursor: pointer
}

.player .overlay-wrapper input:not([type=submit]) {
    padding: .625em
}

.player .overlay-wrapper .popup {
    display: inline-block;
    max-width: 250px;
    font-size: 1.4em;
    padding: .7142857143em 1.4285714286em
}

.player .overlay-wrapper .form a {
    font-weight: 700
}

.player .overlay-wrapper .form form {
    position: relative
}

@media (max-width:414px),
(max-height:168px) {
    .player .overlay-wrapper .form form {
        display: none
    }
}

.player .overlay-wrapper .form input:not([type=submit]) {
    border-radius: 2px;
    width: 9em;
    color: #444
}

.player .overlay-wrapper .form input:not([type=submit])::-webkit-input-placeholder {
    color: #a9a9a9
}

.player .overlay-wrapper .form input:not([type=submit])::-moz-placeholder {
    color: #a9a9a9
}

.player .overlay-wrapper .form input:not([type=submit]):-ms-input-placeholder {
    color: #a9a9a9
}

.player .overlay-wrapper .form input:not([type=submit])::placeholder {
    color: #a9a9a9
}

.player .overlay-wrapper .form.password input[type=submit],
.player .overlay-wrapper .form.unlocked button {
    width: auto
}

.player .overlay-wrapper .form .loading {
    -webkit-animation: throb 1s linear infinite;
    animation: throb 1s linear infinite
}

.player .overlay-wrapper nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

.player .overlay-wrapper nav button {
    font-size: 12px;
    background: 0 0;
    margin: 0;
    padding: 0
}

@media (min-width:480px) and (min-height:270px) and (min-aspect-ratio:640 / 360) {
    .player .overlay-wrapper nav button {
        font-size: 4.4444444444vh
    }
}

@media (min-width:480px) and (min-height:270px) and (max-aspect-ratio:640 / 360) {
    .player .overlay-wrapper nav button {
        font-size: 2.5vw
    }
}

@media (min-width:640px) and (min-height:360px) {
    .player .overlay-wrapper nav button {
        font-size: 16px
    }
}

.player .overlay-wrapper nav .icon-close {
    width: 1.125em;
    height: 1.125em;
    margin: .9375em
}

.player .overlay-wrapper nav .icon-back {
    width: 1em;
    height: 1em;
    margin: 1em
}

.player .overlay-wrapper nav,
.player .overlay-wrapper nav .back {
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, -webkit-transform .2s;
    transition: transform .2s, opacity .2s;
    transition: transform .2s, opacity .2s, -webkit-transform .2s;
    opacity: 0
}

.player .overlay-wrapper nav .back {
    position: absolute;
    left: 0;
    -webkit-transform: translateX(1.5em);
    transform: translateX(1.5em)
}

.player .overlay-wrapper nav .close {
    position: absolute;
    right: 0
}

.player .overlay-wrapper nav .close:active {
    -webkit-transform: translateY(1px);
    transform: translateY(1px)
}

.player .overlay-wrapper .footnote {
    font-weight: 700;
    margin-top: .5em;
    line-height: 1.8em
}

.player .overlay-wrapper .footnote a,
.player .overlay-wrapper .footnote button {
    margin: 0 .25em
}

.player .overlay-wrapper .footnote.share a {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    color: #fff
}

.player .overlay-wrapper .footnote a.clip-url {
    position: relative;
    top: .1em
}

.player .overlay-wrapper .footnote small a {
    margin: 0
}

.player .overlay-wrapper .help {
    color: #fff;
    width: 100%;
    display: block
}

.player .overlay-wrapper .help dl {
    font-size: 10px;
    min-width: 300px;
    max-width: 700px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin: .5em auto;
    text-align: left
}

@media (min-width:400px) and (min-height:225px) and (min-aspect-ratio:640 / 360) {
    .player .overlay-wrapper .help dl {
        font-size: 4.4444444444vh
    }
}

@media (min-width:400px) and (min-height:225px) and (max-aspect-ratio:640 / 360) {
    .player .overlay-wrapper .help dl {
        font-size: 2.5vw
    }
}

@media (min-width:640px) and (min-height:360px) {
    .player .overlay-wrapper .help dl {
        font-size: 16px
    }
}

.player .overlay-wrapper .help dl>div {
    margin: .625em 0
}

.player .overlay-wrapper .help .secondary {
    display: none
}

@media (min-width:501px) {
    .player .overlay-wrapper .help dl {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3
    }
    .player .overlay-wrapper .help dl dt {
        border-width: 2px
    }
    .player .overlay-wrapper .help .secondary {
        display: block
    }
}

.player .overlay-wrapper .help dt {
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px;
    width: 2em;
    height: 2em;
    font-family: -apple-system, Helvetica, Arial, sans-serif;
    font-size: 1.25em;
    line-height: 1.5;
    text-align: center
}

.player .overlay-wrapper .help .arrow {
    line-height: 1.25
}

.player .overlay-wrapper .help dd {
    display: inline-block;
    margin: 0 .625em;
    font-size: .875em
}

.player .overlay-wrapper .help .scrub-backwards,
.player .overlay-wrapper .help .toggle-captions {
    -webkit-column-break-after: always;
    page-break-after: always;
    break-after: always
}

@media (max-width:414px),
(max-height:168px) {
    .player .overlay-wrapper[data-name=password] form {
        display: block
    }
}

@media (max-width:316px) {
    .player .overlay-wrapper[data-name=password] input:not([type=submit]) {
        margin-bottom: 10px
    }
}

.player .overlay-wrapper .share-wrapper {
    position: relative;
    display: inline-block;
    min-width: 30em;
    max-width: 80%
}

.player .overlay-wrapper .share-wrapper h1 {
    color: #fff;
    margin: 0 auto;
    line-height: 1
}

.player .overlay-wrapper .share-wrapper section {
    -webkit-transition: .2s;
    transition: .2s
}

.player .overlay-wrapper .buttons {
    font-size: 6px;
    color: #fff;
    display: inline-block;
    margin: 1em .5em 0;
    padding: 0
}

@media (min-width:426px) and (min-height:240px) and (min-aspect-ratio:640 / 360) {
    .player .overlay-wrapper .buttons {
        font-size: 2.5vh
    }
}

@media (min-width:426px) and (min-height:240px) and (max-aspect-ratio:640 / 360) {
    .player .overlay-wrapper .buttons {
        font-size: 1.40625vw
    }
}

@media (min-width:640px) and (min-height:360px) {
    .player .overlay-wrapper .buttons {
        font-size: 9px
    }
}

.player .overlay-wrapper .buttons li {
    display: inline-block;
    list-style-type: none;
    border-left: 1px solid rgba(0, 0, 0, .14);
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, .14)
}

.player .overlay-wrapper .buttons li a:active {
    background-color: rgba(0, 0, 0, .15);
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, .29)
}

.player .overlay-wrapper .buttons li:active {
    border-left: 1px solid rgba(255, 255, 255, .14)
}

.player .overlay-wrapper .buttons li:active+li {
    border-left: 1px solid rgba(0, 0, 0, .36)
}

.player .overlay-wrapper .buttons li:first-child,
.player .overlay-wrapper .buttons li:first-child a {
    border-left: 0!important;
    box-shadow: none!important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.player .overlay-wrapper .buttons li:last-child,
.player .overlay-wrapper .buttons li:last-child a {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}

.player .overlay-wrapper .buttons li a {
    display: inline-block;
    padding: .9375em .9375em .6875em;
    margin: 0;
    line-height: 1;
    color: #fff;
    border-radius: 0
}

.player .overlay-wrapper .buttons li .embed-icon,
.player .overlay-wrapper .buttons li svg {
    width: 1.9375em;
    height: 1.8125em
}

.player .overlay-wrapper .embed-code {
    overflow: hidden;
    margin: 1em auto 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 85%
}

.player .overlay-wrapper .embed-code>div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.player .overlay-wrapper .embed-code input[type=text] {
    font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
    color: #898f8f;
    font-weight: 400;
    cursor: text;
    margin: 0;
    padding: .6875em;
    width: 100%
}

.player .overlay-wrapper .embed-code button {
    padding: .625em 1em;
    margin: 0 0 0 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.player .overlay-wrapper .embed-wrapper {
    width: 100%;
    max-width: 80vw;
    min-width: 300px;
    margin: 0 auto
}

.player .overlay-wrapper .share-screen {
    width: 100%
}

.player .overlay-wrapper .embed-screen {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 150%;
    -webkit-transform: translateX(125%);
    transform: translateX(125%)
}

.player .overlay-wrapper .embed-screen .subtitle {
    margin-top: 10px;
    margin-left: 1em;
    margin-right: 1em
}

.player .overlay-wrapper .embed-screen .footnote {
    font-weight: 400
}

.player .overlay-wrapper .embed-screen .footnote a {
    font-weight: 700;
    margin: 0
}

@media (max-height:175px) {
    .player .overlay-wrapper .embed-screen .subtitle {
        display: none
    }
}

.player .overlay-wrapper.embed-active .share-screen,
.player .overlay-wrapper.embed-only .share-screen {
    -webkit-transform: translateX(-125%);
    transform: translateX(-125%);
    opacity: 0
}

.player .overlay-wrapper.embed-active .embed-screen,
.player .overlay-wrapper.embed-only .embed-screen {
    -webkit-transform: translateX(-17%);
    transform: translateX(-17%);
    opacity: 1
}

.player .overlay-wrapper.embed-active nav .back {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.player .overlay-wrapper.embed-active nav .back:active {
    -webkit-transform: translateY(1px);
    transform: translateY(1px)
}

.player .overlay-wrapper[data-name=email-capture] .email-capture {
    max-width: 600px
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-form {
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-confirm {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    -webkit-transition: opacity .2s ease-out, -webkit-transform .2s cubic-bezier(0, 0, .51, 1.15);
    transition: opacity .2s ease-out, -webkit-transform .2s cubic-bezier(0, 0, .51, 1.15);
    transition: transform .2s cubic-bezier(0, 0, .51, 1.15), opacity .2s ease-out;
    transition: transform .2s cubic-bezier(0, 0, .51, 1.15), opacity .2s ease-out, -webkit-transform .2s cubic-bezier(0, 0, .51, 1.15);
    opacity: 0
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-confirm.in {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-confirm .check-icon-wrapper {
    font-size: 20px;
    display: block;
    margin: 0 auto;
    width: 1em;
    height: 1em;
    border-radius: 100%
}

@media (min-width:492px) and (min-height:276px) and (min-aspect-ratio:640 / 360) {
    .player .overlay-wrapper[data-name=email-capture] .email-capture-confirm .check-icon-wrapper {
        font-size: 7.2222222222vh
    }
}

@media (min-width:492px) and (min-height:276px) and (max-aspect-ratio:640 / 360) {
    .player .overlay-wrapper[data-name=email-capture] .email-capture-confirm .check-icon-wrapper {
        font-size: 4.0625vw
    }
}

@media (min-width:640px) and (min-height:360px) {
    .player .overlay-wrapper[data-name=email-capture] .email-capture-confirm .check-icon-wrapper {
        font-size: 26px
    }
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-confirm .check-icon {
    margin: .2307692308em;
    width: .5em;
    height: .5769230769em
}

.player .overlay-wrapper[data-name=email-capture] .email-capture-confirm h1 {
    margin-top: .5em
}

.player .overlay-wrapper[data-name=email-capture] h1 {
    font-size: 16px
}

@media (min-width:465px) and (min-height:261px) and (min-aspect-ratio:640 / 360) {
    .player .overlay-wrapper[data-name=email-capture] h1 {
        font-size: 6.1111111111vh
    }
}

@media (min-width:465px) and (min-height:261px) and (max-aspect-ratio:640 / 360) {
    .player .overlay-wrapper[data-name=email-capture] h1 {
        font-size: 3.4375vw
    }
}

@media (min-width:640px) and (min-height:360px) {
    .player .overlay-wrapper[data-name=email-capture] h1 {
        font-size: 22px
    }
}

.player .overlay-wrapper[data-name=email-capture] form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.player .overlay-wrapper[data-name=email-capture] input[type] {
    margin: .3125em auto;
    width: 17.1875em
}

.player .overlay-wrapper .form .submitted input:invalid,
.player .overlay-wrapper .form .submitted input[aria-invalid=true] {
    border: 3px solid #fdb0bf;
    background-color: #fedfef;
    color: #e53e4f;
    padding: .4375em
}

.player .overlay-wrapper .form .submitted input:focus:invalid {
    outline-color: rgba(255, 0, 0, .75)
}

.player .overlay-wrapper .form .validation-bubble {
    z-index: 2147483647;
    display: inline-block;
    position: absolute;
    margin: 0;
    font-size: 1.1666666667em;
    line-height: 0;
    text-align: left;
    opacity: 0;
    -webkit-transition: opacity .25s;
    transition: opacity .25s;
    cursor: default
}

.player .overlay-wrapper .form .validation-bubble.animate {
    opacity: 1;
    -webkit-animation: wiggle .3s linear 1;
    animation: wiggle .3s linear 1
}

.player .overlay-wrapper .form .validation-bubble-message {
    z-index: 2147483644;
    display: block;
    min-width: 50px;
    max-width: 300px;
    padding: .7142857143em 1.0714285714em;
    position: relative;
    top: -4px;
    border-radius: .3571428571em;
    background-color: #fc465a;
    color: #fff;
    line-height: normal;
    font-weight: 700
}

.player .overlay-wrapper .form .validation-bubble-arrow {
    z-index: 2147483645;
    display: inline-block;
    position: relative;
    left: 1.4285714286em;
    width: .8571428571em;
    height: .8571428571em;
    background-color: #fc465a;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
}

.player .overlay-wrapper .form .validation-bubble-arrow-clipper {
    display: block;
    overflow: hidden;
    height: .8571428571em
}

.player .overlay-wrapper[data-name=app-redirect] {
    background: -webkit-linear-gradient(top, transparent, #000 100%), rgba(0, 0, 0, .5);
    background: linear-gradient(180deg, transparent, #000 100%), rgba(0, 0, 0, .5)
}

.player .overlay-wrapper[data-name=app-redirect] .app-redirect--topspace {
    margin-top: 1.7em
}

.player .overlay-wrapper[data-name=app-redirect] .app-redirect-title {
    font-size: 1.7em;
    padding-bottom: 1em
}

.player .overlay-wrapper[data-name=app-redirect] .app-redirect-button {
    font-size: 18px
}

@media (min-width:600px) and (min-height:337px) and (min-aspect-ratio:800 / 450) {
    .player .overlay-wrapper[data-name=app-redirect] .app-redirect-button {
        font-size: 5.3333333333vh
    }
}

@media (min-width:600px) and (min-height:337px) and (max-aspect-ratio:800 / 450) {
    .player .overlay-wrapper[data-name=app-redirect] .app-redirect-button {
        font-size: 3vw
    }
}

@media (min-width:800px) and (min-height:450px) {
    .player .overlay-wrapper[data-name=app-redirect] .app-redirect-button {
        font-size: 24px
    }
}

.player .overlay-wrapper[data-name=app-redirect] .app-redirect-ignore {
    text-decoration: underline;
    cursor: pointer;
    padding-top: 1em;
    font-size: 1.6em
}

.player .overlay-wrapper[data-name=app-redirect] .app-redirect-bottom-text {
    padding-top: 1.6em;
    font-size: 1.6em
}

.player .overlay {
    z-index: 15
}

.player .overlay,
.player .overlay-cell {
    opacity: 0;
    position: relative;
    -webkit-transform: scale(.9, .9);
    transform: scale(.9, .9)
}

.player .overlay-cell.in,
.player .overlay.in {
    opacity: 1;
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, -webkit-transform .2s;
    transition: transform .2s, opacity .2s;
    transition: transform .2s, opacity .2s, -webkit-transform .2s;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
}

.player .overlay-cell.out,
.player .overlay.out {
    -webkit-transition: opacity 105ms, -webkit-transform 105ms;
    transition: opacity 105ms, -webkit-transform 105ms;
    transition: transform 105ms, opacity 105ms;
    transition: transform 105ms, opacity 105ms, -webkit-transform 105ms
}

.player .overlay-icon-wrapper {
    position: absolute;
    left: 50%;
    width: 20em;
    height: 20em;
    margin-left: -10em;
    z-index: 14
}

@media (max-width:414px),
(max-height:168px) {
    .player .overlay-icon-wrapper {
        display: none
    }
}

.player .overlay-icon-wrapper[data-icon=lock] {
    width: 14em;
    height: 22.4em;
    margin-left: -7em
}

.player .overlay-icon-wrapper.animate {
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out
}

.player .overlay-icon {
    width: 20em;
    height: 20em;
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out;
    -webkit-transform: scale(.25);
    transform: scale(.25);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.player .overlay-icon[data-icon=lock] {
    height: 22.4em;
    width: 14em
}

.player .overlay-icon[data-icon=lock].open .bolt {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    -webkit-transition: -webkit-transform .15s;
    transition: -webkit-transform .15s;
    transition: transform .15s;
    transition: transform .15s, -webkit-transform .15s
}

.player .overlay-icon svg {
    width: 100%;
    height: 100%
}

.player .overlay-icon.centered {
    -webkit-transition: .1s ease-out;
    transition: .1s ease-out
}

.player .overlay-icon.pulled-back {
    -webkit-transform: scale(.2);
    transform: scale(.2)
}

.player .overlay-icon.out {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.player .overlay-logo {
    width: 100%;
    height: 2.9em;
    margin: 0 auto;
    position: absolute;
    z-index: -2934875273462
}

.player .overlay-logo.animate {
    opacity: 0;
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out;
    -webkit-transform: scale(.25);
    transform: scale(.25)
}

.player .overlay-logo svg {
    width: 100%;
    height: 100%
}

@media (max-height:269px) {
    .player .overlay-cell::after {
        content: "icon-hidden";
        display: block;
        position: relative;
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden
    }
    .player .overlay-icon-wrapper,
    .player .overlay-logo {
        display: none
    }
    .player .overlay-icon-wrapper::after,
    .player .overlay-logo::after {
        content: "hidden";
        display: block;
        position: relative;
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden
    }
}

.player .overlay-icon,
.player .overlay-logo {
    opacity: .75
}

.player .sidedock {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 8
}

.player .sidedock,
.player .sidedock .sidedock-inner {
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out
}

.player .sidedock .box {
    margin: 0 0 .5em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: -webkit-transform 150ms ease-out;
    transition: -webkit-transform 150ms ease-out;
    transition: transform 150ms ease-out;
    transition: transform 150ms ease-out, -webkit-transform 150ms ease-out
}

.player .sidedock .box:nth-child(3) {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

.player .sidedock .box:nth-child(4) {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.player .sidedock .box[data-vod-expiring="1"] .vod-button {
    background: rgba(188, 23, 30, .75)
}

.player .sidedock.vod .sidedock-inner .box {
    opacity: 0;
    -webkit-transform: translateX(46px);
    transform: translateX(46px)
}

.player .sidedock.vod .box[data-vod-purchased="1"][data-vod-expiring="1"] .vod-button {
    -webkit-animation: pulse 1s infinite;
    animation: pulse 1s infinite
}

.player .sidedock button {
    width: 3.6em;
    height: 3.6em;
    -webkit-transition: background-color 40ms;
    transition: background-color 40ms
}

.player .sidedock label {
    padding: 0 1em;
    margin: 0 .7em 0 0;
    line-height: 3.6em;
    -webkit-transform: translateX(5px);
    transform: translateX(5px)
}

.player .sidedock label.visible {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.player .sidedock label::after {
    position: absolute;
    right: -.5em;
    top: 50%;
    margin-top: -.5em;
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-left: .5em solid rgba(23, 35, 34, .75);
    content: ""
}

.player.no-fullscreen-api-support.in-minibar::after,
.player.no-fullscreen-support.in-minibar::after,
.player.no-playbar::after {
    content: "tiny"
}

.player .sidedock .vod-label,
.player .sidedock label {
    position: relative;
    height: 3.6em;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    -webkit-transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
    transition: opacity 150ms ease-out, transform 150ms ease-out;
    transition: opacity 150ms ease-out, transform 150ms ease-out, -webkit-transform 150ms ease-out
}

.player .sidedock .vod-label,
.player .sidedock label span {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 2.5714285714
}

.player .sidedock .vod-label {
    height: 100%;
    margin-left: .7em
}

.player .sidedock svg {
    margin: 0 auto;
    display: block
}

.player .sidedock .vod-button-inner,
.player .title header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.player .sidedock .vod-button {
    width: auto
}

.player .sidedock .vod-button-inner {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%
}

.player .sidedock .vod-icon {
    width: 2.4em;
    height: 100%;
    margin: 0 .7em
}

.player .sidedock .like-icon {
    width: 2.2em;
    height: 2em
}

.player .sidedock .watch-later-icon {
    width: 2.4em;
    height: 2.4em
}

.player .sidedock .share-icon {
    width: 2.3em;
    height: 2em
}

.player .sidedock .collections-icon {
    width: 2em;
    height: 2em
}

.player .sidedock .fill {
    -webkit-transition: fill 40ms;
    transition: fill 40ms
}

.player .sidedock .stroke {
    -webkit-transition: stroke 40ms;
    transition: stroke 40ms
}

.js-player-fullscreen:-webkit-full-screen .sidedock {
    font-size: 11px
}

.js-player-fullscreen:-moz-full-screen .sidedock {
    font-size: 11px
}

.js-player-fullscreen:-ms-fullscreen .sidedock {
    font-size: 11px
}

.js-player-fullscreen:fullscreen .sidedock {
    font-size: 11px
}

.player.no-fullscreen-api-support.mobile .title .badge,
.player.no-fullscreen-api-support.mobile .title .portrait,
.player.no-fullscreen-support.mobile .title .badge,
.player.no-fullscreen-support.mobile .title .portrait {
    display: none
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.mobile .title .badge,
.js-player-fullscreen:not(:-webkit-full-screen) .player.mobile .title .portrait,
.player:not(:-webkit-full-screen).mobile .title .badge,
.player:not(:-webkit-full-screen).mobile .title .portrait {
    display: none
}

.js-player-fullscreen:not(:-moz-full-screen) .player.mobile .title .badge,
.js-player-fullscreen:not(:-moz-full-screen) .player.mobile .title .portrait,
.player:not(:-moz-full-screen).mobile .title .badge,
.player:not(:-moz-full-screen).mobile .title .portrait {
    display: none
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.mobile .title .badge,
.js-player-fullscreen:not(:-ms-fullscreen) .player.mobile .title .portrait,
.player:not(:-ms-fullscreen).mobile .title .badge,
.player:not(:-ms-fullscreen).mobile .title .portrait {
    display: none
}

.js-player-fullscreen:not(:fullscreen) .player.mobile .title .badge,
.js-player-fullscreen:not(:fullscreen) .player.mobile .title .portrait,
.player:not(:fullscreen).mobile .title .badge,
.player:not(:fullscreen).mobile .title .portrait {
    display: none
}

.player.fullscreen .title {
    margin-right: 4.1818181818em
}

.player .title {
    position: relative;
    padding: 10px;
    z-index: 5;
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out;
    margin-right: 4.6em
}

.player .title header {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap
}

.player .title header .headers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    min-width: 0;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto
}

.player .title header h1,
.player .title header h2 {
    background: rgba(23, 35, 34, .75);
    display: inline-block;
    text-transform: none;
    line-height: normal;
    letter-spacing: normal
}

.player .title header h1 {
    max-width: 100%;
    font-size: 2em!important;
    font-weight: 700;
    margin: 0;
    padding: .1em .2em
}

.player .title header h1 a {
    font-weight: 700
}

.player .title header h2 {
    font-size: 1.2em!important;
    font-weight: 400;
    color: #fff;
    margin-top: .1em;
    padding: .2em .5em
}

.player .title header h2 .user,
.player .title header h2 a {
    font-weight: 700
}

.player .title header h2 .byline-badge {
    color: #fff;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 2px 3px 1px;
    vertical-align: 1px;
    font-family: Helvetica, Arial!important;
    font-size: .7em;
    font-weight: 400
}

.player .title header h2 .byline-badge.plus {
    background-color: #17ade4;
    color: #073a4c
}

.player .title header h2 .byline-badge.pro {
    background-color: #9ba8b8;
    color: #34373d
}

.player .title header h2 .byline-badge.business {
    background-color: #74b300;
    color: #263b00
}

.player .title .badge {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 1em
}

@media (max-width:414px),
(max-height:168px) {
    .player .title .badge {
        display: none
    }
}

.player .title .badge.shadow img {
    -webkit-filter: drop-shadow(0 2px 5px rgba(23, 35, 34, .25));
    filter: drop-shadow(0 2px 5px rgba(23, 35, 34, .25))
}

.player .title .portrait {
    background: rgba(23, 35, 34, .75);
    width: 6em;
    height: 6em;
    margin-right: 1px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

.player .title .portrait img {
    border: 0;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    padding: .5em
}

.player .video-wrapper,
.player .video-wrapper .telecine,
.player .video-wrapper object,
.player .video-wrapper video {
    width: 100%;
    height: 100%
}

.player .title .threesix-badge-title {
    color: #fff;
    background: rgba(255, 255, 255, .15);
    vertical-align: middle;
    font-weight: 700;
    font-size: .6em;
    padding: .2em .4em;
    display: inline-block;
    margin-left: .5em;
    margin-top: -.2em
}

.player .title .threesix-badge-byline,
.player .title .threesix-badge-loner {
    display: inline-block;
    background: rgba(23, 35, 34, .75);
    padding: .2em .4em;
    font-size: 1.2em;
    font-weight: 700
}

.player .title .threesix-badge-byline {
    margin-left: -2px
}

.js-player-fullscreen:-webkit-full-screen .title {
    font-size: 11px
}

.js-player-fullscreen:-moz-full-screen .title {
    font-size: 11px
}

.js-player-fullscreen:-ms-fullscreen .title {
    font-size: 11px
}

.js-player-fullscreen:fullscreen .title {
    font-size: 11px
}

.player .video-wrapper {
    position: absolute;
    background: #000
}

.player .video-wrapper.native-controls {
    z-index: 10
}

.player .video-wrapper .snapshot {
    position: absolute;
    z-index: 1
}

.player .video-wrapper .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-transition: -webkit-filter .25s;
    transition: -webkit-filter .25s;
    transition: filter .25s;
    transition: filter .25s, -webkit-filter .25s
}

.player .video-wrapper .video.cover {
    background-size: cover
}

.player .video-wrapper .video::after {
    -webkit-transition: all .25s;
    transition: all .25s
}

.player .video-wrapper .telecine {
    -webkit-transition: background-color .1s ease-in;
    transition: background-color .1s ease-in
}

.player .video-wrapper .telecine.transition {
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out
}

.player .video-wrapper .telecine.cloaked video {
    opacity: 0
}

.player .video-wrapper .telecine.hide-webkit-controls video::--webkit-media-controls-play-button {
    display: none
}

.player .video-wrapper .telecine.hide-webkit-controls video::-webkit-media-controls-play-button {
    display: none
}

.player .video-wrapper .telecine.hide-webkit-controls video::-webkit-media-controls-start-playback-button {
    display: none
}

.player .video-wrapper pre {
    font-family: monospace!important
}

.js-player-fullscreen:-webkit-full-screen .video-wrapper {
    background: 0 0!important
}

.js-player-fullscreen:-moz-full-screen .video-wrapper {
    background: 0 0!important
}

.js-player-fullscreen:-ms-fullscreen .video-wrapper {
    background: 0 0!important
}

.js-player-fullscreen:fullscreen .video-wrapper {
    background: 0 0!important
}

.player.no-fullscreen-api-support.in-minibar .custom-logo,
.player.no-fullscreen-api-support.in-minibar .outro-wrapper,
.player.no-fullscreen-api-support.in-minibar .sidedock,
.player.no-fullscreen-api-support.in-minibar .title {
    display: none!important
}

.player.no-fullscreen-api-support.in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.player.no-fullscreen-api-support.in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.player.no-fullscreen-api-support.in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.player.no-fullscreen-api-support.in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.player.no-fullscreen-api-support.in-minibar .controls .airplay,
.player.no-fullscreen-api-support.in-minibar .controls .effect,
.player.no-fullscreen-api-support.in-minibar .controls .hd,
.player.no-fullscreen-api-support.in-minibar .controls .logo,
.player.no-fullscreen-api-support.in-minibar .controls .progress,
.player.no-fullscreen-api-support.in-minibar .controls .stereoscopic,
.player.no-fullscreen-api-support.in-minibar .controls .volume {
    display: none
}

.player.no-fullscreen-api-support.in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.player.no-fullscreen-api-support.in-minibar .controls .play .tiny-bars {
    display: block
}

.player.no-fullscreen-api-support.in-minibar .controls .play,
.player.no-fullscreen-api-support.in-minibar .controls .play-bar {
    z-index: 7
}

.player.no-fullscreen-api-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-api-support.in-minibar:not(:-webkit-full-screen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.player.no-fullscreen-api-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-api-support.in-minibar:not(:-moz-full-screen) .captions.with-controls {
    transform: translateY(-32px)
}

.player.no-fullscreen-api-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-api-support.in-minibar:not(:-ms-fullscreen) .captions.with-controls {
    transform: translateY(-32px)
}

.player.no-fullscreen-api-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-api-support.in-minibar:not(:fullscreen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.player.no-fullscreen-support.in-minibar .custom-logo,
.player.no-fullscreen-support.in-minibar .outro-wrapper,
.player.no-fullscreen-support.in-minibar .sidedock,
.player.no-fullscreen-support.in-minibar .title {
    display: none!important
}

.player.no-fullscreen-support.in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.player.no-fullscreen-support.in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.player.no-fullscreen-support.in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.player.no-fullscreen-support.in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.player.no-fullscreen-support.in-minibar .controls .airplay,
.player.no-fullscreen-support.in-minibar .controls .effect,
.player.no-fullscreen-support.in-minibar .controls .hd,
.player.no-fullscreen-support.in-minibar .controls .logo,
.player.no-fullscreen-support.in-minibar .controls .progress,
.player.no-fullscreen-support.in-minibar .controls .stereoscopic,
.player.no-fullscreen-support.in-minibar .controls .volume {
    display: none
}

.player.no-fullscreen-support.in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.player.no-fullscreen-support.in-minibar .controls .play .tiny-bars {
    display: block
}

.player.no-fullscreen-support.in-minibar .controls .play,
.player.no-fullscreen-support.in-minibar .controls .play-bar {
    z-index: 7
}

.player.no-fullscreen-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-support.in-minibar:not(:-webkit-full-screen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.player.no-fullscreen-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-support.in-minibar:not(:-moz-full-screen) .captions.with-controls {
    transform: translateY(-32px)
}

.player.no-fullscreen-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-support.in-minibar:not(:-ms-fullscreen) .captions.with-controls {
    transform: translateY(-32px)
}

.player.no-fullscreen-support.in-minibar.no-fullscreen-support .captions.with-controls,
.player.no-fullscreen-support.in-minibar:not(:fullscreen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar::after,
.player:not(:-webkit-full-screen).in-minibar::after {
    content: "tiny"
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar::after,
.player:not(:-moz-full-screen).in-minibar::after {
    content: "tiny"
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar::after,
.player:not(:-ms-fullscreen).in-minibar::after {
    content: "tiny"
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar::after,
.player:not(:fullscreen).in-minibar::after {
    content: "tiny"
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .custom-logo,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .outro-wrapper,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .sidedock,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .title,
.player:not(:-webkit-full-screen).in-minibar .custom-logo,
.player:not(:-webkit-full-screen).in-minibar .outro-wrapper,
.player:not(:-webkit-full-screen).in-minibar .sidedock,
.player:not(:-webkit-full-screen).in-minibar .title {
    display: none!important
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .custom-logo,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .outro-wrapper,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .sidedock,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .title,
.player:not(:-moz-full-screen).in-minibar .custom-logo,
.player:not(:-moz-full-screen).in-minibar .outro-wrapper,
.player:not(:-moz-full-screen).in-minibar .sidedock,
.player:not(:-moz-full-screen).in-minibar .title {
    display: none!important
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .custom-logo,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .outro-wrapper,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .sidedock,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .title,
.player:not(:-ms-fullscreen).in-minibar .custom-logo,
.player:not(:-ms-fullscreen).in-minibar .outro-wrapper,
.player:not(:-ms-fullscreen).in-minibar .sidedock,
.player:not(:-ms-fullscreen).in-minibar .title {
    display: none!important
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .custom-logo,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .outro-wrapper,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .sidedock,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .title,
.player:not(:fullscreen).in-minibar .custom-logo,
.player:not(:fullscreen).in-minibar .outro-wrapper,
.player:not(:fullscreen).in-minibar .sidedock,
.player:not(:fullscreen).in-minibar .title {
    display: none!important
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls,
.player:not(:-webkit-full-screen).in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls,
.player:not(:-moz-full-screen).in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls,
.player:not(:-ms-fullscreen).in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls,
.player:not(:fullscreen).in-minibar .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -ms-flex-align: end;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:fullscreen).in-minibar .controls .play-bar {
    background: 0;
    padding: 0;
    height: 2.2em;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: absolute;
    bottom: 1em;
    right: 1em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar button,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar button,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar button,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar button,
.player:not(:fullscreen).in-minibar .controls .play-bar button {
    box-sizing: content-box;
    background: rgba(23, 35, 34, .75);
    border-radius: .5em;
    padding: .5em;
    margin-left: .5em;
    height: 1.2em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar button:not(.toggle):hover .fill,
.player:not(:fullscreen).in-minibar .controls .play-bar button:not(.toggle):hover .fill {
    fill: #fff
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar .cc,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar .cc,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar .cc,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar .cc,
.player:not(:fullscreen).in-minibar .controls .play-bar .cc {
    padding: .4em;
    height: 1.4em
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .airplay,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .effect,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .hd,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .logo,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .progress,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .stereoscopic,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .volume,
.player:not(:-webkit-full-screen).in-minibar .controls .airplay,
.player:not(:-webkit-full-screen).in-minibar .controls .effect,
.player:not(:-webkit-full-screen).in-minibar .controls .hd,
.player:not(:-webkit-full-screen).in-minibar .controls .logo,
.player:not(:-webkit-full-screen).in-minibar .controls .progress,
.player:not(:-webkit-full-screen).in-minibar .controls .stereoscopic,
.player:not(:-webkit-full-screen).in-minibar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .airplay,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .effect,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .hd,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .logo,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .progress,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .stereoscopic,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .volume,
.player:not(:-moz-full-screen).in-minibar .controls .airplay,
.player:not(:-moz-full-screen).in-minibar .controls .effect,
.player:not(:-moz-full-screen).in-minibar .controls .hd,
.player:not(:-moz-full-screen).in-minibar .controls .logo,
.player:not(:-moz-full-screen).in-minibar .controls .progress,
.player:not(:-moz-full-screen).in-minibar .controls .stereoscopic,
.player:not(:-moz-full-screen).in-minibar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .airplay,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .effect,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .hd,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .logo,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .progress,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .stereoscopic,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .volume,
.player:not(:-ms-fullscreen).in-minibar .controls .airplay,
.player:not(:-ms-fullscreen).in-minibar .controls .effect,
.player:not(:-ms-fullscreen).in-minibar .controls .hd,
.player:not(:-ms-fullscreen).in-minibar .controls .logo,
.player:not(:-ms-fullscreen).in-minibar .controls .progress,
.player:not(:-ms-fullscreen).in-minibar .controls .stereoscopic,
.player:not(:-ms-fullscreen).in-minibar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .airplay,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .effect,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .hd,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .logo,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .progress,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .stereoscopic,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .volume,
.player:not(:fullscreen).in-minibar .controls .airplay,
.player:not(:fullscreen).in-minibar .controls .effect,
.player:not(:fullscreen).in-minibar .controls .hd,
.player:not(:fullscreen).in-minibar .controls .logo,
.player:not(:fullscreen).in-minibar .controls .progress,
.player:not(:fullscreen).in-minibar .controls .stereoscopic,
.player:not(:fullscreen).in-minibar .controls .volume {
    display: none
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play,
.player:not(:-webkit-full-screen).in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play,
.player:not(:-moz-full-screen).in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play,
.player:not(:-ms-fullscreen).in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play,
.player:not(:fullscreen).in-minibar .controls .play {
    margin: -2em 0 0 -3.25em;
    position: absolute;
    top: 50%;
    left: 50%
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play .tiny-bars,
.player:not(:-webkit-full-screen).in-minibar .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play .tiny-bars,
.player:not(:-moz-full-screen).in-minibar .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play .tiny-bars,
.player:not(:-ms-fullscreen).in-minibar .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play .tiny-bars,
.player:not(:fullscreen).in-minibar .controls .play .tiny-bars {
    display: block
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-webkit-full-screen).in-minibar .controls .play,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-moz-full-screen).in-minibar .controls .play,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:-ms-fullscreen).in-minibar .controls .play,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:fullscreen).in-minibar .controls .play,
.player:not(:fullscreen).in-minibar .controls .play-bar {
    z-index: 7
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-webkit-full-screen).in-minibar .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar .controls .play-bar,
.player:not(:-moz-full-screen).in-minibar .controls .play-bar {
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:-ms-fullscreen).in-minibar .controls .play-bar {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar .controls .play-bar,
.player:not(:fullscreen).in-minibar .controls .play-bar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar.no-fullscreen-support .captions.with-controls,
.js-player-fullscreen:not(:-webkit-full-screen) .player.in-minibar:not(:-webkit-full-screen) .captions.with-controls,
.player:not(:-webkit-full-screen).in-minibar.no-fullscreen-support .captions.with-controls,
.player:not(:-webkit-full-screen).in-minibar:not(:-webkit-full-screen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar.no-fullscreen-support .captions.with-controls,
.js-player-fullscreen:not(:-moz-full-screen) .player.in-minibar:not(:-moz-full-screen) .captions.with-controls,
.player:not(:-moz-full-screen).in-minibar.no-fullscreen-support .captions.with-controls,
.player:not(:-moz-full-screen).in-minibar:not(:-moz-full-screen) .captions.with-controls {
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar.no-fullscreen-support .captions.with-controls,
.js-player-fullscreen:not(:-ms-fullscreen) .player.in-minibar:not(:-ms-fullscreen) .captions.with-controls,
.player:not(:-ms-fullscreen).in-minibar.no-fullscreen-support .captions.with-controls,
.player:not(:-ms-fullscreen).in-minibar:not(:-ms-fullscreen) .captions.with-controls {
    transform: translateY(-32px)
}

.js-player-fullscreen:not(:fullscreen) .player.in-minibar.no-fullscreen-support .captions.with-controls,
.js-player-fullscreen:not(:fullscreen) .player.in-minibar:not(:fullscreen) .captions.with-controls,
.player:not(:fullscreen).in-minibar.no-fullscreen-support .captions.with-controls,
.player:not(:fullscreen).in-minibar:not(:fullscreen) .captions.with-controls {
    -webkit-transform: translateY(-32px);
    transform: translateY(-32px)
}

@media (max-height:119px),
(max-width:79px) {
    .player .controls .cc,
    .player .controls .fullscreen {
        display: none!important
    }
}

@media screen and (max-width:299px),
screen and (max-height:168px) {
    .player::after {
        content: "tiny"
    }
    .player .custom-logo,
    .player .outro-wrapper,
    .player .sidedock,
    .player .title {
        display: none!important
    }
    .player .controls {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        z-index: auto;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
    .player .controls .play-bar {
        background: 0;
        padding: 0;
        height: 2.2em;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        position: absolute;
        bottom: 1em;
        right: 1em;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }
    .player .controls .play-bar button {
        box-sizing: content-box;
        background: rgba(23, 35, 34, .75);
        border-radius: .5em;
        padding: .5em;
        margin-left: .5em;
        height: 1.2em
    }
    .player .controls .play-bar button:not(.toggle):hover .fill {
        fill: #fff
    }
    .player .controls .play-bar .cc {
        padding: .4em;
        height: 1.4em
    }
    .player .controls .airplay,
    .player .controls .effect,
    .player .controls .hd,
    .player .controls .logo,
    .player .controls .progress,
    .player .controls .stereoscopic,
    .player .controls .volume {
        display: none
    }
    .player .controls .play {
        margin: -2em 0 0 -3.25em;
        position: absolute;
        top: 50%;
        left: 50%
    }
    .player .controls .play .tiny-bars {
        display: block
    }
    .player .controls .play,
    .player .controls .play-bar {
        z-index: 7
    }
    .player.no-fullscreen-support .captions.with-controls,
    .player:not(:-webkit-full-screen) .captions.with-controls {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }
    .player.no-fullscreen-support .captions.with-controls,
    .player:not(:-moz-full-screen) .captions.with-controls {
        transform: translateY(-32px)
    }
    .player.no-fullscreen-support .captions.with-controls,
    .player:not(:-ms-fullscreen) .captions.with-controls {
        transform: translateY(-32px)
    }
    .player.no-fullscreen-support .captions.with-controls,
    .player:not(:fullscreen) .captions.with-controls {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }
}

@media screen and (min-width:300px) and (max-width:374px) {
    .player::after {
        content: "mini"
    }
    .player .controls .volume,
    .player .outro-wrapper,
    .player .portrait {
        display: none!important
    }
    .player .title header h1 {
        font-size: 1.8em
    }
}

@media screen and (max-width:449px) {
    .player.with-custom-logo::after {
        content: "mini"
    }
    .player.with-custom-logo .controls .volume,
    .player.with-custom-logo .outro-wrapper,
    .player.with-custom-logo .portrait {
        display: none!important
    }
    .player.with-custom-logo .title header h1 {
        font-size: 1.8em
    }
}

@media screen and (max-width:337px),
screen and (max-height:189px) {
    .player.with-custom-logo::after {
        content: "tiny"
    }
    .player.with-custom-logo .custom-logo,
    .player.with-custom-logo .outro-wrapper,
    .player.with-custom-logo .sidedock,
    .player.with-custom-logo .title {
        display: none!important
    }
    .player.with-custom-logo .controls {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        z-index: auto;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
    .player.with-custom-logo .controls .play-bar {
        background: 0;
        padding: 0;
        height: 2.2em;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        position: absolute;
        bottom: 1em;
        right: 1em;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }
    .player.with-custom-logo .controls .play-bar button {
        box-sizing: content-box;
        background: rgba(23, 35, 34, .75);
        border-radius: .5em;
        padding: .5em;
        margin-left: .5em;
        height: 1.2em
    }
    .player.with-custom-logo .controls .play-bar button:not(.toggle):hover .fill {
        fill: #fff
    }
    .player.with-custom-logo .controls .play-bar .cc {
        padding: .4em;
        height: 1.4em
    }
    .player.with-custom-logo .controls .airplay,
    .player.with-custom-logo .controls .effect,
    .player.with-custom-logo .controls .hd,
    .player.with-custom-logo .controls .logo,
    .player.with-custom-logo .controls .progress,
    .player.with-custom-logo .controls .stereoscopic,
    .player.with-custom-logo .controls .volume {
        display: none
    }
    .player.with-custom-logo .controls .play {
        margin: -2em 0 0 -3.25em;
        position: absolute;
        top: 50%;
        left: 50%
    }
    .player.with-custom-logo .controls .play .tiny-bars {
        display: block
    }
    .player.with-custom-logo .controls .play,
    .player.with-custom-logo .controls .play-bar {
        z-index: 7
    }
    .player.with-custom-logo.no-fullscreen-support .captions.with-controls,
    .player.with-custom-logo:not(:-webkit-full-screen) .captions.with-controls {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }
    .player.with-custom-logo.no-fullscreen-support .captions.with-controls,
    .player.with-custom-logo:not(:-moz-full-screen) .captions.with-controls {
        transform: translateY(-32px)
    }
    .player.with-custom-logo.no-fullscreen-support .captions.with-controls,
    .player.with-custom-logo:not(:-ms-fullscreen) .captions.with-controls {
        transform: translateY(-32px)
    }
    .player.with-custom-logo.no-fullscreen-support .captions.with-controls,
    .player.with-custom-logo:not(:fullscreen) .captions.with-controls {
        -webkit-transform: translateY(-32px);
        transform: translateY(-32px)
    }
}

@media screen and (max-width:449px),
screen and (max-height:249px) {
    .player .custom-logo,
    .player .custom-logo img {
        max-width: 75px!important;
        max-height: 75px!important
    }
}

@media screen and (max-height:224px) {
    .player .custom-logo,
    .player .custom-logo img {
        max-width: 50px!important;
        max-height: 50px!important
    }
}